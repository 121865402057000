import React, { useState } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import TitleBar from "../../components/titleBar";
import FileComment from "../../components/fileComment";
import FileBody from "../../components/fileBody";
import stylesButton from "../../styles/sysButtons.module.css";
import styles from "./clientFile.module.css";
import { generalApiCall } from "../../scripts/apiCalls.jsx";
import { useEffect } from "react";
import { Button, Card, Divider } from "antd";
import TextArea from "antd/es/input/TextArea";
import LoadingScreen from "../../components/loadingScreen";

function ClientFile(props) {
  const setToolbarIcon = useOutletContext();
  useEffect(() => {
    setToolbarIcon(props.tbIcon);
  }, []);

  let { fileInstId } = useParams();

  const [fileData, setFileData] = useState();
  const [isFileDataLoaded, setIsFileDataLoaded] = useState(false);
  const [newComment, setNewComment] = useState("");

  const clearNewComment = () => {
    setNewComment("");
  };

  useEffect(() => {
    generalApiCall("/getFileInfo", { fileInstId: fileInstId }).then(
      (result) => {
        setFileData(result.data);
        setIsFileDataLoaded(true);
      }
    );
  }, []);

  function updateFileFromEdit() {
    generalApiCall("/getFileInfo", { fileInstId: fileInstId }).then(
      (result) => {
        setFileData(result.data);
        setIsFileDataLoaded(true);
      }
    );
  }

  function SendComment() {
    generalApiCall(
      "/fileComment", // url
      { fileInstId: fileInstId, comment: newComment }
    ).then((res) => {
      setFileData({ ...fileData, comments: res.data.comments });
      clearNewComment();
    });
  }
  
  // const fileComments = fileData.comments;

  if (!isFileDataLoaded) {
    return (<LoadingScreen/>)
  }
  return (
    <div className={"fillHeight " + styles.clientFileContainer}>
      <TitleBar backButton={true} name={fileData.name}></TitleBar>
      <FileBody fileData={fileData} updateFunc={updateFileFromEdit}></FileBody>
      <div style={{ marginTop: 24 }}></div>
      <div style={{ margin: "0px 5%" }}>
        {fileData.comments.map((comment, i) => {
          return (
            <>
            <FileComment
              key={i}
              userNames={fileData.userNames}
              commentData={comment}
            ></FileComment>
            <div style={{marginTop: 4}}></div>
            </>
          );
        })}
        <Divider></Divider>
        <TextArea
          id="commentInput"
          value={newComment}
          onChange={(e) => {
            setNewComment(e.target.value);
          }}
          rows={4}
        ></TextArea>
        <div style={{ marginTop: 12 }}></div>
        <Button onClick={SendComment} block type="primary">
          Comment
        </Button>
        <div style={{ marginTop: 12 }}></div>
      </div>
    </div>
  );
}

export default ClientFile;
