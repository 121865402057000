import React, { useEffect } from "react";
import styles from "./clientHome.module.css";
import { useOutletContext } from "react-router-dom";
import TitleBar from "../../components/titleBar";
import SubscriptionsList from "../../components/subscriptionsList";

function ClientSubscriptionsList(props) {
  const setToolbarIcon = useOutletContext();
  useEffect(() => {
    setToolbarIcon(props.tbIcon);
  }, []);

  return (
    // <div className={styles.container}>
    <div className={"fillHeight " + styles.container}>
      <TitleBar name="Subscriptions" backButton={true}></TitleBar>
      {/* <SubscriptionsList></SubscriptionsList> */}
      <div style={{ margin: "24px" }}>
      <SubscriptionsList context="Client"></SubscriptionsList>
      </div>
    </div>
  );
}

export default ClientSubscriptionsList;
