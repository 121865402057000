import { useState, useEffect } from "react";
import { generalApiCall } from "./apiCalls";

function useCheckNewToolbarItems(isPt) {
  const [hasMessage, setHasMessage] = useState(false);
  const [hasNewNotification, setHasNewNotification] = useState(false);

  useEffect(() => {
    const interval = window.setInterval(() => {
      if (!isPt) {
        generalApiCall("/hasNewMessageClient", {}).then((res) => {
          setHasMessage(res.data);
        });
      }
      generalApiCall("/hasNewNotification", {}).then((res) => {
        setHasNewNotification(res.data);
      });
    }, 2000);
    return () => window.clearInterval(interval);
  }, [isPt]);

  return { hasMessage, hasNewNotification };
}

export default useCheckNewToolbarItems;
