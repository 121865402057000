import React, { useEffect, useState } from "react";
import { Link, useOutletContext, useParams } from "react-router-dom";
import TitleBar from "../components/titleBar";
import styles from "./pt/ptPackageViewer.module.css";
import OtherThumbnailIcon from "../components/icons/otherThumbnail";
import PdfThumbnail from "../components/icons/pdfThumbnail";
import VideoThumbnail from "../components/icons/videoThumbnail";
import { generalApiCall } from "../scripts/apiCalls";
import { Card, Button } from "antd";
import FileTable from "../components/fileTable";

function ExternalPackageViewer() {
  let { packageId, accessCode } = useParams();
  console.log(packageId);

  const [packageData, setPackageData] = useState({ name: "Loading.." });
  const [packageLoaded, setPackageLoaded] = useState(false);
  const [filesData, setFilesData] = useState();
  const [filesLoaded, setFilesLoaded] = useState(false);

  useEffect(() => {
    generalApiCall("/getPackageInfoExternal", { accessCode: accessCode }).then(
      (res) => {
        if (res.status === 401) {
          console.log("nav to prev page");
        } else {
          setPackageData(res.data);
          setPackageLoaded(true);
        }
      }
    );
  }, []);

  useEffect(() => {
    if (packageLoaded) {
      getFiles().then((res) => {
        setFilesData(res);
        setFilesLoaded(true);
      });
    }

    return () => {
      // this now gets called when the component unmounts
    };
  }, [packageLoaded]);

  function getFiles() {
    let tmpFiles = [];
    for (let i = 0; i < packageData.filesInst.length; i++) {
      tmpFiles.push(
        generalApiCall("/getPackageExternalFileInfo", {
          fileId: packageData.filesInst[i],
          accessCode: accessCode,
        }).then((res) => {
          return res.data;
        })
      );
    }
    return Promise.all(tmpFiles);
  }

  return (
    <div className={"fillHeight crosses " + styles.pageCtn}>
      <TitleBar name={packageData.name} backButton={false}></TitleBar>
      <div className={styles.ctn}>
        <div className={styles.filesListCtn}></div>
        <div style={{ height: "16px" }}></div>
        {filesLoaded && (
          <FileTable
            files={filesData}
            disabled={false}
            showDate={false}
            url={"/packages/" + packageId + "/" + accessCode + "/"}
          />
        )}
        <Card title="Description" size="small">
          <p>{packageData.description}</p>{" "}
        </Card>
      </div>
    </div>
  );
}

export default ExternalPackageViewer;
