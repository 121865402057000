import React, { useEffect, useState } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import TitleBar from "../../components/titleBar";
import styles from "./ptPackageViewer.module.css";
import AddIcon from "../../components/icons/addIcon";
import { generalApiCall } from "../../scripts/apiCalls";
import { Input, Switch, Button } from "antd";
import FileTable from "../../components/fileTable";

function PtPackageCreator(props) {
  const setToolbarIcon = useOutletContext();
  useEffect(() => {
    setToolbarIcon(props.tbIcon);
  }, []);

  const navigate = useNavigate();
  let { packageId } = useParams();
  const { TextArea } = Input;

  const [filesAdded, setFilesAdded] = useState([]);
  const [searchFiles, setSearchFiles] = useState([]);
  //for edit
  const [packageData, setPackageData] = useState({
    name: "Default Name?",
    description: "Default description?",
    price: 6969,
    saleable: true,
  });
  const [packageLoaded, setPackageLoaded] = useState(false);
  const [saleable, setSaleable] = useState(false);
  // const [files, setPackageLoaded] = useState();

  useEffect(() => {
    generalApiCall("/getPackageFileSearchList", {}).then((res) => {
      setSearchFiles(res.data); //file not instance
      console.log(res.data);
    });
  }, []);

  //for edit
  useEffect(() => {
    if (packageId !== undefined) {
      generalApiCall("/getPackageInfo", { id: packageId }).then((res) => {
        setPackageData(res.data); //files not inst?
        setPackageLoaded(true);
        setSaleable(res.data.saleable)
        console.log(res.data);
        // document.getElementById("nameInput").defaultValue = res.data.name;
        // document.getElementById("priceInput").defaultValue = res.data.price;
        // document.getElementById("saleableInput").checked = res.data.saleable;
        // document.getElementById("descriptionInput").value =
        //   res.data.description;
      });
    }
  }, []);

  useEffect(() => {
    if (packageLoaded) {
      getFiles().then((res) => {
        //filesAdded needs to be set before passwing
        console.log(res);
        setFilesAdded([...filesAdded, ...res]);
        // let filteredArray = searchFiles.filter(function (file) {
        //   return res.map((e) => e._id).indexOf(file._id) === -1;
        // });

        // setFilesAdded(res);
        // console.log(res);
        // setFilesLoaded(true);
      });
    }

    return () => {
      // this now gets called when the component unmounts
    };
  }, [packageLoaded]);

  function getFiles() {
    let tmpFiles = [...packageData.files];
    return Promise.all(tmpFiles);
  }
  function getSelected(data) {}

  function setChecked(value){
    console.log(value)
    setSaleable(value)
  }

  function submitPackage() {
    //name
    //desc
    //price
    //files[] (_id)

    let fileIds = [];
    for (let i = 0; i < filesAdded.length; i++) {
      fileIds.push(filesAdded[i]._id);
    }

    let packageObj = {
      name: document.getElementById("nameInput").value,
      description: document.getElementById("descriptionInput").value,
      price: Math.round(document.getElementById("priceInput").value*100),
      files: filesAdded,//check if inst or root
      saleable:saleable,
    };
    if (packageId !== undefined) {
      //editor
      packageObj._id = packageId;
      // packageObj.saleable = saleable;
      generalApiCall("/editPackage", packageObj).then((res) => {
        navigate("/pt/settings/packages/" + res.data, { replace: true });
      });
    } else {
      //creator
      console.log(packageObj);
      generalApiCall("/addPackage", packageObj).then((res) => {
        navigate("/pt/settings/packages/" + res.data, { replace: true });
      });
    }
  }
  console.log(filesAdded);
  return (
    <div className={"fillHeight  crosses " + styles.pageCtn}>
      <TitleBar name="Creator" backButton="true"></TitleBar>
      <div className={styles.ctn}>
        {/* <div className={styles.searchCtn}>
          <input
            type="text"
            placeholder="Search.."
            className={StylesInput.search}
            onChange={changeSearchText}
            // autoFocus
            onFocus={inputFocus}
            onBlur={inputUnfocus}
          ></input>
        </div> */}
        <div style={{ marginTop: 12 }}></div>
        {/* <div className={styles.fileDropdownCtn}>
          {searchFocus &&
            searchFilesFiltered !== [] &&
            searchFilesFiltered.map((file, index) => {
              console.log(file);
              let cond = file.name
                .toLowerCase()
                .includes(searchText.toLowerCase());
              if (cond) {
                return (
                  <div className={styles.fileCtn} key={file._id}>
                    <div className={styles.thumbNameCtn}>
                      {thumbnailSelector(file.extension)}
                      <p className={styles.name}>{file.name}</p>
                    </div>
                    <div
                      onClick={() => {
                        addFile(file);
                      }}
                    >
                      <AddIcon />
                    </div>
                  </div>
                );
              }
            })}
        </div> */}
        {/* Maybe send through "context" adds field for a tick box
            filters by tickbox by default
            ticked stored on filetable? callback function to retrieve files ticked
            - set ticked
            - get ticked
            ticked hits callback func? file added to files added? but then need filter when removing tick
         */}
        {(packageLoaded || packageId === undefined) && (
          <FileTable
            files={searchFiles}
            filesAdded={
              packageData.files
              // filesAdded
            }
            showSelect={true}
            disabled={false}
            showDate={false}
            url={"/pt/files/"}
            getSelected={getSelected}
            setFilesAdded={setFilesAdded}
          />
        )}

        {(packageLoaded || packageId === undefined) && (
          <>
            <div style={{ marginTop: 12 }}></div>
            <div>Package name </div>
            <div style={{ marginTop: 6 }}></div>
            <Input id="nameInput" defaultValue={packageData.name}></Input>
            <div style={{ marginTop: 12 }}></div>
            <div>Description </div>
            <div style={{ marginTop: 6 }}></div>
            <TextArea
              rows={4}
              defaultValue={packageData.description}
              id="descriptionInput"
            ></TextArea>
            <div style={{ marginTop: 12 }}></div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ display: "inline", margin: "auto 0" }}>Price:</div>
              <Input
                style={{ width: "75px" }}
                id="priceInput"
                defaultValue={packageData.price / 100}
                type="number"
                maxLength={3}
              ></Input>
            </div>
            <div style={{ marginTop: 12 }}></div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ display: "inline" }}>Saleable:</div>
              <Switch
                defaultChecked={packageData.saleable}
                style={{ width: "30px" }}
                onChange={setChecked}
              ></Switch>
            </div>
            {/* {packageId !== undefined && (
              <div>
                Saleable
                <label className={styles.switch}>
                  <input id="saleableInput" type="checkbox" />
                  <span className={styles.slider + " " + styles.round}></span>
                </label>
              </div>
            )} */}
            <div style={{ marginTop: 12 }}></div>
            <Button onClick={submitPackage} block type="primary">
              Save
            </Button>
            <div style={{ marginBottom: 12 }}></div>
          </>
        )}
      </div>
    </div>
  );
}

export default PtPackageCreator;
