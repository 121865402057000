import React, { useState } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import styles from "./clientFiles.module.css";
import TitleBar from "../../components/titleBar.jsx";
// import PtUploadFile from "../../components/ptUploadFile.jsx";
import UploadFile from "../../components/UploadFile.jsx";
import useAuth from "../../scripts/useAuth.jsx";
import LoadingScreen from "../../components/loadingScreen";
import { generalApiCall } from "../../scripts/apiCalls.jsx";
import { useEffect } from "react";
import { Button } from "antd";
import FileTable from "../../components/fileTable";

function ClientFilesAll(props) {
  const setToolbarIcon = useOutletContext();
  useEffect(() => {
    setToolbarIcon(props.tbIcon);
  }, []);
  let { clientId } = useParams();
  const { userData } = useAuth();
  const isPt = userData.isPt;
  const [showUpload, setShowUpload] = useState(false);
  const [files, setFiles] = useState();
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    generalApiCall("/getFilesAll", { clientId: clientId }).then((result) => {
      if (result.status === 200) {
        setIsLoaded(true);
        setFiles(result.data);
      } else {
        setIsLoaded(false);
      }
    });
  }, []);
  function refreshFiles(){
    generalApiCall("/getFilesAll", { clientId: clientId }).then((result) => {
      if (result.status === 200) {
        setIsLoaded(true);
        setFiles(result.data);
      } else {
        setIsLoaded(false);
      }
    });
  }

  function toggleUpload() {
    setShowUpload(!showUpload);
  }

  function closeUpload() {
    setShowUpload(false);
  }

  if (!isLoaded) {
    return (<LoadingScreen/>)
 
  } else {
    return (
      <div className={"fillHeight crosses " + styles.pageCtn}>
        <TitleBar
          name={isPt && clientId === undefined ? "Library" : "Client Files"}
          backButton={true}
        ></TitleBar>
        <div className={styles.searchCtn}>
          <Button size="large" type="primary" block onClick={toggleUpload}>
            Upload
          </Button>
        </div>
        <div className={styles.filterCtn}></div>

        <div className={styles.allFilesCtn}>
          <FileTable
            url={isPt ? "./files/" : "./files/"}
            files={files}
            showDate={true}
          />
          {/* <Table columns={columns} dataSource={files} size="small" /> */}
        </div>
        <UploadFile isOpen={showUpload} refresh={refreshFiles} close={closeUpload}></UploadFile>
        {/* {upload && <PtUploadFile close={uploadFalse}></PtUploadFile>} */}
      </div>
    );
  }
}

export default ClientFilesAll;
