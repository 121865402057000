import React from "react";
import styles from "./popup.module.css";
import stylesButtons from "../styles/sysButtons.module.css";

function Popup(props) {
  //props needed:
  // text
  // close
  //buttons[]:
  //  text
  //  clicked

  function stopClick(event) {
    event.stopPropagation();
  }
  return (
    <div className={styles.background} onMouseUp={props.close}>
      <div
        className={styles.yesNoCtn + " horizontalCenter"}
        onMouseUp={stopClick}
      >
        <PopupText text={props.text}></PopupText>
        <div className={styles.buttons}>
          {props.buttons.map((btn, index) => {
            return (
              <Button
                key={"popupButton" + btn.text}
                clicked={btn.clicked}
                text={btn.text}
              ></Button>
            );
          })}
          {/* <button
            className={stylesButtons.button + " " + stylesButtons.buttonSolid}
            onClick={props.close}
          >
            No
          </button> */}
        </div>
      </div>
    </div>
  );
}

function PopupText(props) {
  console.log(typeof props.text)
  if (typeof props.text === "string") {
    return <div className={styles.text}>{props.text}</div>;
  } else if (Array.isArray(props.text)) {
    return (
      <div>
        {props.text.map((para, ind) => {
          return <div className={styles.text}>{para}</div>;
        })}
      </div>
    );
  }
}

function Button(props) {
  function btnClicked() {
    props.clicked();
  }

  return (
    <button
      className={stylesButtons.button + " " + stylesButtons.buttonFlag}
      onClick={btnClicked}
    >
      {props.text}
    </button>
  );
}

export default Popup;
