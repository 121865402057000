
async function findFreeEvents(bookingData, ptSettings) {
  return new Promise(async (res) => {
    // let ptSettings = await generalApiCall("/getPtBookingSettings");
    console.log(ptSettings)
    let startHour = ptSettings.timeMin;
    let endHour = ptSettings.timeMax;
    let bookingBuffer = ptSettings.bookingBuffer/60; //in hour form
    let minimumBooking = ptSettings.bookingMin/60; //In hour form

    console.log(bookingData);
    let freeSlots = JSON.parse(JSON.stringify(bookingData));
    freeSlots[0].data = [];
    freeSlots[1].data = [];
    freeSlots[2].data = [];
    freeSlots[3].data = [];
    freeSlots[4].data = [];
    freeSlots[5].data = [];
    freeSlots[6].data = [];

    for (let i = 0; i < 7; i++) {
      let date = new Date(freeSlots[i].date);
      let latestOnDay = 0;
      //full free day
      if (bookingData[i].data.length === 0) {
        //add full day
        let tt = {
          day: i,
          date: date.getDate(),
          startHour: startHour,
          startMin: 0,
          endHour: endHour,
          endMin: 0,
          name: "Full Free Day",
        };
        freeSlots[i].data.push(tt);
        continue;
      }
      //day fully booked
      if (
        eventStart(bookingData[i].data[0]) === startHour &&
        eventEnd(bookingData[i].data[0]) === endHour
      ) {
        continue;
      }

      //add free before first event
      if (eventStart(bookingData[i].data[0]) >= startHour + minimumBooking) {
        let tt = {
          day: i,
          date: date.getDate(),
          startHour: startHour,
          startMin: 0,
          endHour: bookingData[i].data[0].startHour,
          endMin: bookingData[i].data[0].startMin,
          name: "First of day",
        };
        freeSlots[i].data.push(tt);
      }
      //find gaps
      if (bookingData[i].data.length > 1) {
        for (
          let eventI = 0;
          eventI < bookingData[i].data.length - 1;
          eventI++
        ) {
          //update latest today?
          latestOnDay =
            eventEnd(bookingData[i].data[eventI]) > latestOnDay
              ? eventEnd(bookingData[i].data[eventI])
              : latestOnDay;

          if (i === 2) {
            console.log(latestOnDay);
            //end free space look at latest on day
          }
          //eventEnd syn or latest today, add to if below
          if (
            // eventEnd(bookingData[i].data[eventI]) + minimumBooking <=
            latestOnDay + minimumBooking <=
            eventStart(bookingData[i].data[eventI + 1])
          ) {
            let tt = {
              day: i,
              date: date.getDate(),
              startHour: Math.floor(latestOnDay),
              startMin: (latestOnDay - Math.floor(latestOnDay)) * 60,
              // startHour: bookingData[i].data[eventI].endHour,
              // startMin: bookingData[i].data[eventI].endMin,
              endHour: bookingData[i].data[eventI + 1].startHour,
              endMin: bookingData[i].data[eventI + 1].startMin,
              // name: "Did I Finally Find it?",
            };
            freeSlots[i].data.push(tt);
          }
        }
      }
      let lastInd = bookingData[i].data.length - 1;
      latestOnDay =
        eventEnd(bookingData[i].data[lastInd]) > latestOnDay
          ? eventEnd(bookingData[i].data[lastInd])
          : latestOnDay;
      if (i === 2) {
        console.log(latestOnDay);
        //end free space look at latest on day
      }

      //add one at the end of it
      // if (eventEnd(bookingData[i].data[lastInd]) + minimumBooking <= endHour) {
      if (latestOnDay + minimumBooking <= endHour) {
        let tt = {
          day: i,
          date: date.getDate(),
          // startHour: bookingData[i].data[lastInd].endHour,
          // startMin: bookingData[i].data[lastInd].endMin,
          startHour: Math.floor(latestOnDay),
          startMin: (latestOnDay - Math.floor(latestOnDay)) * 60,
          endHour: endHour,
          endMin: 0,
          name: "End",
        };
        freeSlots[i].data.push(tt);
      }
    }
    // console.log(freeSlots);
    res(freeSlots);
  });
}

function eventStart(event) {
  return event.startHour + event.startMin / 60;
}
function eventEnd(event) {
  return event.endHour + event.endMin / 60;
}

export function daySlots(dayObj, time) {
  console.log(dayObj.data);
  let day = dayObj.data.day;
  let availableOnDay = dayObj.data;
  let finalSlots = [];
  let slotSpacing = 15 / 60;
  // let i = 0;
  // do {
  //   if (dayObj.data[i].day === day) {
  //     availableOnDay.push(dayObj.data[i]);
  //   }
  //   i++;
  // } while (i < dayObj.data.length);

  console.log(time);
  for (let i = 0; i < availableOnDay.length; i++) {
    let slotSpan = eventEnd(availableOnDay[i]) - eventStart(availableOnDay[i]);
    let nbOfSlots = Math.floor(slotSpan / slotSpacing);
    // console.log(nbOfSlots);

    for (let j = 0; j < nbOfSlots; j++) {
      let startTime = eventStart(availableOnDay[i]) + j * slotSpacing;

      let startMin = (startTime - Math.floor(startTime)) * 60;
      if (startMin === 60) {
        startMin = 0;
      }
      let endMin = ((startTime + time / 60 - Math.floor(startTime)) * 60) % 60;
      if (endMin === 60) {
        endMin = 0;
      }

      if (
        Math.floor(startTime + time / 60) + endMin / 60 >
        eventEnd(availableOnDay[i])
      ) {
        continue;
      }

      finalSlots.push({
        startHour: Math.floor(startTime),
        startMin: startMin,
        endHour: Math.floor(startTime + time / 60),
        endMin: endMin,
        date: availableOnDay[i].date,
      });
    }
  }
  console.log(finalSlots);
  console.log(availableOnDay);
  return finalSlots;
}

export default findFreeEvents;
