import React, { useEffect, useState } from "react";
import styles from "./clientHome.module.css";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import TitleBar from "../../components/titleBar";
import { generalApiCall } from "../../scripts/apiCalls";
import { Button } from "antd";

function ClientSubscription(props) {
  const setToolbarIcon = useOutletContext();
  useEffect(() => {
    setToolbarIcon(props.tbIcon);
  }, []);
  let { subscriptionId } = useParams();
  const navigate = useNavigate();

  const [subscriptionData, setSubscriptionData] = useState();
  const [subscriptionLoaded, setSubscriptionLoaded] = useState(false);
  useEffect(() => {
    generalApiCall("/getSubscription", { _id: subscriptionId }).then((res) => {
      setSubscriptionData(res.data);
      setSubscriptionLoaded(true);
    });
  }, []);

  function buySubscription() {
    //make api call
    generalApiCall("/buySubscription", { _id: subscriptionId }).then(
      (res) => {
        if (res.status === 200) {
          // navigate(res.data.url, { replace: props.replace ?? false });
          window.location.href = res.data.url
        }
      }
    );
  }
  return (
    // <div className={styles.container}>
    <div className={"fillHeight " + styles.container}>
      <TitleBar name="Name of subscription?" backButton={true}></TitleBar>
      <div style={{ width: "85%" }}>
      <div style={{ height: "32px" }}></div>
      {subscriptionLoaded && (
        <div>
          <h1 style={{textAlign:"center"}}>
            {subscriptionData.name}
          </h1>
          <div style={{ whiteSpace: "pre-line", backgroundColor:"#e9e9e9", borderRadius:"7px" }}>{subscriptionData.description}</div>
          <div style={{margin:"16px 0px"}}>
            {subscriptionData.userIds !== null
              ? "Current"
              : "$" + subscriptionData.price/100}
          </div>
          {subscriptionData.userIds === null && (
            <Button block type="primary" onClick={buySubscription}>Buy Now!</Button>
          )}
        </div>
      )}
    </div>
    </div>
  );
}

export default ClientSubscription;
