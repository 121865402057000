import React, { useState } from "react";
import useAuth from "../scripts/useAuth";
import { useNavigate, useParams } from "react-router-dom";
import { generalApiCall } from "../scripts/apiCalls";
import { Button, Modal } from "antd";

function FileRemove(props) {
  const navigate = useNavigate();
  const { userData } = useAuth();
  const isPt = userData.isPt;
  let { clientId } = useParams();
  const [displayConfirm, setDisplayConfirm] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorText, setErrorText] = useState("");

  //props.fileId
  //props.ptUploaded
  function deleteFileSubmit() {
    generalApiCall("/deleteFile", { fileId: props.fileId }).then((res) => {
      //check status response, if the file is part of a package, create popup saying it needs
      console.log(res.status);
      //to be removed from the package first
      if (res.status === 200) {
        navigate(-1);
      } else if (res.status === 401) {
        setErrorText(res.data);
        setShowError(true);
      }
    });
  }
  function removeAccessSubmit() {
    generalApiCall("/removeFileAccess", {
      fileId: props.fileId,
      clientId: clientId,
    }).then((res) => {
      //go to previous page?
      navigate(-1);
    });
  }

  function confirmReturnFunc() {
    if (props.ptUploaded & isPt & (clientId !== undefined)) {
      console.log("remove access");
      removeAccessSubmit();
      setDisplayConfirm(false);
    } else {
      console.log("delete file");
      deleteFileSubmit();
      setDisplayConfirm(false);
    }
  }

  function toggleDisplayConfirm() {
    setDisplayConfirm(!displayConfirm);
  }
  function toggleShowError() {
    setShowError(!showError);
  }

  function RemoveBtn() {
    let text = "Delete";
    //maybe use
    console.log(clientId);
    console.log(props);
    if (props.ptUploaded & isPt & (clientId !== undefined)) {
      text = "Remove Access";
    }

    return (
      <Button type="dashed" danger block onClick={toggleDisplayConfirm}>{text}</Button>
    );

  }

  return (
    <div style={{ width: "100%" }}>
      <RemoveBtn></RemoveBtn>
      <Modal
        centered
        open={displayConfirm}
        okText="Yes"
        cancelText="No"
        onCancel={toggleDisplayConfirm}
        onOk={confirmReturnFunc}
      >{props.ptUploaded & isPt & (clientId !== undefined) ?
        "Are you sure you want to remove their access to this file?"
        : "Are you sure you want to delete this file?"}

      </Modal>
      <Modal
        centered
        open={showError}
        onCancel={toggleShowError}
        footer={<Button onClick={() => { toggleShowError() }} type="primary">Okay</Button>}
      >
        {errorText}
      </Modal>
    </div>
  );
}



export default FileRemove;
