import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import styles from "./clientToolbar.module.css";
import ProfileIcon from "../components/icons/profileIcon";
import MessagesIcon from "../components/icons/messagesIcon";
import SettingsIcon from "../components/icons/settingsIcon";
import FilesIcon from "../components/icons/filesIcon";
import NotificationsIcon from "../components/icons/notificationsIcon";
import ScheduleIcon from "../components/icons/scheduleIcon";
import useAuth from "../scripts/useAuth";
import useCheckNewToolbarItems from "../scripts/useCheckNewToolbarItems";


function MobileToolbarItem({ to, children, hasBadge }) {
	return (
		<NavLink className={styles.link} activeClassName={styles.selected} to={to}>
			{children}
			{hasBadge && <div className={styles.iconNotify}></div>}
		</NavLink>
	);
}

function MobileToolbar(props) {
	const { userData } = useAuth();
	const isPt = userData.isPt
	const { hasMessage, hasNewNotification } = useCheckNewToolbarItems(isPt);

	function cssSelected(tag) {
		if (tag === props.selected) {
			return styles.selected;
		} else {
			return styles.unSelected;
		}
	}

	return (
		<div className={styles.buffer}>
			<div id={isPt ? "ptToolbar" : "clientToolbar"} className={styles.ctn}>
				<MobileToolbarItem to={isPt ? "/pt" : "/client"}>
					<ProfileIcon class={cssSelected("Profile")} />
				</MobileToolbarItem>
				<MobileToolbarItem to={isPt ? "/pt/library" : "/client/files"}>
					<FilesIcon class={cssSelected("Files")} />
				</MobileToolbarItem>
				{isPt ? (
					<MobileToolbarItem to="/pt/booking">
						<ScheduleIcon class={cssSelected("Booking")} />
					</MobileToolbarItem>
				) : (
					<MobileToolbarItem to="/client/messages" hasBadge={hasMessage}>
						<MessagesIcon class={cssSelected("Messages")} />
					</MobileToolbarItem>
				)}
				<MobileToolbarItem
					to={isPt ? "/pt/notifications" : "/client/notifications"}
					hasBadge={hasNewNotification}
				>
					<NotificationsIcon class={cssSelected("Notifications")} />
				</MobileToolbarItem>
				<MobileToolbarItem to={isPt ? "/pt/settings" : "/client/settings"}>
					<SettingsIcon class={cssSelected("Settings")} />
				</MobileToolbarItem>
			</div>
		</div>
	);
}

export default MobileToolbar;
