import { Outlet } from "react-router-dom";

import "./App.css";

//remove footer from here? in footer
function App() {
  return (
    <div className="rootContainer">

    {/* <AuthProvider> */}
      {/* <Header></Header> */}
      <Outlet />
      {/* <Footer></Footer> */}
      {/* </AuthProvider> */}
    </div>
  );
}

export default App;
