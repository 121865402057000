import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./SettingsPassword.module.css";
import TitleBar from "../../components/titleBar";
import { ChangePassword, generalApiCall } from "../../scripts/apiCalls";
import { useOutletContext } from "react-router-dom";
import { Form, Input, Button } from "antd";

function SettingsPassword(props) {
  const setToolbarIcon = useOutletContext();
  useEffect(() => {
    setToolbarIcon(props.tbIcon);
  }, []);
  const navigate = useNavigate();


  function handleSubmit(formInfo) {
    console.log(formInfo);
    generalApiCall("/changePassword", {
      oldPassword: formInfo.passwordCurrent,
      password: formInfo.passwordNew1,
    }).then((res) => {
      navigate(-1);
    });
  }

  const validatePassword = (rule, value) => {
    return new Promise((resolve, reject) => {
      const regex = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])([a-zA-Z0-9]+){8,}$/;

      if (value && !regex.test(value)) {
        reject(
          "Password must be 8+ characters with 1 uppercase, 1 lowercase, and 1 number"
        );
      } else {
        resolve();
      }
    });
  };

  return (
    <div className={"fillHeight crosses " + styles.container}>
      <TitleBar name="Change Password" backButton={true}></TitleBar>
      <div className={styles.passwordContainer}>
        <Form
          onFinish={handleSubmit}
          labelCol={{
            span: 10,
          }}
          wrapperCol={
            {
              // span: 12
            }
          }
        >
          <Form.Item
            name="passwordCurrent"
            label="Current Password"
            rules={[
              { required: true, message: "Please input your Password" },
              // { validator: validatePassword },
            ]}
            style={{ backgroundColor: "#e9e9e9" }}
          >
            <Input type="password" placeholder="Password" />
          </Form.Item>
          <Form.Item
            name="passwordNew1"
            label="New Password"
            rules={[
              { required: true, message: "Please input your new Password" },
              { validator: validatePassword },
            ]}
            style={{ backgroundColor: "#e9e9e9" }}
          >
            <Input type="password" placeholder="Password" />
          </Form.Item>
          <Form.Item
            name="passwordNew2"
            label="Comfirm Password"
            rules={[
              { required: true, message: "Please confirm your Password" },
              // { validator: validatePassword },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("passwordNew1") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      "The two passwords that you entered do not match!"
                    )
                  );
                },
              }),
            ]}
            style={{ backgroundColor: "#e9e9e9" }}
          >
            <Input type="password" placeholder="Password" />
          </Form.Item>
          {/* <Form.Item> */}
          <Button block type="primary" htmlType="submit">
            Submit
          </Button>
          {/* </Form.Item> */}
        </Form>
      </div>
    </div>
  );
}

export default SettingsPassword;
