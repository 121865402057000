import React from "react";
import { Link } from "react-router-dom";
import { Form, Checkbox, Typography, Modal } from 'antd';
import styles from "./register.module.css";

const { Text } = Typography;

function FinalSteps({ termsAccepted, setTermsAccepted, licenseAccepted, setLicenseAccepted }) {
   const containerStyle = {
     display: 'flex',
     flexDirection: 'column',
     alignItems: 'center',
     textAlign: 'center',
     padding: '20px',
     backgroundColor: 'white',
     borderRadius: '8px',
     boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
   };

   const textBlockStyle = {
     marginBottom: '12px',
   };

   const linkStyle = {
     color: 'blue',
     textDecoration: 'underline',
   };

   const handleCheckboxChange = (e, checkboxLabel) => {
    if (e.target.checked) {
      console.log(`${checkboxLabel} selected.`);
    } else {
      console.log(`${checkboxLabel} deselected.`);
    }
  };

   return (
     <div style={containerStyle}>
       <div style={textBlockStyle}>
         <Text>
           Thank you for choosing our platform! After submitting, you will receive an email with a verification link.
         </Text>
       </div>
       
       <div style={textBlockStyle}>
         <Text>
           Click the <b>"Verify Account"</b> button in the email to confirm your registration and activate your account.
         </Text>
       </div>
       
       <div style={textBlockStyle}>
         <Text>
           After successful verification, you'll be redirected to Stripe, our trusted payment partner.
         </Text>
       </div>

       <div style={textBlockStyle}>
         <Text>
           Need assistance during verification or setup? Our support team is here to help. Reach out to us at: 
           <Link href="mailto:contact@wemakewebsites.co.nz" style={linkStyle}>contact@wemakewebsites.co.nz</Link>
         </Text>
       </div>

       <Form>
            <Form.Item 
              name="termsAndConditions"
              valuePropName="checked"
              initialValue={termsAccepted}
              rules={[
                { required: true, message: 'Please accept the Terms & Conditions!' },
              ]}
            >
              <Checkbox 
                checked={termsAccepted} 
                onChange={(e) => {
                  setTermsAccepted(e.target.checked);
                  handleCheckboxChange(e, "Terms & Conditions");
                }}
              >
                I have read and understood the <Link to="/termsAndConditions" target="_blank" style={linkStyle}>Terms & Conditions</Link>
              </Checkbox>
            </Form.Item>

            <Form.Item 
              name="endUserLicenseAgreement"
              valuePropName="checked"
              initialValue={licenseAccepted}
              rules={[
                { required: true, message: 'Please accept the End User License Agreement!' },
              ]}
            >
              <Checkbox 
                checked={licenseAccepted} 
                onChange={(e) => {
                  setLicenseAccepted(e.target.checked);
                  handleCheckboxChange(e, "End User License Agreement");
                }}
              >
                I have read and understood the <Link to="/endUserLicenseAgreement" target="_blank" style={linkStyle}>End User License Agreement</Link>
              </Checkbox>
            </Form.Item>
        </Form>
     </div>
   );
}

export default FinalSteps;