import React, { useCallback, useEffect, useRef, useState } from "react";
import NotificationCard from "../../components/notificationCard.jsx";
import TitleBar from "../../components/titleBar.jsx";
import styles from "./notifications.module.css";
import { useOutletContext } from "react-router-dom";
import { generalApiCall } from "../../scripts/apiCalls.jsx";
import LoadingScreen from "../../components/loadingScreen.jsx";

function Notifications(props) {
  const setToolbarIcon = useOutletContext();
  // console.log(setToolbarIcon)
  useEffect(() => {
    if (props.isMobile) {
      setToolbarIcon(props.tbIcon);
    }
  }, []);
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    setIsSmallScreen(mediaQuery.matches);
    const handleResize = () => setIsSmallScreen(mediaQuery.matches);
    mediaQuery.addEventListener("change", handleResize);
    return () => mediaQuery.removeEventListener("change", handleResize);
  }, []);

  const [notificationData, setNotificationData] = useState([]);
  const [lastId, setLastId] = useState();
  const [hasNextPage, setHasNextPage] = useState(true);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    generalApiCall("/getNotifications").then((res) => {
      setNotificationData(res.data);
      if (res.data.length !== 0) {
        setLastId(res.data[res.data.length - 1]._id);
      } else {
        setHasNextPage(false);
      }
      setIsLoading(false);
    });
  }, []);

  const intObserver = useRef();
  const lastNoteRef = useCallback(
    (post) => {
      console.log("useCallback called");
      if (isLoading) return;

      if (intObserver.current) intObserver.current.disconnect();
      intObserver.current = new IntersectionObserver((posts) => {
        if (posts[0].isIntersecting && hasNextPage) {
          console.log("We are near the last post!");
          setIsLoading(true);
          generalApiCall("/getNotifications", { lastId: lastId }).then(
            (res) => {
              console.log(res);
              if (res.data.length === 0) {
                setHasNextPage(false);
                setIsLoading(false);
              } else {
                setNotificationData((prev) => [...prev, ...res.data]);
                setLastId(res.data[res.data.length - 1]._id);
                setIsLoading(false);
              }
            }
          );
          // setLastId((prev) => prev + 1);
        }
      });

      if (post) intObserver.current.observe(post);
    },
    [isLoading, hasNextPage, lastId]
  );

  const notificationList = notificationData.map((notification, i) => {
    const key = `${notification._id}-${notification.timestamp}-${i}`;
    console.log(`Notification key: ${key}`);
    if (notificationData.length === i + 1) {
      return (
        <NotificationCard
          // key={notification._id}
          key={key}
          _id={notification._id}
          name={notification.refUserName}
          text={notification.text}
          url={notification.url}
          refUserId={notification.refUserId}
          timestamp={notification.timestamp}
          seen={notification.clicked}
          ref={lastNoteRef}
        ></NotificationCard>
      );
    } else {
      return (
        <NotificationCard
          key={notification._id}
          _id={notification._id}
          name={notification.refUserName}
          text={notification.text}
          url={notification.url}
          refUserId={notification.refUserId}
          timestamp={notification.timestamp}
          seen={notification.clicked}
        ></NotificationCard>
      );
    }
  });

  console.log(notificationData);
  if (isLoading && isSmallScreen) {
    return (<LoadingScreen/>)
  }
  return (
    <div
      className={`fillHeight ${styles.page} ${
        !isSmallScreen && styles.pageDesktop
      }`}
    >
      {/* {!isSmallScreen&&<TitleBar name="Notifications" hasBackButton="false"></TitleBar>} */}
      <div style={{ height: "2px" }}></div>

      {notificationList}
      {/* {isLoading && isSmallScreen && <LoadingScreen />} */}
      {!isLoading && notificationData.length === 0 && (
        <div
          style={{
            backgroundColor: "#f0f0f0", // Background color
            padding: "10px", // Padding
            borderRadius: "5px", // Rounded corners
            textAlign: "center", // Center text horizontally
            boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)", // Box shadow for a subtle effect
          }}
        >
          You have no notifications
        </div>
      )}
    </div>
  );
}

export default Notifications;
