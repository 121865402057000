import React, { useState, useEffect } from "react";
import styles from "./register.module.css";
import { LoadingOutlined, PlusOutlined, UserOutlined } from "@ant-design/icons";
import defaultImage from "../../src/images/defaultImage.png"
function RegisterUploadImage(props) {

  // const [profileImg, setProfileImg] = useState();
  const [imageUrl, setImageUrl] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (props.profileImg !== undefined) {
      setImage(props.profileImg)
    }
  }, [])

  function showPreview(event) {
    console.log(event)//console log is, look into event.target.files, first obj in that array
    //look at the formating of that object, see if its something we can construct
    //if so we make an object that looks like it
    //in the use effect we pretty much copy this fucntions functionality
    if (event.target.files.length > 0) {
      props.setProfileImg(event.target.files[0]);
      setImage(event.target.files[0])
    }
  }

  function setImage(file) {
    const src = URL.createObjectURL(file)
    setImageUrl(src);
    const preview = document.getElementById("imgPreview");
    const defaultImg = document.getElementById("defaultImage");
    preview.src = src;
    preview.style.display = "block";
    defaultImg.style.display = "none";
  }
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  return (

    <div className={styles.imgCtn}>
      <label className={styles.imageArea} htmlFor="img1" style={{ margin: "0px" }}>
        <div id="defaultImage" className={styles.defaultImage}>
          <span style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%", textAlign: "center" }}>
            <input id="img1" type="file" style={{ display: "none" }} accept="image" onChange={(event) => showPreview(event)}
              hidden />
            {uploadButton}
          </span>
        </div>
        <img
          id="imgPreview"
          className={styles.previewImg}
          alt="PreviewImage"
        />
      </label>
    </div>
  )
}


export default RegisterUploadImage;
