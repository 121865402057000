import React from "react";
import styles from "./messagesCard.module.css";

const MessageCard = React.forwardRef((props, ref) => {
  const timestamp = new Date(props.message.date);
  const time = timestamp.toLocaleString("en-NZ", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
  const date = timestamp.toLocaleString("en-NZ", {
    day: "numeric",
    month: "numeric",
    year: "2-digit",
  });

  function classStyle() {
    return (
      styles.msgContainer +
      " " +
      (props.isSender && styles.msgSender) +
      " " +
      (props.removeBottom && styles.removeBottom)
    );
  }
  const content = (
    <>
      <p className={styles.msgText}>{props.message.message}</p>
      {/* <div style={{display:"flex",justifyContent:"end"}}>
        <p className={styles.msgTimeStamp}>{time}</p>
        <p className={styles.msgTimeStamp}>{date}</p>
      </div> */}
    </>
  );

  function timestampStyle() {
    if (props.isSender) {
      return styles.msgTimeStampPos;
    } else {
      return styles.msgTimeStampPosNS;
    }
  }

  if (ref) {
    return (
      <div className={styles.msgTimeStampAlign}>
        {props.showTimestamp && (
          <div className={timestampStyle()}>
            <p className={styles.msgDateStamp}>{date}</p>
          </div>
        )}
        <div ref={ref} id={props.id} className={classStyle()}>
          {content}
          <p className={styles.msgTimeStamp}>{time}</p>
        </div>
      </div>
    );
  } else {
    return (
      <>
        <div className={styles.msgTimeStampAlign}>
          {props.showTimestamp && (
            <div className={timestampStyle()}>
              <p className={styles.msgDateStamp}>{date}</p>
            </div>
          )}
          <div className={classStyle()}>
            {content}
            <p className={styles.msgTimeStamp}>{time}</p>
          </div>
        </div>
      </>
    );
  }
});

export default MessageCard;
