import React from "react";
import styles from "./fileComment.module.css";
import { Card } from "antd";

function FileComment(props) {
  console.log(props);
  const timestamp = new Date(props.commentData.date);
  const time = timestamp.toLocaleString("en-NZ", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
  const date = timestamp.toLocaleString("en-NZ", {
    day: "numeric",
    month: "numeric",
    year: "2-digit",
  });
  const name =
    props.userNames[0][0] === props.commentData.userId
      ? props.userNames[0][1]
      : props.userNames[1][1];
  return (
    <Card
      headStyle={{ minHeight: 24 }}
      type="inner"
      title={name}
      extra={time + " " + date}
      size="small"
      bodyStyle={{ padding: 0 }}
    >
      <div style={{ display: "flex" }}>
        <div className={styles.imageContainter}>
          <img
            className={styles.image}
            alt="profile"
            src={"/getProfilePhoto/" + props.commentData.userId}
          />
        </div>
        <div>
          <p className={styles.comment}>{props.commentData.comment}</p>
        </div>
      </div>
    </Card>
  );
}

export default FileComment;
