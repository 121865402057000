import React, { useEffect } from "react";
import { Link, useOutletContext, useNavigate } from "react-router-dom";
import TitleBar from "../components/titleBar";
import { Button, Input, Space } from "antd";
import { generalApiCall } from "../scripts/apiCalls";
import styles from "./externalPackageLogin.module.css";

function ExternalPackageLogin() {
  const navigate = useNavigate();

  function login() {
    let accessCode = document.getElementById("externalAccessCode").value;
    generalApiCall("/packageExternalLogin", { accessCode: accessCode }).then(
      (res) => {
        console.log("api call");
        if (res.status === 401) {
        } else {
          navigate("/packages/" + res.data + "/" + accessCode, {
            replace: false,
          });
        }
      }
    );
  }

  return (
    <div className={"fillHeight "}>
      <TitleBar name="Settings" backButton={false}></TitleBar>
      <div className={styles.ctn}>
        <Space.Compact>
        <p> </p>
          <Input id="externalAccessCode" />
          <Button onClick={login} type="primary">
            Submit
          </Button>
        </Space.Compact>
      </div>
    </div>
  );
}

export default ExternalPackageLogin;
