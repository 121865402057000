import { useState, useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import TitleBar from "../../components/titleBar";
import styles from "./ptSettingsAccess.module.css";
import { generalApiCall } from "../../scripts/apiCalls";
import RemoveIcon from "../../components/icons/removeIcon"
import sysButtons from "../../styles/sysButtons.module.css"

function PtSettingsAccess(props) {
  const setToolbarIcon = useOutletContext();
  useEffect(() => {
    setToolbarIcon(props.tbIcon);
  }, []);

  const [codesData, setCodesData] = useState({
    loaded: false,
    data: [],
  });

  useEffect(()=>{
    generalApiCall("/getAccessCodes",{}).then((res)=>{
      if (res.data !=="") {
        setCodesData({...codesData, loaded:true,data:res.data})
      }
    })
  },[])

  function createNewCode(){
    generalApiCall("/createAccessCode",{}).then((res)=>{
      let updatedCodes = codesData.data.concat([res.data])
      setCodesData({...codesData, data: updatedCodes})
    })
  }

  //function to link the PT deleting code in app
  function deleteCode(event) {
    let code = event.currentTarget.id;
    console.log(code)

    generalApiCall("/removeAccessCode",{accessCode:code}).then((res)=>{
      setCodesData({...codesData, data: res.data})
    })

  }


  return (
    <div className={"fillHeight crosses " + styles.pageCtn}>
      <TitleBar name="Access Codes" backButton={true}></TitleBar>
      <div className={styles.ctn}>
        {/* <button className={styles.gButton} onClick={createNewCode}>Generate Code</button> */}
        <button className={ styles.gButton+" "+sysButtons.button + " " + sysButtons.buttonSolid} onClick={createNewCode}>Generate Code</button>
        <br/>
        {codesData.loaded && codesData.data.map((code)=>{
          return (
          <div key={code} className={styles.codeCtn}>
            <div>{code}</div>
            <div id={code} onClick={(e)=>{deleteCode(e)}} className={styles.svgCnt}>
            <RemoveIcon class={styles.svg}/>
            </div>
          </div>)
        })}
      </div>
    </div>
  );
}

export default PtSettingsAccess;