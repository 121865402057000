import React, { useState, useEffect } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import useAuth from "../../scripts/useAuth";
import styles from "./clientSettingsInfo.module.css";
import TitleBar from "../../components/titleBar";
import { Card, Button, Image, Modal } from "antd";
import { GetClientInfo, generalApiCall } from "../../scripts/apiCalls.jsx";
import { useParams } from "react-router-dom";

function ClientSettingsInfo(props) {
  const setToolbarIcon = useOutletContext();
  useEffect(() => {
    setToolbarIcon(props.tbIcon);
  }, []);

  let navigate = useNavigate();
  const { userData } = useAuth();
  const isPt = userData.isPt;

  let { clientId } = useParams();
  if (clientId === undefined) {
    clientId = userData._id;
  }

  const [deletePopup, setDeletePopup] = useState(false);

  const tmpUser = {
    _id: "",
    firstName: "loading..",
    lastName: "loading..",
    gender: "loading..",
    dob: "00",
    mobile: "loading..",
    username: "loading..",
    address: {
      street: "loading..",
      town: "loading..",
      country: "loading..",
    },
    dateCreated: "",
    lastActive: "",
  };
  const [userInfo, setUserInfo] = useState(tmpUser);
  const [infoLoaded, setInfoLoaded] = useState(false);

  useEffect(() => {
    if (isPt) {
      GetClientInfo(clientId).then((res) => {
        // console.log(res);
        setUserInfo(res);
        setInfoLoaded(true);
      });
    } else {
      generalApiCall("/getMyInfo").then((result) => {
        setUserInfo(result.data);
        setInfoLoaded(true);
      });
    }
  }, []);

  function toggleDelete() {
    setDeletePopup(!deletePopup);
  }

  function deleteOff() {
    console.log("Clicked");
    setDeletePopup(false);
  }

  function navEdit() {
    navigate("/client/settings/info/edit", { replace: false });
  }

  function EditButton() {
    return (
      <Button block type="primary" onClick={navEdit}>
        Edit
      </Button>
    );
  }

  function DeleteButton() {
    return (
      <Button block danger type="dashed" onClick={toggleDelete}>
        Delete
      </Button>
    );
  }

  function deleteUserSubmit() {
    generalApiCall("/deleteProfile", {
      clientId: clientId,
    }).then((res) => {
      navigate("/pt", { replace: true });
    });
  }
  function dateToAge(dob) {
    console.log(dob);
    const timeDiff = new Date(new Date() - new Date(dob));
    const age = timeDiff.getUTCFullYear() - 1970; //time counts from 1970
    return age;
  }

  return (
    <div className={"fillHeight crosses " + styles.container}>
      <TitleBar name="Personal Info" backButton={true}></TitleBar>
      <div className={styles.body}>
        <div className={styles.cnt1}>
          <div className={styles.square}>
            <Image
              rootClassName={styles.imageCtn}
              className={styles.image}
              src={
                "/getProfilePhoto/" +
                (isPt ? clientId : userData._id) +
                "?dummy=" +
                new Date().getMilliseconds()
              }
            ></Image>
          </div>

          <div className={styles.cnt2}>
            <Card size="small">
              <Card.Meta
                title={userInfo.firstName + " " + userInfo.lastName}
                description={userInfo.gender + ", " + dateToAge(userInfo.dob)}
              />
            </Card>
            <div style={{ height: "12px" }}></div>

            <Card
              size="small"
              title="Contact"
              bodyStyle={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <p style={{ margin: "0px" }}>{userInfo.mobile}</p>
              <p style={{ margin: "0px" }}>{userInfo.username}</p>
            </Card>
            <div style={{ height: "12px" }}></div>
            <Card
              size="small"
              title="Address"
              bodyStyle={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <p style={{ margin: "0px" }}>{userInfo.address.street}</p>
              <p style={{ margin: "0px" }}>{userInfo.address.town}</p>
              <p style={{ margin: "0px" }}>{userInfo.address.country}</p>
            </Card>
          </div>
        </div>
        <div>
          <div className={styles.btn}>
            {isPt && clientId !== userData._id ? (
              <DeleteButton />
            ) : (
              <EditButton />
            )}
          </div>
          <div className={styles.activeInfo}>
            <div className={styles.activeContainer}>
              <div className={styles.text}>Created</div>
              <div className={styles.created}>
                {new Date(userInfo.dateCreated).toLocaleString("en-NZ", {
                  day: "numeric",
                  month: "numeric",
                  year: "2-digit",
                })}
              </div>
            </div>
            <div className={styles.activeContainer}>
              <div className={styles.text}>Last Active</div>
              <div className={styles.active}></div>
              <div className={styles.active}>
                {new Date(userInfo.lastActive).toLocaleString("en-NZ", {
                  day: "numeric",
                  month: "numeric",
                  year: "2-digit",
                })}
              </div>
            </div>
          </div>
          <Modal
            open={deletePopup}
            title={
              "Delete " + userInfo.firstName + " " + userInfo.lastName + "?"
            }
            okText="Delete"
            okButtonProps={{ danger: true }}
            okType="dashed"
            onOk={deleteUserSubmit}
            onCancel={deleteOff}
            cancelButtonProps={{ type: "primary" }}
            centered
          >
            <p>This can not be undone.</p>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default ClientSettingsInfo;
