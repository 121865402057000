import React, { useEffect } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
// import { useLocation} from "react-router-dom";
import styles from "./loginExample2.module.css";
import useAuth, { setCssVars } from "../../scripts/useAuth";
import { Typography } from "antd";
const { Title, Text } = Typography;

function LoginExample2(props) {
  const navigate = useNavigate();
  const { login, authed, userData } = useAuth();
  const urlQuery = new URLSearchParams(window.location.search);
  const location = useLocation();

  function handleSubmit(e) {
    e.preventDefault();
    const user = {
      username: e.target[0].value,
      password: e.target[1].value,
    };

    console.log(window.history);
    login(user).then((response) => {
      //https://stackoverflow.com/questions/54069253/the-usestate-set-method-is-not-reflecting-a-change-immediately
      let nav = "/client";
      if (response === true) {
        nav = "/pt";
      }
      if (location.state?.path && location.state?.path !== "/client") {
        navigate(location.state?.path, { replace: true });
      } else {
        navigate(nav, { replace: true });
      }
    });
  }

  useEffect(() => {
    console.log(process.env.REACT_APP_TEST);
    if (urlQuery.get("success")) {
      setCssVars();
    }
    console.log("checking auth");
    if (authed) {
      console.log("isAuthed");
      let nav = "/client";
      if (userData.isPt === true) {
        nav = "/pt";
      }
      navigate(nav, { replace: true });
    }
  }, [authed]);

  return (
    <div
      className={
        styles.backgroundColour + " fillHeight horizontalCenter verticalCenter"
      }
    >
      <div className={styles.loginCtn + " horizontalCenter verticalCenter"}>
        <Title level={3}>Silly Goose!</Title>
        <Text type="secondary">
          Don't have an account?{" "}
          <Link className="registerLink" to="/register">
            <p className={styles.registerLinkText}>Register</p>
          </Link>
        </Text>
        <div style={{ margin: "14px" }}></div>
        <form className="loginForm horizontalCenter" onSubmit={handleSubmit}>
          <div className={styles.labelCtn}>
            <Text type="secondary">Your e-mail</Text>
          </div>
          <input type="text" placeholder="Email.." className="loginInput" />
          <div className={styles.labelCtn}>
            <Text type="secondary">Password</Text>
          </div>
          <input
            type="password"
            placeholder="Password.."
            className="loginInput"
          />
          <div className={styles.forgetPasswordCtn}>
            <Link className={styles.forgotPasswordLink} to="/forgotPassword">
              <Text type="secondary">
                <p className={styles.forgotPasswordLinkText}>Forgot Password?</p>
              </Text>
            </Link>
          </div>
          <button type="submit" className="loginButton loginSubmit">
            Submit
          </button>
        </form>

        <p className="loginNotice">Prototype v0.9.3</p>
      </div>
    </div>
  );
}

export default LoginExample2;
