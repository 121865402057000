import React, { useEffect, useState } from "react";
import styles from "../routes/register.module.css";
import { generalApiCall } from "../scripts/apiCalls";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Card, Typography, Button, Radio, Modal, Form, Input } from "antd";
import SubscriptionEditModal from "./subscriptionEditModal";
const { Title } = Typography;

function SubscriptionsList(props) {
  const [searchParams] = useSearchParams();
  const code = searchParams.get("accessCode");
  const [subscriptions, setSubscriptions] = useState();
  const [selectedInd, setSelectedInd] = useState(0);
  const [isModalOpenMore, setIsModalOpenMore] = useState(false);
  const [subscriptionMoreSelected, setSubscriptionMoreSelected] =
    useState(undefined);
  useEffect(() => {
    generalApiCall("/getSubscriptionsList", { code: code }).then((result) => {
      setSubscriptions(result.data);
      // console.log(result.data)
      // console.log(code)
      // console.log(props.selectedSub)
      if (props.selectedSub !== null) {
        let ind = result.data.findIndex(o => o._id === props.selectedSub);
        if (ind !== -1) {
          setSelectedInd(ind);
        }
      }
      else{
        props.selectFunc(result.data[0]._id)
      }
    });
  }, []);

  // const showModalMore = (obj) => {
  //   setSubscriptionMoreSelected(obj);
  //   setIsModalOpenMore(true);
  // };
  const handleCancel = () => {
    setIsModalOpenMore(false);
  };
  const handleOkMore = () => {
    setIsModalOpenMore(false);
  };



  const selectSubscription = (index, subId) => {
    setSelectedInd(index);
    props.selectFunc(subId);
    setIsModalOpenMore(false);
  };

  const showModalMore = (obj, index) => {
    // console.log(obj)
    setSubscriptionMoreSelected(obj);
    setIsModalOpenMore(true);
  };
  return (
    <div className={styles.subGrid}>
      {subscriptions &&
        subscriptions.map((sub, ind) => {
          return (
            <Card
              bodyStyle={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                position: "relative",
                padding: "24px 5%",
                // maxWidth:"calc(100%-24px)"
              }}
              className={styles.subCard}
            // onClick={() => {
            //   setSelectedInd(ind);
            //   props.selectFunc(subscriptions[ind]._id);
            // }} // lachie comment out
            >
              {props.context === "Register" && (
                <Radio.Group
                  className={styles.subRadio}
                  value={ind === selectedInd ? 1 : 0}
                  onChange={() => { }} // lachie change
                >
                  <Radio value={1}></Radio>
                </Radio.Group>
              )}
              <div>
                <Title className={styles.subTitle} level={3}>
                  {sub.name}
                </Title>
                <div
                  style={{ margin: "8px 0px", borderBottom: "1px black solid" }}
                ></div>
                <p style={{ fontSize: "1.5rem", margin: "0px auto" }}>
                  {(sub.userIds !== null && code === null)
                    ? "Current"
                    : "$" + sub.price / 100 + " "}
                  {sub.userIds === null && (
                    <span style={{ fontSize: "18px", color: "gray" }}>
                      Per Week
                    </span>
                  )}
                </p>
              </div>
              {/* <br /> */}
              <p
                style={{
                  whiteSpace: "pre-line",
                  padding: "0px 0px",
                }}
              >
                {sub.shortDescription}
              </p>
              <div style={{ display: "flex", marginTop: "auto" }}>
                {props.context === "PT" && (
                  <>
                    <Button
                      block
                      onClick={() => {
                        props.showModalEdit(sub);
                      }}
                    >
                      Edit
                    </Button>
                    <div style={{ marginRight: 4 }}></div>
                  </>
                )}
                <Button block type="primary" onClick={() => showModalMore(sub)}>
                  See More
                </Button>
              </div>
            </Card>
          );
        })}
      {subscriptionMoreSelected !== undefined && (
        <Modal
          title={
            <Title className={styles.subTitle} level={3}>
              {subscriptionMoreSelected.name}
            </Title>
          }
          centered
          // okText="Subscribe"
          open={isModalOpenMore}

          footer={[
            <Button key="close" onClick={handleCancel}>
              Close
            </Button>,
            (subscriptionMoreSelected.userIds !== null || props.context === "PT")? null : <Button key="subscribe" type="primary" onClick={() =>
              selectSubscription(subscriptions.findIndex(sub => sub._id === subscriptionMoreSelected._id), subscriptionMoreSelected._id)}>
              Select
            </Button>,
            // <Button key="subscribe" type="primary" onClick={() =>
            //   selectSubscription(subscriptions.findIndex(sub => sub._id === subscriptionMoreSelected._id), subscriptionMoreSelected._id)}>
            //   Subscribe
            // </Button>,
          ]}
          okText={"Subscribe"}
          onOk={() => selectSubscription(subscriptions.findIndex(sub => sub._id === subscriptionMoreSelected._id), subscriptionMoreSelected._id)}
          onCancel={handleCancel}
        >
          <div style={{ width: "100%", borderBottom: "1px solid black" }}></div>
          <p style={{ fontSize: "1.5rem", margin: "0px auto" }}>
            {"$" + subscriptionMoreSelected.price / 100 + " "}
            <span style={{ fontSize: "18px", color: "gray" }}>Per Week</span>
          </p>
          <p style={{ whiteSpace: "pre-line" }}>
            {subscriptionMoreSelected.longDescription}
          </p>
        </Modal>
      )}

    </div>
  );
}

export default SubscriptionsList;
