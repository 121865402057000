import { useState, useEffect } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import TitleBar from "../../components/titleBar";
import styles from "./ptSettingsBooking.module.css";
import { generalApiCall } from "../../scripts/apiCalls";
import PackagesLists from "../../components/packagesList";
import PackageEditModal from "../../components/packageEditModal";
import { Button } from "antd";

function PtSettingsPackages2(props) {
  const navigate = useNavigate();
  
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState(null);
  // const [dataLoaded, setDataLoaded] = useState(false);

  const handleEditClick = (packageData) => {
    setSelectedPackage(packageData);
    setIsModalOpen(true);
  };

  const handleModalCancel = () => {
    setIsModalOpen(false);
    setSelectedPackage(null);
  };

  return (
    <div className={"fillHeight crosses " + styles.pageCtn}>
      <TitleBar name="Package Settings" backButton={true}></TitleBar>
      <div style={{ margin: "24px" }}>
        <PackagesLists onEditClick={handleEditClick} />

      </div>
      {selectedPackage !== null && (
        <PackageEditModal
          open={isModalOpen}
          onCancel={handleModalCancel}
          packageData={selectedPackage}
          // Other props as needed
        />
      )}
    </div>
  );
}

export default PtSettingsPackages2;
