import React, { useEffect, useState } from 'react';
import styles from './schedule.module.css';

function EventSchedule(props) {
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 768px)');
    setIsSmallScreen(mediaQuery.matches);
    const handleResize = () => setIsSmallScreen(mediaQuery.matches);
    mediaQuery.addEventListener('change', handleResize);
    return () => mediaQuery.removeEventListener('change', handleResize);
  }, []);

  function eventStyle(event) {
    const offset = -props.startHour + 150 / props.hourHeight;
    const startTime = event.startHour + event.startMin / 60 + offset;
    const endTime = event.endHour + event.endMin / 60 + offset;
    const rowStart = startTime * props.hourHeight - 10;
    const columnStart = event.day * props.dayWidth;
    const eventHeight = (endTime - startTime) * props.hourHeight;
    const colour = !props.free && 'lightsalmon';
    return {
      top: rowStart,
      left: columnStart + 40 + 1 + (isSmallScreen ? 0:256),
      width: props.dayWidth - 2,
      height: `${eventHeight}px`,
      // backgroundColor: colour,
    };
  }

  function eventClassStyle(){
    return props.free ? styles.eventAvailable : styles.eventNotAvailable
  }
  return (
    <div id='schedule'>
      {props.bookingData.map(day => (
        day.data.map(event => {
          const startTime = event.startHour + event.startMin / 60;
          const eventKey = `${event.day} ${startTime} ${event.name}`;
          if(event.name === "Day Not Available" || event.name === "before Not Available" || event.name === "After Not Available"){
            return null;
          }

          return (
            <div
              onClick={() => props.daySelectFunction(event)}
              key={eventKey}
              style={eventStyle(event)}
              className={`${styles.eventCtn} ${eventClassStyle()}`}
            >
              {props.showText && (
                <div>
                  <div className={styles.eventText}>{event.name}</div>
                  {/* <span className={styles.eventText}>
                       {startTime} - {endTime}
                      </span> */}
                </div>
              )}
            </div>
          );
        })
      ))}
    </div>
  );
}

export default EventSchedule;

