import React, { useState, useRef } from "react";
import styles from "./register.module.css";
import TitleBar from "../components/titleBar";
import useAuth from "../scripts/useAuth";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { uploadProfilePhoto } from "../scripts/apiCalls";
import RegisterForm from "./registerForm.jsx"; // Import the new RegisterForm component
import { Steps, Button, Upload, Form, Modal } from "antd";
import SubscriptionsList from "../components/subscriptionsList";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import RegisterUploadImage from "./registerUploadImage";
import FinalSteps from "./finalSteps";
import { generalApiCall } from "../scripts/apiCalls";

function RegisterPage() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { register } = useAuth();
  const [form] = Form.useForm();
  const [searchParams] = useSearchParams();

  const [profileImg, setProfileImg] = useState();
  const [currentStep, setCurrentStep] = useState(0);
  const [formData, setFormData] = useState({});
  // const [imageUrl, setImageUrl] = useState();
  const [selectedSub, setSelectedSub] = useState(null);

  const [termsAccepted, setTermsAccepted] = useState(false);
  const [licenseAccepted, setLicenseAccepted] = useState(false);
  const [showAgreementModal, setShowAgreementModal] = useState(false);

  function handleSubmit(e) {
    e.preventDefault();
    //check if both passwords are equal

    if (!termsAccepted || !licenseAccepted) {
      setShowAgreementModal(true);
      return;
  }

    const code = searchParams.get("accessCode");
    let user = {
      ptId: code,
      username: formData.email.toLowerCase(),
      firstName: formData.fName, //format
      lastName: formData.lName, //format
      mobile: formData.phNumber,
      dob: formData.dob, //double check format
      gender: formData.gender,
      address: {
        street: formData.street,
        town: formData.town,
        country: formData.country,
      },
      password: formData.password1,
      subscriptionId: selectedSub,
    };
    console.log(formData);
    console.log(user);

    register(user).then((response) => {
      console.log(response)
      if (response.status === 500) {
        //error in account creation or email send
      } else if (response.status === 400) {
        //no pt found
      } else if (response.status === 200) {
        console.log("trying to upload image")
        if (profileImg !== undefined) {
          uploadProfilePhoto(profileImg).then((res) => {
            console.log("upload image attempted")
            console.log(res)
            navigate("/");
          });
        } else {
          console.log("no image selected")
          navigate("/");
        }
      }
    });
  }

  function updateFormData(dir) {
    form
      .validateFields()
      .then((values) => {
        // form.resetFields();
        setFormData(values);
        console.log(values);
        let date = new Date(values.dob);
        console.log(date);
        //forward = 1, back = -1
        setCurrentStep(currentStep + dir);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  }

  // function selectSubscription(subId){

  // }

  return (
    <div className={"fillHeight crosses " + styles.cnt}>
      {/* <TitleBar backButton={true} name="Register" /> */}
      <div>
        {/* <form id="registerForm" className={styles.form}> */}
        <form id="registerForm" className={styles.form} onSubmit={handleSubmit}>
          <div style={{ margin: "16px" }}></div>
          <Steps
            responsive={false}
            direction="horizontal"
            size="small"
            current={currentStep}
            items={[
              {
                title: "", //Subscription List
              },
              {
                title: "", //User Details
              },
              {
                title: "", //Upload Photo
              },
              {
                title: "", //Stripe setup
              },
            ]}
          ></Steps>
          <div style={{ margin: "16px" }}></div>
          {currentStep === 0 && (
            <SubscriptionsList
              context="Register"
              selectedSub={selectedSub}
              selectFunc={setSelectedSub}
            />
          )}
          {currentStep === 1 && (
            <RegisterForm formData={formData} formRef={form} />
          )}
          <div className={styles.centreImage}>
            {currentStep === 2 && (
              <RegisterUploadImage
                setProfileImg={setProfileImg}
                profileImg={profileImg}
              ></RegisterUploadImage>
            )}
          </div>

          {currentStep === 3 && (
            <FinalSteps
              termsAccepted={termsAccepted}
              setTermsAccepted={setTermsAccepted}
              licenseAccepted={licenseAccepted}
              setLicenseAccepted={setLicenseAccepted}
            />
          )}

          <div className={styles.button + " " + styles.grid}>
            {currentStep > 0 && (
              <Button
                style={{ marginRight: "8px" }}
                onClick={() => {
                  if (currentStep === 1) {
                    updateFormData(-1);
                  } else {
                    setCurrentStep(currentStep - 1);
                  }
                }}
              >
                Previous
              </Button>
            )}
            {currentStep === 0 && (
              <Button
                type="primary"
                className={styles.nextButton}
                onClick={() => {
                  setCurrentStep(currentStep + 1);
                }}
              >
                Next
              </Button>
            )}
            {currentStep === 1 && (
              <Button
                type="primary"
                onClick={() => {
                  updateFormData(1);
                  // setCurrentStep(currentStep + 1);
                }}
              >
                Next
              </Button>
            )}
            {currentStep === 2 && (
              <Button
                type="primary"
                onClick={() => {
                  setCurrentStep(currentStep + 1);
                }}
              >
                Next
              </Button>
            )}
            {currentStep === 3 && (
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            )}
            {showAgreementModal && (
              <Modal
                title="Reminder"
                open={showAgreementModal}
                onOk={() => setShowAgreementModal(false)}
                onCancel={() => setShowAgreementModal(false)}
              >
                <p>
                  Please accept both the Terms & Conditions and the End User
                  License Agreement before proceeding.
                </p>
              </Modal>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}

export default RegisterPage;
