import React, { createContext, useState, useEffect } from "react";
import * as authService from "./authService";

const authContext = createContext();

function useAuth() {
  const [authed, setAuthed] = useState();
  const [userData, setUserData] = useState(null);

  async function initLoggedIn() {
    try {
      let data = await authService.initLoggedIn();
      data = data.data;
      console.log(data);

      if (data) {
        setUserData(data);
        setAuthed(true);
        setCssVars(data._id);
        return true;
      } else {
        setAuthed(false);
        return false;
      }
    } catch (error) {
      console.log("failed, " + error.message);
      setAuthed(false);
    }
  }

  useEffect(() => {
    initLoggedIn();
  }, []);

  async function login(user) {
    try {
      let loginResponse = await authService.login(user);
      console.log(loginResponse);
      //data.data on fail === "error", consider changng authService error responses better
      if (loginResponse.error !== "Unauthorized") {
        loginResponse = loginResponse.data;
        // if(loginResponse.deactivated){

        // }
        setUserData(loginResponse.user);
        setAuthed(true);
        setCssVars(loginResponse.user._id);
        return { isPt: loginResponse.user.isPt, error: false };
      } else {
        return { isPt: undefined, error: true };
      }
    } catch (error) {
      console.log("failed, " + error.message);
      setAuthed(false);
      return;
    }
  }

  async function register(user) {
    try {
      let data = await authService.register(user);
      // data = data.data
      // setUserData(data.user);
      // setAuthed(true);
      return data;
    } catch (error) {
      console.log("failed, " + error.message);
      setAuthed(false);
      return;
    }
  }

  async function logout() {
    try {
      await authService.logout();
      setAuthed(false);
    } catch (error) {
      console.log("failed, " + error.message);
      setAuthed(false);
    }
  }

  async function updateUserData(data) {
    setUserData({
      ...userData,
      username: data.username,
      firstName: data.firstName,
      lastName: data.lastName,
    });
  }

  return {
    authed,
    userData,
    updateUserData,
    login,
    register,
    initLoggedIn,
    logout,
  };
}

export function setCssVars(id) {
  authService.getHubColours(id).then((res) => {
    let root = document.querySelector(":root").style;
    root.setProperty("--primaryDark", res.data.primaryDark);
    root.setProperty("--primary", res.data.primary);
    root.setProperty("--primaryLight", res.data.primaryLight);
    root.setProperty("--secondaryDark", res.data.secondaryDark);
    root.setProperty("--secondary", res.data.secondary);
    root.setProperty("--grayDark", res.data.grayDark);
    root.setProperty("--grayLight", res.data.grayLight);
    root.setProperty("--primaryBg", res.data.primaryBg);
    root.setProperty("--offWhite", res.data.offWhite);
    root.setProperty("--textColour", res.data.textColour);
  });
}

export function AuthProvider({ children }) {
  const auth = useAuth();

  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export default function AuthConsumer() {
  return React.useContext(authContext);
}
