import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";

import "./App.css";
import PtToolbar from "./components/ptToolbar.jsx";
import DesktopToolbar from "./components/DesktopToolbar";
import MobileToolbar from "./components/MobileToolbar";
import Notifications from "./routes/shared/notifications";

function Pt() {
  const [toolbarIcon, setToolbarIcon] = useState()
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 768px)');
    setIsSmallScreen(mediaQuery.matches);
    const handleResize = () => setIsSmallScreen(mediaQuery.matches);
    mediaQuery.addEventListener('change', handleResize);
    return () => mediaQuery.removeEventListener('change', handleResize);
  }, []);

  console.log(toolbarIcon);
  return (
    <div className="rootContainer">
      {!isSmallScreen && <DesktopToolbar isPt={true}/>}
      <Outlet context={setToolbarIcon} isMobile={isSmallScreen}/>
      {isSmallScreen && <MobileToolbar selected={toolbarIcon}></MobileToolbar>}
      {!isSmallScreen && <Notifications isMobile={isSmallScreen} />}
    </div>
  );
}

export default Pt;
