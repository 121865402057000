import { Button, Form, Input, Modal, Radio, InputNumber } from "antd";
import { useState, useEffect } from "react";
import { UserOutlined } from "@ant-design/icons";

const FeedbackModal = ({ open, onCreate, onCancel }) => {
  const [form] = Form.useForm();
  const { TextArea } = Input;

  return (
    <Modal
      centered
      open={open}
      title="Send Feedback"
      okText="Submit"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onCreate(values.feedbackText);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <Form form={form} layout="vertical" name="form_in_modal">
        <Form.Item
          name="feedbackText"
          min={0}
          // label="Hi"
          className="collection-create-form_last-form-item"
        >
          <TextArea rows={4}></TextArea>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default FeedbackModal;
