import React, { useEffect, useState } from "react";
import { generalApiCall } from "../scripts/apiCalls";
// import { useNavigate,} from "react-router-dom";
import { Card, Typography, Button, List, Modal } from "antd";
// import { PlusCircleOutlined } from "@ant-design/icons";
import styles from "../routes/register.module.css";
import PackageEditModal from "../components/packageEditModal";

const { Title } = Typography;

function PackagesList(props) {
  // const navigate = useNavigate();
  const [packagesSaleableData, setPackagesSaleableData] = useState([]);
  const [packagesNotSaleableData, setPackagesNotSaleableData] = useState([]);
  const [packagesLoaded, setPackagesLoaded] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [packageData, setPackageData] = useState([]);

  useEffect(() => {
    generalApiCall("/getPackageList", {}).then((res) => {
      setPackagesSaleableData(res.data.filter((el) => el.saleable));
      setPackagesNotSaleableData(res.data.filter((el) => !el.saleable));
      setPackagesLoaded(true);
    });
  }, []);

  const linkClicked = (itemId) => {
    // Handle click logic here
  };

  const showModal = (item) => {
    setSelectedItem(item);
    setIsModalVisible(true);
  };

  const handleModalCancel = () => {
    setIsModalOpen(false);
    setIsModalVisible(false);
  };

  const handleEditClick = (item) => {
    setSelectedPackage(item);
    setIsModalOpen(true);
  };

  const renderCard = (item, ind) => (
    <Card
      key={ind}
      bodyStyle={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        position: "relative",
        padding: "24px 5%",
      }}
      className={styles.subCard}
    >
      <div>
        <Title className={styles.subTitle} level={3}>
          {item.name}
        </Title>
        <div
          style={{
            margin: "8px 0px",
            borderBottom: "1px black solid",
          }}
        ></div>
        <p style={{ fontSize: "1.5rem", margin: "0px auto" }}>
          {`$${(item.price / 100).toFixed(2).replace(/\.00$/, "")}`}
        </p>
      </div>
      <p
        style={{
          whiteSpace: "pre-line",
          padding: "0px 0px",
          maxHeight: "200px",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {item.description}
      </p>
      <div style={{ display: "flex", marginTop: "auto" }}>
        <Button onClick={() => showModal(item)}>See More</Button>
        {props.isPT === undefined && (
          <>
            <Button block onClick={() => handleEditClick(item)}>
              Edit
            </Button>
            <div style={{ marginRight: 4 }}></div>
          </>
        )}
      </div>
    </Card>
  );

  return (
    <div className={"fillHeight crosses " + styles.pageCtn}>
      <div
        style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}
      >
        {/* ... (other code) */}
      </div>
      <div className={styles.bodyCtn}>
        <div style={{ marginTop: 6 }}></div>
        {packagesLoaded && packagesSaleableData.length > 0 && (
          <>
            <div className={styles.subGrid}>
              {packagesSaleableData.map((sub, ind) => renderCard(sub, ind))}
            </div>
            <h2 style={{ textAlign: "center" }}>
              {packagesSaleableData.length}/4
            </h2>
          </>
        )}
        {packageData?.length < 4 && (
          <Button
            style={{ margin: "24px 0px 0px 0px" }}
            type="primary"
            block
            onClick={() => handleEditClick("new")}
          >
            Add New
          </Button>
        )}

        <h2 style={{ textAlign: "center" }}>Not Saleable</h2>
        <List
          itemLayout="horizontal"
          dataSource={packagesNotSaleableData}
          size="small"
          renderItem={(item, index) => (
            <List.Item
              style={{
                padding: "8px 5%",
                backgroundColor: "lightGrey",
                opacity: "0.7",
                cursor: "pointer",
                borderRadius: "8px",
                margin: "1px 2px",
              }}
              onClick={() => linkClicked(item._id)}
            >
              <List.Item.Meta
                title=<div
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    marginRight: "15px",
                  }}
                >
                  {item.name}
                </div>
                description={
                  <div
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {item.description}
                  </div>
                }
              />
              <h3 style={{ margin: "0 0 auto 0" }}>${item.price / 100}</h3>
            </List.Item>
          )}
        />
      </div>
      <PackageEditModal
        open={isModalOpen}
        onCancel={handleModalCancel}
        packageData={selectedPackage}
        // Other props as needed
      />
    </div>
  );
}

export default PackagesList;