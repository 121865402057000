import React, { useCallback, useEffect, useRef, useState } from "react";
import styles from "./messages.module.css";
import MessageCard from "../../components/messageCard.jsx";
import useAuth from "../../scripts/useAuth.jsx";
import { generalApiCall } from "../../scripts/apiCalls.jsx";
import TitleBar from "../../components/titleBar";
// import SendIcon from "../../components/icons/sendIcon";
import { useOutletContext, useParams } from "react-router-dom";
import { Input } from "antd";
import { SendOutlined } from "@ant-design/icons";
let scrollToBottom = true;

function Messages(props) {
  if (window.scrollY < 10) {
    window.scrollTo(0, window.scrollY + 2);
  }

  const setToolbarIcon = useOutletContext();
  useEffect(() => {
    setToolbarIcon(props.tbIcon);
  }, []);

  let { clientId } = useParams();
  const { userData } = useAuth();

  const messagesTmp = {
    messagesArray: [],
    ptId: "",
    ptName: "",
    clientId: "",
    clientName: "",
  };

  const [toolbarH, setToolbarH] = useState(46);

  const [messagesObj, setMessagesObj] = useState(messagesTmp);
  const [firstMessageId, setFirstMessageId] = useState();
  const [hasPreviousMessages, setHasPreviousMessages] = useState(true); //?
  const [isLoadingNew, setIsLoadingNew] = useState(false);

  React.useEffect(() => {
    function handleResize() {
      let box = document.getElementById(
        userData.isPt ? "ptToolbar" : "clientToolbar"
      );
      if (box) {
        let height = box.offsetHeight;
        setToolbarH(height);
      } else {
        setToolbarH(0);
      }
    }
    handleResize();
    window.addEventListener("resize", handleResize);
    return window.removeEventListener("resize", handleResize);
  });

  React.useEffect(() => {
    const interval = window.setInterval(() => {
      updateMessages();
    }, 2000);
    return () => window.clearInterval(interval);
  }, [messagesObj]);

  React.useEffect(() => {
    generalApiCall("/getMessages", { clientId: clientId }).then((result) => {
      if (result.data !== "") {
        setFirstMessageId(result.data.messagesArray[0]._id);
        setMessagesObj(result.data);
        // setIsLoadingNew(false);
        window.scrollTo(0, document.body.scrollHeight);
      }
    });
  }, []);

  const intObserver = useRef();
  const lastMessRef = useCallback(
    (post) => {
      console.log("useCallback called");
      if (isLoadingNew) return;

      if (intObserver.current) intObserver.current.disconnect();
      intObserver.current = new IntersectionObserver((posts) => {
        if (posts[0].isIntersecting && hasPreviousMessages) {
          console.log("We are near the last post!");
          setIsLoadingNew(true);
          console.log(firstMessageId);

          generalApiCall("/getMessagesPrevious", {
            clientId: clientId,
            lastId: firstMessageId,
          }).then((res) => {
            if (res.data.messagesArray.length === 0) {
              setHasPreviousMessages(false);
              setIsLoadingNew(false);
            } else {
              let messagesTmp = res.data.messagesArray;
              messagesTmp = messagesTmp.concat(messagesObj.messagesArray);
              setMessagesObj({ ...messagesObj, messagesArray: messagesTmp });
              setFirstMessageId(res.data.messagesArray[0]._id);
              setIsLoadingNew(false);

              // window.scrollTo(0, document.body.scrollHeight);
            }
          });
          // setLastId((prev) => prev + 1);
        }
      });

      if (post) intObserver.current.observe(post);
    },
    [isLoadingNew, hasPreviousMessages, firstMessageId, clientId, messagesObj]
  );

  function isSender(messageName) {
    if (messageName === userData._id) {
      return true;
    }
    return false;
  }

  function nextIsSame(firstMsg, secondMsg) {
    if (firstMsg.senderId === secondMsg.senderId) return true;
  }

  function updateMessages() {
    //messagesObj when used from the interval is always the inital val
    if (messagesObj.messagesArray.length === 0) {
      console.log("Update, but msg empty. get all messages");
      generalApiCall("/getMessages", { clientId: clientId }).then((result) => {
        if (result.data !== "") {
          console.log(result.data);
          setMessagesObj(result.data);
        }
      });
    } else {
      const lastId =
        messagesObj.messagesArray[messagesObj.messagesArray.length - 1]._id;
      generalApiCall("/getMessagesUpdate", {
        lastId: lastId,
        clientId: clientId,
      }).then((response) => {
        if (response.data.messagesArray.length !== 0) {
          const newMessages = response.data.messagesArray;
          let messagesTmp = messagesObj.messagesArray;
          messagesTmp = messagesTmp.concat(newMessages);
          setMessagesObj({ ...messagesObj, messagesArray: messagesTmp });
          scrollToBottom = true;
        }
      });
    }
  }

  function sendCallback() {
    const messageInp = document.getElementById("messageInput");
    const messageText = messageInp.value;
    messageInp.value = "";
    // console.log(messageInp.text)
    // messageInp.value = "";
    if (messageText !== "") {
      generalApiCall("/sendMessage", {
        clientId: clientId,
        message: messageText,
      }).then((response) => {
        console.log(response.data !== "");
        if (response.data !== "") {
          messageInp.value = "";
        }
        updateMessages();
      });
    }
  }

  function EnterPressed(event) {
    if (event.key === "Enter") {
      sendCallback();
    }
  }

  const messagesList = messagesObj.messagesArray.map((message, index) => {
    let isRemove = false;
    if (messagesObj.messagesArray.length - 1 > index) {
      isRemove = nextIsSame(message, messagesObj.messagesArray[index + 1]);
    }
    let showTimestamp = false;

    if (index !== 0) {
      const timestampLast = new Date(messagesObj.messagesArray[index -1].date);
      const timestampCurrent = new Date(messagesObj.messagesArray[index].date);
      const dateLast = timestampLast.toLocaleString("en-NZ", {
        day: "numeric",
        month: "numeric",
        year: "2-digit",
      });
      const dateCurrent = timestampCurrent.toLocaleString("en-NZ", {
        day: "numeric",
        month: "numeric",
        year: "2-digit",
      });
      if(dateLast !== dateCurrent){
        showTimestamp = true;
      }
      console.log(dateLast + " " + dateCurrent)
    }
    if (index === 0) {
      // scrollId = message._id;
      return (
        <MessageCard
          key={message._id}
          id={message._id}
          message={message}
          isSender={isSender(message.senderId)}
          removeBottom={isRemove}
          ref={lastMessRef}
          showTimestamp={true}
        ></MessageCard>
      );
    } else {
      return (
        <MessageCard
          key={message._id}
          message={message}
          isSender={isSender(message.senderId)}
          removeBottom={isRemove}
          showTimestamp={showTimestamp}
        ></MessageCard>
      );
    }
  });

  useEffect(() => {
    if (scrollToBottom) {
      let bottom = document.getElementById("bottomScroll");
      bottom.scrollIntoView();
      scrollToBottom = false;
    }
  });

  let messageBoxStyle = {
    bottom: toolbarH + "px",
    left: "256px",
    width: "calc(100%-512px)",
  };

  return (
    <div className={"fillHeight " + styles.ctn}>
      <TitleBar
        name={userData.isPt ? messagesObj.clientName : messagesObj.ptName}
        backButton={userData.isPt}
      ></TitleBar>
      {messagesList}
      <div className={styles.messageBoxBuffer}>
        <div style={{ bottom: toolbarH + "px" }} className={styles.messageBox}>
          <input
            id="messageInput"
            type="text"
            placeholder="Send message..."
            className={styles.inside}
            autoComplete="off"
            // onKeyDown={(event) => {
            //   EnterPressed(event);
            // }}
          ></input>
          <SendOutlined
            onClick={sendCallback}
            style={{ fontSize: "24px", color: "white" }}
          />
        </div>
      </div>
      <div id="bottomScroll"></div>
    </div>
  );
}

export default Messages;
