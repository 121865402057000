import React, { useEffect, useState } from "react";
import { Link, useOutletContext } from "react-router-dom";
import styles from "./clientSettings.module.css";
import TitleBar from "../../components/titleBar";
import useAuth from "../../scripts/useAuth.jsx";
import { generalApiCall } from "../../scripts/apiCalls";
import { Button } from "antd";
import FeedbackModal from "../../components/feedbackModal";

function ClientSettings(props) {
  const setToolbarIcon = useOutletContext();
  useEffect(() => {
    setToolbarIcon(props.tbIcon);
  }, []);

  const { logout } = useAuth();

  const [showFeedbackPopup, setShowFeedbackPopup] = useState(false);


  function toggleFeedbackPopup() {
    setShowFeedbackPopup(!showFeedbackPopup);
  }

  function submitFeedback(text) {
    console.log(text);
    generalApiCall("/submitFeedback", { feedback: text }).then((res) => {
      toggleFeedbackPopup();
    });
  }

  return (
    <div className={"fillHeight crosses " + styles.container}>
      <TitleBar name="Settings" hasBackButton="false"></TitleBar>
      <div style={{ height: "32px" }}></div>
      <Link className={styles.link} to="/client/settings/info">
        <Button size="large" type="primary" block >Personal Info</Button>
      </Link>
      <div style={{ height: "16px" }}></div>
      <Link className={styles.link} to="/client/settings/password">
        <Button size="large" type="primary" block >Change Password</Button>
      </Link>
      <div style={{ height: "16px" }}></div>
      <Button style={{ margin: "0px 7.5% 0px 7.5%", width: "85%" }} size="large" type="primary" block onClick={toggleFeedbackPopup}>Send Feedback</Button>

      <div style={{ height: "32px" }}></div>
      <Link className={styles.link} to="/">
        <Button size="large" danger block onClick={logout}>Logout</Button>
      </Link>
      <Link className={styles.link} to="/termsAndConditions">
        <p style={{ textAlign: "center" }}>
          T&C
        </p>
      </Link>
      <FeedbackModal
        open={showFeedbackPopup}
        onCancel={toggleFeedbackPopup}
        onCreate={submitFeedback}
      ></FeedbackModal>
    </div>
  );
}

export default ClientSettings;
