import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Popup from "../components/popup";
import styles from "./forgotPassword.module.css";
import { generalApiCall } from "../scripts/apiCalls";
import { Typography, Form, Input, Button, Modal } from "antd";
import useAuth, { setCssVars } from "../scripts/useAuth";

const { Title, Text } = Typography;

function ForgotPassword(props) {
  const navigate = useNavigate();
  const [recover, setRecover] = useState(false);
  const [code, setCode] = useState();
  const [showPopup, setShowPopup] = useState(false);
  const [popupText, setPopupText] = useState();
  const [popupButtons, setPopupButtons] = useState();
  const [popupUsername, setPopupUsername] = useState();
  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get("code")) {
      setRecover(true);

      setCode(query.get("code"));
      console.log("recovering");
    }
  }, []);

  function requestRecovery(e) {
    // e.preventDefault();
    // const username = e.target[0].value;
    const username = e;
    console.log(e.username);
    console.log("requested password");
    generalApiCall("/forgotPassword", { username: username.username }).then(
      (response) => {
        console.log("password request url");
        setShowPopup(true);
        setPopupText(
          "An email has been sent to " +
            username.username +
            " with a link to reset your password"
        );
        setPopupButtons([
          <Button
            onClick={() => {
              setShowPopup(false);
            }}
          >
            Close
          </Button>,
        ]);
      }
    );
  }

  function checkPasswords() {
    const passLabel = document.getElementById("passwordLb");
    const pass1 = document.getElementById("password1");
    const pass2 = document.getElementById("password2");

    if (pass1.value.length < 8) {
      passLabel.textContent = "Passwords is not at least 8 characters";
    } else if (pass1.value !== pass2.value) {
      passLabel.textContent = "Passwords are not the same";
    } else if (pass1.value === pass2.value) {
      passLabel.textContent = "Password is okay!";
      return true;
    } else {
      passLabel.textContent = "Error";
    }
    return false;
  }
  let popupUsernameTmp = "";
  function submitNewPassword(e) {
    e.preventDefault();
    const password0 = e.target[0].value;
    const password1 = e.target[1].value;

    let passwordOkay = checkPasswords();
    if (passwordOkay) {
      generalApiCall("/forgotPasswordNew", {
        code: code,
        password: password0,
      }).then((response) => {
        console.log(response);
        if (response.status === 401) {
          if (response.data[0] === "expired") {
            //code has expired, would you like to resend it?
            setShowPopup(true);
            setPopupUsername(response.data[1]);
            popupUsernameTmp = response.data[1];
            setPopupText(
              "Your code has expired, would you like to send another?"
            );
            setPopupButtons(
              <div>
                <Button onClick={closePopup}>Close</Button>
                <Button onClick={resendForgotPassword} type="primary">
                  Resend
                </Button>
              </div>
            );
          } else if (response.data[0] === "missing") {
            console.log("missing code should run?");
            //code is missing popup
            //go to forgot password page
            setShowPopup(true);
            setPopupText(
              "Your code could not be found, would you like to try again?"
            );
            setPopupButtons(
              <div>
                <Button onClick={closePopup}>Close</Button>
                <Button onClick={gotoForgotPassword} type="primary">
                  Try again
                </Button>
              </div>
            );
          }
        } else if (response.status === 200) {
          //password has been reset popup
          //nav to login
          setShowPopup(true);
          setPopupText("Your password has been reset, please login");
          setPopupButtons(
            <div>
              <Button onClick={closePopup}>Close</Button>
              <Button onClick={gotoLogin} type="primary">
                Go to login
              </Button>
            </div>
          );
        }
      });
    }
  }
  function closePopup() {
    setShowPopup(false);
  }

  function gotoLogin() {
    //nav to login
    navigate("/login", { replace: true });
  }

  function gotoForgotPassword() {
    //nav to forgotPasswordPage
    navigate("/forgotPassword", { replace: true });
    window.location.reload(true);
  }

  function resendForgotPassword() {
    console.log(popupUsername);
    //when assigning this function in the usestate for popup buttons
    //it takes a snapshot of resendforgotpassword
    //and in that snapshot popupusername is undefined
    //popupUsernameTmp is removed every rerender but holds the value
    //for the snapshot
    console.log(popupUsernameTmp);
    generalApiCall("/forgotPassword", { username: popupUsernameTmp }).then(
      (response) => {
        setPopupText(
          "An email has been sent to " +
            popupUsernameTmp +
            " with a link to reset your password"
        );
        setPopupButtons([{ text: "close", clicked: closePopup }]);
      }
    );
  }

  if (!recover) {
    return (
      <div className={"fillHeight " + styles.pageCtn}>
        <div className={styles.ctn}>
          {/* <Title level={3}>Forgot Password</Title> */}
          <Form
            className="loginForm horizontalCenter"
            onFinish={requestRecovery}
            style={{ height: "100%" }}
          >
            <div style={{ marginTop: 8 }}></div>
            <div className={styles.labelCtn}>
              <Text type="secondary">Your e-mail</Text>
            </div>
            <Form.Item name="username">
              <Input size="large" type="text" placeholder="Email.." />
            </Form.Item>
            <div style={{ height: "16px" }}></div>
            <Form.Item>
              <Button
                style={{ marginTop: "auto" }}
                block
                type="primary"
                htmlType="submit"
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>

        <Modal open={showPopup} footer={popupButtons}>
          <p>{popupText}</p>
        </Modal>
      </div>
    );
  } else {
    return (
      <div className="fillHeight">
        <div className={styles.forgotCtn}>
          <div className={styles.forgotPageCtn}>
            <Title level={3}>Forgot Password</Title>
            <form className="loginForm" onSubmit={submitNewPassword}>
              <Text type="secondary">New Password</Text>
              <div style={{ height: "6px" }}></div>
              <Input
                id="password1"
                size="large"
                type="text"
                placeholder="Password.."
                onChange={checkPasswords}
              />
              <div style={{ height: "6px" }}></div>
              <Input
                id="password2"
                size="large"
                type="text"
                placeholder="Confirm password.."
                onChange={checkPasswords}
              />
              <div style={{ height: "12px" }}></div>
              <Button block id="passwordLb" type="primary" htmlType="submit">
                Confirm
              </Button>
            </form>
          </div>
          <Modal
            open={showPopup}
            // onOk={() => {
            //   setShowPopup(false);
            // }}
            // onCancel={() => {
            //   setShowPopup(false);
            // }}
            footer={popupButtons}
          >
            <p>{popupText}</p>
          </Modal>
        </div>
      </div>
    );
  }
}

export default ForgotPassword;
