import React from "react";
import ReactDOM from "react-dom/client";
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  Navigate,
} from "react-router-dom";
import useAuth, { AuthProvider} from "./scripts/useAuth";

import "./index.css";
import App from "./App";
import Client from "./Client.js";
import Login from "./routes/login.jsx";


import ClientFilesAll from "./routes/client/clientFilesAll";
import ClientFile from "./routes/client/clientFile";
import ClientHome from "./routes/client/clientHome";
import ClientSettings from "./routes/client/clientSettings";
import ClientSettingsInfo from "./routes/client/clientSettingsInfo";
import ClientSettingsInfoEdit from "./routes/client/clientSettingsInfoEdit";
import ClientPackageList from "./routes/client/clientPackageList";

import Pt from "./Pt.js";
import PtClientList from "./routes/pt/ptClientList";
import PtClientProfile from "./routes/pt/ptClientProfile";
import PtLibraryFile from "./routes/pt/ptLibraryFile";
import PtSettings from "./routes/pt/ptSettings";

import Messages from "./routes/shared/messages";
import Notifications from "./routes/shared/notifications";
import SettingsPassword from "./routes/shared/SettingsPassword";
import BookingMaster from "./routes/shared/bookingMaster";

import RegisterPage from "./routes/registerPage";
import VerifyEmailRedirect from "./routes/verifyEmailRedirect";

import BookingMade from "./routes/bookingMade";
import PackagePaid from "./routes/packagePaid";
import DevTools from "./routes/devTools";
import PtSettingsBooking from "./routes/pt/ptSettingsBooking";
import PtSettingsTheme from "./routes/pt/ptSettingsTheme";
import PtSettingsAccess from "./routes/pt/ptSettingsAccess";
import PtPackageCreator from "./routes/pt/ptPackageCreator";
import PtPackagesList2 from "./routes/pt/ptPackagesList2";
import PtPackageViewer from "./routes/pt/ptPackageViewer";
import ExternalPackageLogin from "./routes/externalPackageLogin";
import ExternalPackageViewer from "./routes/externalPackageViewer";
import ExternalPackageFile from "./routes/externalPackageFile";
import ForgotPassword from "./routes/forgotPassword";
import BuyPackageRedirect from "./routes/buyPackageRedirect";
import TermsAndCondiditions from "./routes/termsAndConditons";
import EndUserLicenseAgreement from "./routes/endUserLicenseAgreement";
import PtSettingsSubscriptions from "./routes/pt/ptSettingsSubscriptions";
import ClientSubscriptionsList from "./routes/client/clientSubscriptionsList";
import ClientSubscription from "./routes/client/clientSubscription";
import PtSettingsSubscription from "./routes/pt/ptSettingsSubscription";


const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route path="/" element={<App />}>
            <Route index element={<Login  />} />
            <Route path="login" element={<Login  />} />
            <Route path="forgotPassword" element={<ForgotPassword  />} />
            <Route path="register" element={<RegisterPage />} />
            <Route path="dev" element={<DevTools />} />
            <Route path="bookingCallback" element={<BookingMade />} />
            <Route path="packagePaid" element={<PackagePaid />} />
            <Route path="packages" element={<ExternalPackageLogin  />} />
            <Route path="packages/:packageId/:accessCode" element={<ExternalPackageViewer />} />
            <Route path="packages/:packageId/:accessCode/:fileId" element={<ExternalPackageFile />} />
            <Route path="buypackageredirect/:packageId" element={<BuyPackageRedirect />} />
            <Route path="termsAndConditions" element={<TermsAndCondiditions />} />
            <Route path="endUserLicenseAgreement" element={<EndUserLicenseAgreement />} />
            <Route path="verifyEmail" element={<VerifyEmailRedirect />} />
          </Route>

          <Route path="/client" element={
            <RequireAuth>
              <Client />
            </RequireAuth>
          }>
            <Route index element={<ClientHome tbIcon="Profile" />} />
            <Route path="subscriptions" element={<ClientSubscriptionsList tbIcon="Profile" />} />
            <Route path="subscriptions/:subscriptionId" element={<ClientSubscription tbIcon="Profile" />} />
            <Route path="files" element={<ClientFilesAll tbIcon="Files" />} />
            <Route path="files/:fileInstId" element={<ClientFile tbIcon="Files" />} />
            <Route path="messages" element={<Messages tbIcon="Messages" />} />
            <Route path="notifications" element={<Notifications tbIcon="Notifications" />} />
            <Route path="settings" element={<ClientSettings tbIcon="Settings" />} />
            <Route path="settings/info" element={<ClientSettingsInfo tbIcon="Settings" />} />
            <Route path="settings/info/edit" element={<ClientSettingsInfoEdit tbIcon="Settings" />} />
            <Route path="settings/password" element={<SettingsPassword tbIcon="Settings" />} />
            <Route path="booking" element={<BookingMaster tbIcon="Profile" />} />
            <Route path="packages" element={<ClientPackageList tbIcon="Profile" />} />
            <Route path="packages/:packageId" element={<PtPackageViewer tbIcon="Profile" />} />
          </Route>
          <Route path="/pt" element={
            <RequireAuth>
              <Pt />
            </RequireAuth>
          }>
            <Route index element={<PtClientList tbIcon="Profile" />} />
            <Route path="client/:clientId/profile" element={<PtClientProfile tbIcon="Profile" />} />
            <Route path="client/:clientId/messages" element={<Messages tbIcon="Profile" />} />
            <Route path="client/:clientId/files" element={<ClientFilesAll tbIcon="Profile" />} />
            <Route path="client/:clientId/files/:fileInstId" element={<ClientFile tbIcon="Profile" />} /> {/* <PtClientFile /> */}
            <Route path="client/:clientId/settings" element={<ClientSettingsInfo tbIcon="Profile" />} />
            <Route path="library" element={<ClientFilesAll tbIcon="Files" />} />
            <Route path="files/:fileInstId" element={<PtLibraryFile tbIcon="Files" />} />
            <Route path="notifications" element={<Notifications tbIcon="Notifications" />} />
            <Route path="settings" element={<PtSettings tbIcon="Settings" />} />
            {/* <Route path="settings/info" element={<PtSettingsInfo tbIcon="Settings" />} /> */}
            <Route path="settings/info" element={<ClientSettingsInfo tbIcon="Settings" />} />
            <Route path="settings/info/edit" element={<ClientSettingsInfoEdit tbIcon="Settings" />} />
            <Route path="settings/booking" element={<PtSettingsBooking tbIcon="Settings" />} />
            <Route path="settings/subscriptions" element={<PtSettingsSubscriptions tbIcon="Settings" />} />
            <Route path="settings/subscriptions/:subscriptionId" element={<PtSettingsSubscription tbIcon="Settings" />} />
            <Route path="settings/theme" element={<PtSettingsTheme tbIcon="Settings" />} />
            <Route path="settings/access" element={<PtSettingsAccess tbIcon="Settings" />} />
            <Route path="settings/password" element={<SettingsPassword tbIcon="Settings" />} />

            {/* <Route path="settings/packages" element={<PtPackageList tbIcon="Settings" />} /> */}
            <Route path="settings/packages" element={<PtPackagesList2 tbIcon="Settings" />} />
            <Route path="settings/packages/creator" element={<PtPackageCreator tbIcon="Settings" />} />
            <Route path="settings/packages/:packageId" element={<PtPackageViewer tbIcon="Settings" />} />
            <Route path="settings/packages/:packageId/edit" element={<PtPackageCreator tbIcon="Settings" />} />

            <Route path="booking" element={<BookingMaster tbIcon="Booking" />} />

          </Route>
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>
);

export function RequireAuth({ children }) {
  const { authed, initLoggedIn } = useAuth();
  const location = useLocation();
  // const navigate = useNavigate();
  if (authed === undefined) {
    initLoggedIn().then((res) => {
      console.log(res)
      if (res === true) {
        return children
      }
      else {
        return <Navigate to="/login" replace state={{ path: location.pathname }} />
      }

    })
  }

  else {
    return authed ? (
      children
    ) : (
      <Navigate to="/login" replace state={{ path: location.pathname }} />
    );

  }
}