import { useState, useEffect } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import TitleBar from "../../components/titleBar";
import styles from "./ptSettingsBooking.module.css";
import sysButtons from "../../styles/sysButtons.module.css";
import { generalApiCall } from "../../scripts/apiCalls";

function PtSettingsSubscription(props) {
  const setToolbarIcon = useOutletContext();
  useEffect(() => {
    setToolbarIcon(props.tbIcon);
  }, []);

  let { subscriptionId } = useParams();
  const navigate = useNavigate();
  const [subscriptionData, setSubscriptionData] = useState();
  const [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    if (subscriptionId !== "new") {
      generalApiCall("/getSubscription", { _id: subscriptionId }).then(
        (res) => {
          console.log(res);
          setSubscriptionData(res.data);
          setDataLoaded(true);
        }
      );
    } else {
      setSubscriptionData({
        name: "Template Subscription",
        description: "Fill out the description of your subscription",
        price: 999,
        available: true,
        userIds: [],
        _id: "new",
      });
      setDataLoaded(true);
    }
  }, []);

  function submitSubscription() {
    console.log(subscriptionData);
    let tmpSub = subscriptionData;
    tmpSub.price *= 100
    generalApiCall("/setSubscription", {
      subscription: tmpSub,
      _id: subscriptionId,
      new: (subscriptionId==="new")
    }).then((res) => {
      //nav
      navigate(-1, { replace: false });
    });
  }


  return (
    <div className={"fillHeight crosses " + styles.pageCtn}>
      <TitleBar name="Subscription Settings" backButton={true}></TitleBar>
      {!dataLoaded && <div>loading...</div>}
      {dataLoaded && (
        <div className={styles.ctn}>
          <span>{JSON.stringify(subscriptionData)}</span>
          <div>number of clients: {subscriptionData.userIds.length}</div>
          {subscriptionData.userIds.map((clientId) => {
            return <div>{clientId}</div>;
          })}
          <button
            className={sysButtons.buttonSolid + " " + sysButtons.button}
            onClick={submitSubscription}
          >
            Save
          </button>
        </div>
      )}
    </div>
  );
}


export default PtSettingsSubscription;
