import React, { useEffect } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import styles from "../pt/ptPackageList.module.css";
import TitleBar from "../../components/titleBar";
import { useState } from "react";
import { generalApiCall } from "../../scripts/apiCalls";
import { List, Input } from "antd";

function PtPackageList(props) {
  const setToolbarIcon = useOutletContext();
  useEffect(() => {
    setToolbarIcon(props.tbIcon);
  }, []);

  const navigate = useNavigate();
  const [packagesData, setPackagesData] = useState();
  const [packagesLoaded, setPackagesLoaded] = useState(false);

  useEffect(() => {
    generalApiCall("/getPackageList", {}).then((res) => {
      setPackagesData(res.data.filter((el)=>{
        return el.saleable || el.paid
      }));
      setPackagesLoaded(true);
      console.log(res.data);
    });
  }, []);

  // function packageClick(paid, packageId) {
  //   if (paid) {
  //     navigate("/client/packages/" + packageId, { replace: false });
  //   } else {
  //     generalApiCall("/buyPackage", { packageId: packageId }).then((res) => {
  //       // navigate(res.url, { replace: false });
  //       window.location.href = res.data.url;
  //     });
  //   }
  // }

  function linkClicked(packageId) {
    navigate("/client/packages/" + packageId, { replace: false });
  }

  return (
    <div className={"fillHeight crosses " + styles.pageCtn}>
      <TitleBar name="Packages" backButton="true"></TitleBar>
      <div className={styles.searchBar}>
        <Input placeholder="Search.." />
      </div>
      <div className={styles.bodyCtn}>
        <div style={{ marginTop: 12 }}></div>
        {packagesLoaded && (
          <List
            bordered
            style={{ backgroundColor: "#fff" }}
            itemLayout="horizontal"
            dataSource={packagesData}
            size="small"
            renderItem={(item, index) => (
              <List.Item onClick={() => linkClicked(item._id)}>
                <List.Item.Meta
                  title={item.name}
                  description={
                    <div
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {item.description}
                    </div>
                  }
                />

                <h3 style={{ margin: "0 0 auto 0" }}>
                  {!item.paid ? "$" + item.price / 100 : "Own"}
                </h3>
              </List.Item>
            )}
          />
        )}
      </div>
    </div>
  );
}

export default PtPackageList;
