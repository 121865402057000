import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import {
  useNavigate,
  Link,
  useParams,
  useOutletContext,
} from "react-router-dom";
import styles from "./ptClientProfile.module.css";
import { Button, Card, Input } from "antd";
import SettingsIcon from "../../components/icons/settingsIcon";
import TitleBar from "../../components/titleBar";
import {
  generalApiCall,
  GetClientInfo,
  GetNote,
  UpdateNote,
} from "../../scripts/apiCalls.jsx";

function ClientProfile(props) {
  const setToolbarIcon = useOutletContext();
  useEffect(() => {
    setToolbarIcon(props.tbIcon);
  }, []);
  const clientInfoTmp = {
    firstName: "Loading..",
    lastName: "",
    gender: "Loading..",
    age: "",
    mobileNumber: "Loading..",
    username: "Loading..",
  };

  let { TextArea } = Input;
  let { clientId } = useParams();
  const [clientInfo, setClientInfo] = useState(clientInfoTmp);
  const [isClientLoaded, setIsClientLoaded] = useState(false);
  const [subscriptionName, setSubscriptionName] = useState("");
  const [note, setNote] = useState("loading..");
  const [noteTmp, setNoteTmp] = useState("loading..");

  useEffect(() => {
    GetClientInfo(clientId).then((result) => {
      console.log(result);
      setClientInfo(result);
      setIsClientLoaded(true);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    generalApiCall("/getNote", { clientId: clientId }).then((result) => {
      setNote(result.data);
      setNoteTmp(result.data);
    });
    generalApiCall("/getCurrentSubscriptionName", { userId: clientId }).then(
      (result) => {
        setSubscriptionName(result.data);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let navigate = useNavigate();

  function gotoClientInfo() {
    navigate("/pt/client/" + clientId + "/settings", { replace: false });
  }

  function uploadButton() {
    const newNote = document.getElementById("clientNote").value;

    generalApiCall("/updateNote", { clientId: clientId, note: newNote });
  }

  function dobToAge() {
    const timeDiff = new Date(new Date() - new Date(clientInfo.dob));
    const age = timeDiff.getUTCFullYear() - 1970; //time counts from 1970
    return age;
  }

  return (
    <div className={"fillHeight  crosses " + styles.background}>
      <TitleBar name="Profile" backButton={true}></TitleBar>
      <div className={styles.pageCtn}>
        <div style={{ margin: 24 }}></div>
        <Card size="small">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div
              style={{
                display: "flex",
                flex: "1",
                alignItems: "flex-start",
                maxWidth: "100%",
              }}
            >
              <img
                style={{
                  width: 75,
                  height: 75,
                  objectFit: "cover",
                  borderRadius: 8,
                  marginInlineEnd: 16,
                }}
                alt="profile"
                src={"/getProfilePhoto/" + clientId}
              />
              <div style={{ flex: "1 0", width: 0 }}>
                <h4
                  style={{ margin: 0, fontSize: "1.2rem" }}
                >{`${clientInfo.firstName} ${clientInfo.lastName}`}</h4>
                <div
                  style={{ fontSize: 12, color: "rgba(0, 0, 0, 0.45)" }}
                >{`${clientInfo.gender}, ${dobToAge()}`}</div>
                <div
                  style={{ fontSize: 12, color: "rgba(0, 0, 0, 0.45)" }}
                >{`${subscriptionName}`}</div>
              </div>
            </div>
            <div
              role="link"
              onClick={(e) => {
                e.preventDefault();
                // toMessages(item._id);
              }}
            >
              <div onClick={gotoClientInfo}>
                <SettingsIcon class={styles.settingIcon} />
              </div>
            </div>
          </div>
        </Card>
        <div style={{ margin: 24 }}></div>
        <Link to={"/pt/client/" + clientId + "/messages"}>
          <Button type="primary" size="large" block>
            Messages
          </Button>
        </Link>
        <div style={{ margin: 16 }}></div>
        <Link to={"/pt/client/" + clientId + "/files"}>
          <Button type="primary" size="large" block>
            Files
          </Button>
        </Link>
        <div style={{ margin: 24 }}></div>
        {note !== "loading.." && (
          <>
            <TextArea id="clientNote" rows={4} defaultValue={note} onChange={(e)=>{setNoteTmp(e.target.value)}}></TextArea>
            {noteTmp !== note &&
              <Button onClick={uploadButton} block>
                Save Note
              </Button>
            }
          </>
        )}
        <div className={styles.spacer}></div>
      </div>
    </div>
  );
}
export default ClientProfile;
