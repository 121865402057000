import React, { useEffect, useState } from "react";
import styles from "./bookingPopup.module.css";
import BackwardIcon from "./icons/backwardIcon";
import ForwardIcon from "./icons/forwardIcon";
import { daySlots } from "../scripts/booking";
import { generalApiCall } from "../scripts/apiCalls";
import { Modal, Button } from "antd";

function BookingPopup(props) {

  const [slotLength, setSlotLength] = useState(props.bookingLengths[0].length);
  const [availableDaySlots, setAvailableDaySlots] = useState();
  const [daySelected, setDaySelected] = useState(props.initalDay);
  const [slotSelected, setSlotSelected] = useState();

  useEffect(() => {
    setAvailableDaySlots(daySlots(props.weekData[daySelected], slotLength));
  }, [slotLength, daySelected]);

  const dayNames = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  function editSlotLength(time) {
    setSlotLength(time);
  }

  function editDaySelected(alt) {
    const newDay = (daySelected + alt + 7) % 7;
    setDaySelected(newDay);
  }

  function UpdateSlotLength(length) {
    editSlotLength(length);
    setSlotSelected(undefined);
  }

  function LengthCard() {
    return (
      <div className={styles.buttons}>
        {props.bookingLengths.map((item) => (
          <Button onClick={() => UpdateSlotLength(item.length)}
            type={slotLength === item.length ? "primary" : "default"}
          >{item.length} Minutes</Button>
        ))}
      </div>
    );
  }

  function ChangeSelected(event) {
    setSlotSelected(availableDaySlots[event]);
  }


  function SubmitBooking() {
    const startDate = new Date();
    const dayOfWeek = startDate.getDay();
    const daysToAdd = 7 * props.addedWeeks + daySelected - dayOfWeek;
    startDate.setDate(startDate.getDate() + daysToAdd);
    const startTimestamp = new Date(
      startDate.getFullYear(),
      startDate.getMonth(),
      startDate.getDate(),
      slotSelected.startHour,
      slotSelected.startMin
    );

    const endTimestamp = new Date(
      startDate.getFullYear(),
      startDate.getMonth(),
      startDate.getDate(),
      slotSelected.endHour,
      slotSelected.endMin
    );

    console.log({
      start: startTimestamp,
      end: endTimestamp,
    })
    generalApiCall("/api/makeBooking", {
      start: startTimestamp,
      end: endTimestamp,
    }).then((res) => {
      if (res.data.available) {
        window.location.href = res.data.url;
      } else {
        // Booking slot was not available. The PT had filled in time
        // or someone has booked in already since the page was loaded
      }
    });
  }

  function formatTime(hour, minute) {
    const hour12 = hour > 12 ? hour - 12 : hour === 0 ? 12 : hour;
    const period = hour >= 12 ? 'pm' : 'am';
    const minuteStr = minute !== 0 ? minute : '00';
    return `${hour12}:${minuteStr} ${period}`;
  }

  return (
    <Modal
      centered
      open={props.open}
      // title="Edit File"
      okText="Book"
      cancelText="Cancel"
      onCancel={props.close}
      onOk={props.close}
      // onOk={SubmitBooking}
      okButtonProps={{disabled: slotSelected? false:true}}
    >
      <div className={styles.date}>
        <div onClick={() => editDaySelected(-1)}>
          <BackwardIcon class={styles.icon} />
        </div>
        <span>{dayNames[daySelected]}</span>
        <div onClick={() => editDaySelected(1)}>
          <ForwardIcon class={styles.icon} />
        </div>
      </div>
      <p className={styles.bookingTitle}>Length of Booking</p>
      <LengthCard />
      <p className={styles.bookingTitle}>Select Time Slot</p>
      <div className={styles.slotsCtn}>
        {availableDaySlots?.map((slot, index) => {
          const startSlot = formatTime(slot.startHour, slot.startMin);
          const endSlot = formatTime(slot.endHour, slot.endMin);
          return (
            <>
              <Button onClick={() => ChangeSelected(index)}
                id={"slot" + index}
                key={"slot" + index}
                block
                type={slotSelected === slot ? "primary" : "default"}
                // size="large"
              >
                {startSlot} - {endSlot}
              </Button>
              <div style={{ marginBottom: 4 }}></div>
            </>
          );
        })}
      </div>
      <div style={{ marginBottom: 24 }}></div>
      {/* <Button block type="primary" onClick={SubmitBooking}>
        Book
      </Button> */}
    </Modal>
  );
}

export default BookingPopup;