import React from "react";
import styles from "./register.module.css";
import sysInputs from "../styles/sysInputs.module.css";
import sysButtons from "../styles/sysButtons.module.css";
import TitleBar from "../components/titleBar";
import useAuth from "../scripts/useAuth";

function DevTools() {
  const { register } = useAuth();


  const firstNames = [
    "Sam",
    "Kory",
    "Billie",
    "Jessie",
    "Keagan",
    "Jo",
    "Petra",
    "Asra",
    "Embry",
    "Kace",
    "Hayden",
    "Ash",
    "Alex",
    "Jay",
    "Flynn",
    "August",
    "Riley",
    "Ace",
    "Taylor",
    "Micah",
    "Nico",
  ];
  const lastNames = [
    "Ray",
    "Edwards",
    "Phillips",
    "Holland",
    "Chandler",
    "Burke",
    "Walker",
    "Carr",
    "Campbell",
    "Dunn",
    "Mcdonald",
    "Thompson",
    "Davidson",
    "Burns",
    "Robinson",
    "Kirby",
    "Mccarthy",
    "Crawford",
    "O'Brien",
    "Hunt",
    "Holmes",
  ];
  const genders = ["Male", "Female", "Other"];
  const streets = [
    "Chestnut",
    "Grove",
    "Hawthorne",
    "Briarwood",
    "Oak",
    "Cooper",
    "Carriage",
    "Summit",
    "Homestead",
    "Sycamore",
    "Poplar",
    "Edgewood",
    "Glenwood",
    "Andover",
  ];
  const streetsExt = ["Street", "Place", "Lane", "Avenue", "Drive"];
  const towns = ["Christchurch", "Christchurch","Christchurch","Christchurch","Rangiora", "Kiapoi", "Ashburton"];
  function getRandom(list) {
    return list[Math.floor(Math.random() * list.length)];
  }
  function randomPh() {
    return "02" + Math.floor(Math.random() * 10000000);
  }
  function randomDate() {
    let start = new Date(1970, 0, 1);
    let end = new Date(2004, 0, 1);
    return new Date(
      start.getTime() + Math.random() * (end.getTime() - start.getTime())
    );
  }
  function randomStreet() {
    let nb = Math.floor(Math.random() * 100);
    let st = getRandom(streets);
    let ex = getRandom(streetsExt);
    return nb + " "  + st + " " + ex;
  }

  function buttonStyle() {
    return sysButtons.button + " " + sysButtons.buttonSolid;
  }

  function handleSubmit(e) {
    e.preventDefault();
    let user = {
      ptId: e.target["ptId"].value.trim(),
      accessCode: e.target["accessCode"].value.trim(),
      username: e.target["email"].value.trim(),
      firstName: getRandom(firstNames),
      lastName: getRandom(lastNames),
      mobile: randomPh(),
      dob: randomDate(),
      gender: getRandom(genders),
      address: {
        street: randomStreet(),
        town: getRandom(towns),
        country: "New Zealand",
      },
      password: "password",
    };

    register(user).then((response) => {});

    console.log(user);
  }

  return (
    <div className={"fillHeight " + styles.cnt}>
      <TitleBar name="Register" />
      <div>
        <form id="registerForm" className={styles.form} onSubmit={handleSubmit}>
          <div className={styles.headerTxt}>
            <p>Create an Account</p>
          </div>
          <div className={styles.middleBox}>
            <label className={styles.label}>Email</label>
            <input
              className={sysInputs.textInput + " " + styles.margin}
              type="text"
              id="email"
              placeholder="username"
            />
            <label className={styles.label}>PT ID (tmp)</label>
            <input
              className={sysInputs.textInput + " " + styles.margin}
              type="text"
              id="ptId"
              placeholder="_id"
            />
            <label className={styles.label}>Access Code</label>
            <input
              className={sysInputs.textInput + " " + styles.margin}
              type="text"
              id="accessCode"
              placeholder="Does nothing yet"
            />
          </div>
          <div className={styles.button}>
            <button className={buttonStyle()} type="submit">
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default DevTools;
