import React from "react";
import styles from "./titleBar.module.css";
import { useNavigate } from "react-router-dom";
import BackIcon from "./icons/backIcon";

function TitleBar(props) {
  const navigate = useNavigate();

  function handleBack() {
    // console.log(window.history.state)
    // console.log(window.history.state.idx)
    // if (window.history.state && window.history.state.idx > 0) {
    navigate(-1, { replace: props.replace ?? false });
    // } else {
    //   navigate("/", { replace: props.replace ?? false });
    // }
  }

  return (
    
    <div className={styles.title}>
      {props.backButton && (
        <div className={styles.backButtonCtn} onClick={handleBack}>
          {" "}
          <BackIcon className={styles.backButton} />{" "}
        </div>
      )}
      <p className={styles.titleText}>{props.name}</p>
      {props.backButton && <div class={styles.hiddenDiv}> </div>}
    </div>
  );
}

export default TitleBar;
