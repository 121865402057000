import React, { useEffect, useState } from "react";
import { useOutletContext, Link, useParams } from "react-router-dom";
import TitleBar from "../../components/titleBar";
import styles from "./ptPackageViewer.module.css";
import { generalApiCall } from "../../scripts/apiCalls";
import useAuth from "../../scripts/useAuth.jsx";
import { Card, Button } from "antd";
import FileTable from "../../components/fileTable";

//PT and Clients view this
function PtPackageViewer(props) {
  const setToolbarIcon = useOutletContext();
  useEffect(() => {
    setToolbarIcon(props.tbIcon);
  }, []);
  const { userData } = useAuth();
  let { packageId } = useParams();
  const [packageData, setPackageData] = useState({ name: "Loading.." });
  const [packageLoaded, setPackageLoaded] = useState(false);

  useEffect(() => {
    generalApiCall("/getPackageInfo", { id: packageId }).then((res) => {
      console.log(res.data)
      setPackageData(res.data);
      setPackageLoaded(true);
    });
  }, []);

  const tabListNoTitle = [
    {
      key: "package",
      tab: "Info",
    },
    {
      key: "external",
      tab: "Links",
    },
    {
      key: "admin",
      tab: "Tracking",
    },
  ];
  const contentListNoTitle = {
    package: (
      <div>
        <p>
          <span style={{ fontWeight: "500" }}>Description:</span>
          <br />
          {packageData.description}
        </p>
        <p>
          <span style={{ fontWeight: "500" }}>Price:</span>
          <br />${packageData.price/100}
        </p>
      </div>
    ),
    external: (
      <div>
        <p>
          <span style={{ fontWeight: "500" }}>Purchase Link:</span>
          <br />
          http://stripe.com/payment?1234&name?wownamfh&hi?5
        </p>
        <div style={{ marginTop: 16 }} />
        <p>
          <span style={{ fontWeight: "500" }}>Access Code:</span>
          <br />
          {packageLoaded && packageData.accessCode}
        </p>
      </div>
    ),
    admin: (
      <div>
        <p>
          <span style={{ fontWeight: "500" }}>Available:</span>
          <br />
          {packageLoaded &&
            userData.isPt &&
            capitaliseFirst(packageData.saleable.toString())}
        </p>
        <p>
          <span style={{ fontWeight: "500" }}>Units Sold:</span>
          <br />
          {packageLoaded && packageData.unitsSold}
        </p>
        <p>
          <span style={{ fontWeight: "500" }}>Revenue:</span>
          <br />
          {packageLoaded && packageData.unitsSold}
        </p>
      </div>
    ),
  };

  function capitaliseFirst(word) {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }

  const [activeTabKey1, setActiveTabKey1] = useState("tab1");
  const [activeTabKey2, setActiveTabKey2] = useState("package");
  const onTab1Change = (key) => {
    setActiveTabKey1(key);
  };
  const onTab2Change = (key) => {
    setActiveTabKey2(key);
  };

  return (
    <div className={"fillHeight crosses " + styles.pageCtn}>
      <TitleBar name={packageData.name} backButton="true"></TitleBar>
      <div className={styles.ctn}>
        <div style={{ marginTop: 16 }} />
        {packageLoaded && (
          <FileTable
            files={packageData.filesInst}
            disabled={!packageData.paid && !userData.isPt}
            showDate={false}
            url={userData.isPt ? "/pt/files/" : "/client/files/"}
          />
        )}
        {!userData.isPt && (
          <>
            <div style={{ marginTop: 16 }} />
            <Card title="Description" size="small">
              <p>{packageData.description}</p>{" "}
            </Card>
            <div style={{ marginBottom: 16 }} />
          </>
        )}
        {packageLoaded && !packageData.paid && !userData.isPt && (
          <>
            <Button block type="primary">
              Purchase
            </Button>
            <div style={{ marginBottom: 16 }} />
          </>
        )}
        {userData.isPt && (
          <>
            <Card
              style={{
                width: "100%",
              }}
              size="small"
              tabList={tabListNoTitle}
              tabProps={{ size: "small" }}
              activeTabKey={activeTabKey2}
              onTabChange={onTab2Change}
            >
              {contentListNoTitle[activeTabKey2]}
            </Card>
            <div style={{ marginTop: 16 }} />
            <Link to={"/pt/settings/packages/" + packageId + "/edit"}>
              <Button block type="primary" size="large">
                Edit
              </Button>
            </Link>
            <div style={{ marginTop: 16 }} />
          </>
        )}
      </div>
    </div>
  );
}

export default PtPackageViewer;
