import React from 'react';

const LoadingScreen = () => {
  return (
    <div
      style={{
        display: 'flex',
        flex: '1',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh', // Full viewport height
        width:'100%',
        background: '#fff', // White background
        position: 'relative'
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '80px', // Adjust the size as needed
        }}
      >
        <div
          style={{
            width: '16px', // Adjust the size as needed
            height: '16px', // Adjust the size as needed
            borderRadius: '50%', // Circular shape
            background: '#0078d4', // Dot color (blue in this example)
            animation: 'bounce 1s infinite', // CSS animation
          }}
        ></div>
        <div
          style={{
            width: '16px', // Adjust the size as needed
            height: '16px', // Adjust the size as needed
            borderRadius: '50%', // Circular shape
            background: '#0078d4', // Dot color (blue in this example)
            animation: 'bounce 1s infinite', // CSS animation
            animationDelay: '0.25s', // Delay for the second dot
          }}
        ></div>
        <div
          style={{
            width: '16px', // Adjust the size as needed
            height: '16px', // Adjust the size as needed
            borderRadius: '50%', // Circular shape
            background: '#0078d4', // Dot color (blue in this example)
            animation: 'bounce 1s infinite', // CSS animation
            animationDelay: '0.5s', // Delay for the third dot
          }}
        ></div>
        <div
          style={{
            width: '16px', // Adjust the size as needed
            height: '16px', // Adjust the size as needed
            borderRadius: '50%', // Circular shape
            background: '#0078d4', // Dot color (blue in this example)
            animation: 'bounce 1s infinite', // CSS animation
            animationDelay: '0.75s', // Delay for the fourth dot
          }}
        ></div>
      </div>
      <p
        style={{
          marginTop: '20px', // Space between dots and text
          fontSize: '16px', // Adjust the font size as needed
          fontWeight: 'bold', // Adjust the font weight as needed
          color: '#333', // Text color
        }}
      >
        Loading...
      </p>

      {/* CSS animation keyframes */}
      <style>
        {`
          @keyframes bounce {
            0%, 100% { transform: translateY(0); }
            50% { transform: translateY(-16px); }
          }
        `}
      </style>
    </div>
  );
};

export default LoadingScreen;