import { Button, Space, Table, Input, Checkbox } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useState, useRef, useEffect } from "react";

//files added are file instances not file ids
function FileTable(props) {
  console.log(props);
  const [files, setFiles] = useState(
    []
    // props.files.map((file, ind) => {
    //   let newFile = file
    //   newFile.key = file._id
    //   return newFile
    // })
  );
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [selectedRowKeysState, setSelectedRowKeysState] = useState(
    []
    // props.filesAdded.map((file, ind) => {
    //   return file
    // })
  );

  useEffect(() => {
    console.log(props.files);
    let fileKeys = props.files.map((file, ind) => {
      let newFile = file;
      newFile.key = file._id;
      return newFile;
    });
    setFiles(fileKeys);
    if (props.filesAdded !== undefined) {
      let addedFilesKeys = props.filesAdded.map((file, ind) => {
        return file;
      });
      console.log(addedFilesKeys);
      setSelectedRowKeysState(addedFilesKeys);
    }
  }, [props.files]);
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  function onCheckboxChange(e, r) {
    let copy = [...selectedRowKeysState];
    let indTmp = copy.indexOf(r._id);
    if (indTmp === -1) {
      copy = [...copy, r._id];
      // setSelectedRowKeysState([...copy, r._id]);
    } else {
      copy.splice(indTmp, 1);
    }
    setSelectedRowKeysState(copy);
    if (props.showSelect) {
      props.setFilesAdded(copy);
    }
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => (searchedColumn === dataIndex ? text : text),
  });

  const baseColumns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "50%",
      ...getColumnSearchProps("name"),
      ellipsis: true,
      render: (text, record, index) => {
        return props.disabled ? (
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {text}
          </div>
        ) : (
          // Only works if select is only used on package editor/creator. instId doesnt exist otherwise
          <a
            target={props.showSelect ? "_blank" : undefined}
            rel="noopener noreferrer"
            href={props.url + (props.showSelect ? record.instId : record._id)}
          >
            {text}
          </a>
        ); //pt string/client string
      },
    },
    {
      title: "Label",
      dataIndex: "label",
      key: "label",
      // ellipsis:true,
      // width: '30%',
      // ...getColumnSearchProps('age'),
      filters: [
        {
          text: "Nutrition",
          value: "Nutrition",
        },
        {
          text: "Workout",
          value: "Workout",
        },
        {
          text: "Corrective",
          value: "Corrective",
        },
        {
          text: "Mobility",
          value: "Mobility",
        },
        {
          text: "Other",
          value: "Other",
        },
        {
          text: "Client Upload",
          value: "Client Upload",
        },
      ],
      onFilter: (value, record) => record.label.indexOf(value) === 0,
    },
  ];

  const dateColumn = {
    title: "Date",
    dataIndex: "date",
    key: "date",
    // width: '20%',
    // ...getColumnSearchProps('address'),
    sorter: (a, b) => new Date(a.date) > new Date(b.date),
    sortDirections: ["descend", "ascend"],
    render: (text) => {
      let a = new Date(text).toLocaleDateString("en-NZ", {
        year: "2-digit",
        month: "numeric",
        day: "2-digit",
      });
      return a;
    },
  };
  const selectColumn = {
    title: "Included",
    dataIndex: "included",
    key: "included",
    // width: '5%',
    // ...getColumnSearchProps('address'),
    sorter: (a, b) => {
      let aInclude = selectedRowKeysState.includes(a._id);
      let bInclude = selectedRowKeysState.includes(b._id);
      if (aInclude === bInclude) {
        return 0;
      } else if (aInclude) {
        return -1;
      } else {
        return 1;
      }
    },
    sortDirections: ["ascend", "descend"],
    render: (text, record, index) => {
      let selected = false;
      for (let i = 0; i < selectedRowKeysState.length; i++) {
        if (selectedRowKeysState[i] === record._id) {
          selected = true;
        }
      }
      return (
        <Checkbox
          checked={selected}
          onClick={(e) => onCheckboxChange(e, record)}
        ></Checkbox>
      );
    },
  };

  let columns = baseColumns;
  if (props.showDate) {
    columns = [...columns, dateColumn];
  }
  if (props.showSelect) {
    columns = [...columns, selectColumn]; //if condition
  }
  console.log();
  return <Table columns={columns} dataSource={[...files]} size="small" />;
}

export default FileTable;
