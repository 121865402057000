import { useState, useEffect } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import TitleBar from "../../components/titleBar";
import styles from "./ptSettingsBooking.module.css";
import { generalApiCall } from "../../scripts/apiCalls";
import SubscriptionsList from "../../components/subscriptionsList";
import SubscriptionEditModal from "../../components/subscriptionEditModal";
import { Button } from "antd";

function PtSettingsSubscriptions(props) {
  const setToolbarIcon = useOutletContext();
  useEffect(() => {
    setToolbarIcon(props.tbIcon);
  }, []);

  const navigate = useNavigate();
  const [subscriptionData, setSubscriptionData] = useState();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [isModalOpenEdit, setIsModalOpenEdit] = useState(false);
  const [subscriptionEditSelected, setSubscriptionEditSelected] =
    useState(undefined);

  useEffect(() => {
    console.log("get sub call?")
    generalApiCall("/getSubscriptionsSettings").then((res) => {
      console.log(res)
      setSubscriptionData(res.data);
      setDataLoaded(true);
    });
  }, []);
  const showModalEdit = (obj) => {
    setSubscriptionEditSelected(obj);
    setIsModalOpenEdit(true);
  };
  const handleCancel = () => {
    setIsModalOpenEdit(false);
  };
  const handleOkEdit = () => {
    setIsModalOpenEdit(false);
  };
  const onCreate = (values) => {
    const isNew = (values._id === "new")

    generalApiCall("/setSubscription",{new:isNew,subscription:values}).then((res) => {
      console.log(res)
      setSubscriptionData(res.data);
      setDataLoaded(true);
    });
    console.log(values)
    setIsModalOpenEdit(false);
  };

  function addNewSubscription() {
    navigate("./new", { replace: false });
  }

  function selectSubscription(sub) {
    setSubscriptionEditSelected(sub)
  }

  return (
    <div className={"fillHeight crosses " + styles.pageCtn}>
      <TitleBar name="Subscription Settings" backButton={true}></TitleBar>
      {/* <SubscriptionsList></SubscriptionsList> */}
      <div style={{ margin: "24px" }}>
        <SubscriptionsList context="PT" showModalEdit={showModalEdit}></SubscriptionsList>
        {subscriptionData?.length < 4 && (
        <Button style={{ margin: "24px 0px 0px 0px" }} type="primary" block onClick={() => showModalEdit("new")}>Add New</Button>
        )}
      </div>
      {/* <button onClick={addNewSubscription}>Add</button> */}
      {subscriptionEditSelected !== undefined && (
        <SubscriptionEditModal
          subscriptionData={subscriptionEditSelected}
          open={isModalOpenEdit}
          onCreate={onCreate}
          onCancel={handleCancel}

        />
      )}
    </div>
  );
}


export default PtSettingsSubscriptions;
