import React, { useState } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import TitleBar from "../../components/titleBar";
import FileBody from "../../components/fileBody";
// import fileData from "../../data/clientFile.js";
// import clientList from "../../data/clientListRawData.js";
import stylesButton from "../../styles/sysButtons.module.css";
import StylesInput from "../../styles/sysInputs.module.css";
// import styles from "./client/clientFile.module.css";
import styles from "./ptLibraryFile.module.css";
import RemoveIcon from "../../components/icons/removeIcon";
import AddIcon from "../../components/icons/addIcon";
import { generalApiCall } from "../../scripts/apiCalls.jsx";
import { useEffect } from "react";
import Popup from "../../components/popup";
import { useNavigate } from "react-router-dom";
import { Modal, Input } from "antd";
import LoadingScreen from "../../components/loadingScreen";

function PtLibraryFile(props) {
  const setToolbarIcon = useOutletContext();
  useEffect(() => {
    setToolbarIcon(props.tbIcon);
  }, []);

  let { fileInstId } = useParams();
  let fileId = "";

  const [addAccess, setAddAccess] = useState(false);
  const [fileData, setFileData] = useState();
  const [isFileDataLoaded, setIsFileDataLoaded] = useState(false);

  const [clientList, setClientList] = useState();
  const [isClientListLoaded, setIsClientListLoaded] = useState(false);

  const [confirmPopup, setConfirmPopup] = useState("closed");
  const [clientAccess, setClientAccess] = useState();
  const [clientSearch, setClientSearch] = useState("");

  console.log(clientList);
  console.log(clientAccess);
  useEffect(() => {
    generalApiCall("/getFileInfo", { fileInstId: fileInstId }).then(
      (result) => {
        setFileData(result.data);
        setIsFileDataLoaded(true);
        fileId = result.data.fileId;

        generalApiCall("/getFileAccess", { fileId: fileId }).then((result) => {
          setClientList(result.data);
          setIsClientListLoaded(true);
        });
      }
    );
  }, []);

  function updateFileFromEdit() {
    generalApiCall("/getFileInfo", { fileInstId: fileInstId }).then(
      (result) => {
        setFileData(result.data);
        setIsFileDataLoaded(true);
      }
    );
  }

  function toggleConfirm(clientAccess, isAdd) {
    setClientAccess(clientAccess);
    setConfirmPopup(isAdd ? "add" : "remove");
  }
  function closeConfirm() {
    setConfirmPopup("closed");
  }

  function toggleAccess() {
    setAddAccess(!addAccess);
  }

  function removeAcc(clientId) {
    generalApiCall("/removeFileAccess", {
      fileId: fileData.fileId,
      clientId: clientId,
    }).then((res) => {
      generalApiCall("/getFileAccess", { fileId: fileData.fileId }).then(
        (result) => {
          setClientList([...result.data]);
        }
      );
    });
  }
  function addAcc(id) {
    generalApiCall("/addFileAccess", {
      fileId: fileData.fileId,
      clientId: id,
    }).then((res) => {
      generalApiCall("/getFileAccess", { fileId: fileData.fileId }).then(
        (result) => {
          setClientList([...result.data]);
        }
      );
    });
  }

  function ClientCard(props) {
    const navigate = useNavigate();

    function goToInst() {
      //get file instance
      console.log(props.fileId);
      console.log(props.client._id);
      if (props.client.access) {
        generalApiCall("/getInstId", {
          fileInstId: props.fileId,
          clientId: props.client._id,
        }).then((res) => {
          navigate("/pt/client/" + props.client._id + "/files/" + res.data, {
            replace: false,
          });
        });
        // getInstanceId(props.fileId, props.client._id).then((res)=>{
        //   navigate("/pt/client/"+ props.client._id+ "/files/" + res, { replace: false })
        // })
      }
    }

    const remove = (
      <div
        className={styles.svgPosition}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          toggleConfirm(props.client, false);
        }}
      >
        <RemoveIcon
          class={styles.removeColour + " " + styles.svgPosition}
        ></RemoveIcon>
      </div>
    );
    const add = (
      <div
        className={styles.svgPosition}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          toggleConfirm(props.client, true);
        }}
      >
        <AddIcon class={styles.addColour + " " + styles.svgPosition}></AddIcon>
      </div>
    );
    const card = (
      <div onClick={goToInst} className={styles.peopleCardCtn}>
        <img
          className={styles.profileImg}
          src={"/getProfilePhoto/" + props.client._id}
          // src="/assets/images/client-face0.png"
          alt="ahhh"
        ></img>
        <p className={styles.clientName}>
          {props.client.firstName} {props.client.lastName}
        </p>
        {props.client.access ? remove : add}
      </div>
      // {/* <div className={styles.peopleCardCtn}>
      //   <img className={styles.profileImg} src="/assets/images/client-face0.png" alt="ahhh"></img>
      //   <p className={styles.clientName}>{props.client.firstName} {props.client.lastName}</p>
      //   {props.client.access ? <RemoveIcon class={styles.removeColour + " " + styles.svgPosition}></RemoveIcon> : <AddIcon class={styles.addColour + " " + styles.svgPosition}></AddIcon>}
      // </div> */}
    );

    if (addAccess & props.client.access) {
      return card;
    } else if (!addAccess & !props.client.access) {
      return card;
    }
  }

  function confirmReturnFunc() {
    closeConfirm();
    if (clientAccess.access) {
      return removeAcc(clientAccess._id);
    } else {
      return addAcc(clientAccess._id);
    }
  }

  if (!isFileDataLoaded) {
    return (<LoadingScreen/>);
  }
  return (
    <div className={"fillHeight " + styles.pageCtn}>
      <TitleBar backButton={true} name={fileData.name}></TitleBar>

      <FileBody fileData={fileData} updateFunc={updateFileFromEdit}></FileBody>

      <div className={styles.ctn}>
        <p className={styles.fileTitle}>File Access</p>
        <div className={styles.inputCtn}>
          <Input
            placeholder="Search..."
            block
            onChange={(e) => {
              setClientSearch(e.target.value.toLowerCase());
            }}
          />
          <div className={styles.svgPosition} onClick={toggleAccess}>
            {addAccess ? (
              <AddIcon class={styles.addColour} />
            ) : (
              <RemoveIcon class={styles.removeColour}></RemoveIcon>
            )}
          </div>
        </div>
        {isClientListLoaded &&
          clientList.map((client) => {
            let name = (client.firstName + " " + client.lastName).toLowerCase();
            console.log(client.firstName)
            if (name.includes(clientSearch)) {
              return (
                <ClientCard
                  key={client._id}
                  fileId={fileData._id}
                  client={client}
                ></ClientCard>
              );
            }

          })}
        {/* Map people cards */}
      </div>
      <Modal
        centered
        open={confirmPopup !== "closed"}
        okText="Yes"
        cancelText="No"
        onCancel={closeConfirm}
        onOk={confirmReturnFunc}
      >
        <h4>
          Are you sure you want to {confirmPopup === "add" ? "add" : "remove"}{" "}
          them?
        </h4>
      </Modal>
    </div>
  );
}

export default PtLibraryFile;
