import React, { useEffect } from "react";
import styles from "../routes/client/clientFiles.module.css";
import { generalApiCall } from "../scripts/apiCalls";
import { Form, Input, Modal, Select } from "antd";

function EditFilePopup(props) {

  const [form] = Form.useForm();
  const { TextArea } = Input;

  useEffect(() => {
    form.setFieldsValue({
      name: props.fileData.name,
      description: props.fileData.description,
      label: props.fileData.label,
    });
  })

  function update() {
    const dataToSend = {
      name: document.getElementById("editName").value,
      description: document.getElementById("editDescription").value,
      label: props.isPt && document.getElementById("editLabel").value,
      fileId: props.fileData._id,
    };
    generalApiCall("/updateFile", dataToSend).then((res) => {
      props.close(res.data);
    });
  }

  function onCreate(data) {
    console.log(data)
    const dataToSend = {
      name: data.name,
      description: data.description,
      label: props.isPt && data.label,
      fileId: props.fileData._id,
    };
    generalApiCall("/updateFile", dataToSend).then((res) => {
      props.onClose(res.data);
    });
    // update();
  }


  console.log(props.fileData);
  function LabelAccess() {
    if (props.isPt) {
      return (
        <Form.Item
          name="label"
          label="Label"
        >
          <Select options={[
            {
              label: "Nutrition",
              value: "Nutrition",
            },
            {
              label: "Workout",
              value: "Workout",
            },
            {
              label: "Corrective",
              value: "Corrective",
            },
            {
              label: "Mobility",
              value: "Mobility",
            },
            {
              label: "Other",
              value: "Other",
            },
            {
              label: "Client Upload",
              value: "Client Upload",
              disabled: true
            },
          ]} />
        </Form.Item>
      );
    }
  }

  return (
    <Modal
      centered
      open={props.open}
      title="Edit File"
      okText="Save"
      cancelText="Cancel"
      onCancel={props.onClose}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onCreate(values);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <Form form={form} layout="vertical" name="form_in_modal">
        <Form.Item
          name="name"
          label="Title"
        >
          <Input></Input>
        </Form.Item>
        <Form.Item
          name="description"
          label="Description"
        >
          <TextArea rows={4}></TextArea>
        </Form.Item>
        {LabelAccess()}
        {/* <Form.Item
          name="feedbackText"
          // label="Hi"
          className="collection-create-form_last-form-item"
        >
          <TextArea rows={4}></TextArea>
        </Form.Item> */}
      </Form>
    </Modal>
  );
}

export default EditFilePopup;
