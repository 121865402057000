import React, { useState } from "react";
import { useParams } from "react-router-dom";
import FileViewer from "./fileViewer";
import FileRemove from "./fileRemove";
import stylesButton from "../styles/sysButtons.module.css";
import styles from "../routes/client/clientFile.module.css";
import useAuth from "../scripts/useAuth";
import EditFilePopup from "./editFilePopup";
import { Button, Card, Space, Modal } from "antd";

function FileBody({ fileData, updateFunc }) {
  const { packageId, accessCode } = useParams();
  const [editPopup, setEditPopup] = useState(false);
  const { userData } = useAuth();


  const toggleEditPopup = (res) => {
    if (res === "updated") {
      updateFunc();
    }
    setEditPopup((prevState) => !prevState);
  };

  const downloadURL = () => {
    const baseUrl = process.env.REACT_APP_BACKEND_URL;
    const path = `downloadFile/${fileData._id}`;
    const accessCodePath = accessCode ? `/${accessCode}` : "";

    return `${baseUrl}/${path}${accessCodePath}`;
  };

  const formatedTimestamp = () => {
    const timestamp = new Date(fileData.date);
    return timestamp.toLocaleString("en-NZ", {
      day: "numeric",
      month: "numeric",
      year: "2-digit",
    });
  };

  const shouldShowEditButton = () => {
    return (
      packageId === undefined && (userData.isPt === fileData.ptUploaded) //both or neither true
    );
  };

  return (
    <div style={{ margin: "0px 5%" }}>
      <FileViewer fileType={fileData.extension} fileId={fileData._id} />
      <div style={{ marginTop: 16 }}></div>
      {/* Description */}
      <Card
        title="Description"
        extra={"Uploaded: " + formatedTimestamp()}
        size="small"
      >
        <p>{fileData.description}</p>
      </Card>

      <div style={{ marginTop: 16 }}></div>
      {/* Buttons */}
      {shouldShowEditButton() && (
        <>
          <Button type="primary" block onClick={() => toggleEditPopup()}>
            Edit
          </Button>
          <div style={{ marginTop: 4 }}></div>
        </>
      )}
      <div style={{ display: "flex", gap: 4 }}>
        <a className={styles.downloadButton} href={downloadURL()} download>
          <Button type="dashed" block>
            Download
          </Button>
        </a>

        {accessCode === undefined &&
          !(fileData.ptUploaded && !userData.isPt) && (
            <div style={{ width: "100%" }}>
              <FileRemove
                fileId={fileData._id}
                ptUploaded={fileData.ptUploaded}
              />
            </div>

          )}
      </div>
      {accessCode === undefined && (
        <EditFilePopup
          fileData={fileData}
          isPt={userData.isPt}
          onClose={(res) => toggleEditPopup(res)}
          open={editPopup}
        />
      )}
    </div>
  );
}

export default FileBody;
