// import React from "react";
import useAuth from "../../scripts/useAuth";
import styles from "./clientSettingsInfoEdit.module.css";
import TitleBar from "../../components/titleBar";
import sysButtons from "../../styles/sysButtons.module.css";
import sysInputs from "../../styles/sysInputs.module.css";
import { Card, Typography, Button, Input, Select, DatePicker } from "antd";
import { useState, useEffect } from "react";
import { generalApiCall, uploadProfilePhoto } from "../../scripts/apiCalls.jsx";
import { useNavigate, useOutletContext } from "react-router-dom";
import dayjs from "dayjs";

function ClientSettingsInfoEdit(props) {
  const setToolbarIcon = useOutletContext();
  useEffect(() => {
    setToolbarIcon(props.tbIcon);
  }, []);

  const navigate = useNavigate();
  const { userData, updateUserData } = useAuth();
  const isPt = userData.isPt;

  const tmpUser = {
    username: "",
    firstName: "",
    lastName: "",
    gender: "",
    dob: "",
    address: {
      street: "",
      town: "",
      country: "",
    },
    mobileNumber: "",
  };

  const [userInfo, setUserInfo] = useState(tmpUser);
  const [infoGender, setInfoGender] = useState("");


  useEffect(() => {
    generalApiCall("/getMyInfo").then((result) => {
      setUserInfo(result.data);
      setInfoGender(result.data.gender)
    });
  }, []);

  function sendNewInfo() {
    let hardCopy = JSON.parse(JSON.stringify(userInfo));
    hardCopy.firstName = document.getElementById("settingsEditFirstName").value;
    hardCopy.lastName = document.getElementById("settingsEditLastName").value;
    hardCopy.mobile = document.getElementById("settingsEditPhone").value;
    hardCopy.username = document.getElementById("settingsEditEmail").value;

    hardCopy.dob = document.getElementById("DOB").value;
    hardCopy.address.street = document.getElementById("street").value;
    hardCopy.address.town = document.getElementById("town").value;
    hardCopy.address.country = document.getElementById("country").value;
    hardCopy.gender = infoGender;
    console.log(hardCopy);

    generalApiCall("/updateMyInfo", { newData: hardCopy }).then((result) => {
      const updatedInfo = {
        username: result.data.username,
        firstName: result.data.firstName,
        lastName: result.data.lastName,
      };
      updateUserData(updatedInfo).then(() => {
        navigate(-1, { replace: false });
      });
    });
  }

  function handlePhotoUpload(event) {
    const file = event.target.files[0];
    uploadProfilePhoto(file).then(() => {
      //force update
      const profileImg = document.getElementById("infoEditProfileImage");
      const timestamp = new Date().getMilliseconds();
      profileImg.src = "/getProfilePhoto/" + userData._id + "?dummy="+timestamp;
      console.log("should change profile img")
    });
  }

  function removePhoto() {
    // Set the profile image to the default image
    generalApiCall("/deleteProfilePhoto", {}).then((result) => {
      const profileImg = document.getElementById('infoEditProfileImage');
      profileImg.src = "/getProfilePhoto/" + userData._id + "?dummy=123";

    });
  }



  function gender() {
    return (
      <div className={styles.grid}>
        <p>Gender</p>

        <div className="verticalCenter">
          <Select
            id="settingsEditGender"
            defaultValue={userInfo.gender}
            onChange={(value) => {
              setInfoGender(value)
            }}
            options={[
              {
                value: "Male",
                label: "Male",
              },
              {
                value: "Female",
                label: "Female",
              },
              {
                value: "Non-binary",
                label: "Non-binary",
              },
              {
                value: "Other",
                label: "Other",
              },
            ]}
          />
        </div>
      </div>
    );
  }

  function age() {
    const isoDate = userInfo.dob.split("T")[0];

    return (
      <div className={styles.grid}>
        <p>Age</p>
        <div className="verticalCenter">
          <DatePicker id="DOB" defaultValue={dayjs(isoDate)} />
          {/* <input
              defaultValue={isoDate}
              type="date"
              id="DOB"
              placeholder="D.O.B"
              className={sysInputs.textInput}
            ></input> */}
        </div>
      </div>
    );
  }

  function address() {
    return (
      <div>
        <p>Address</p>
        <Input
          defaultValue={userInfo.address.street}
          id="street"
          placeholder="123 Waterway Ave..."
        ></Input>
        <Input
          defaultValue={userInfo.address.town}
          id="town"
          placeholder="Town..."
        ></Input>
        <Input
          defaultValue={userInfo.address.country}
          id="country"
          placeholder="Country..."
        ></Input>
      </div>
    );
  }

  return (
    <div className={"fillHeight crosses " + styles.pageCtn}>
      <TitleBar name="Personal Info - Edit" backButton={true}></TitleBar>
      {userInfo.username !== "" && (
        <div className={styles.ctn}>
          <p>Photo</p>
          <div className={styles.photoGrid}>
            <img
              id="infoEditProfileImage"
              className={styles.profileImage}
              src={"/getProfilePhoto/" + userData._id}
              alt="Sam"
            />
            <div className="verticalCenter">
              <input
                type="file"
                id="userImage"
                onChange={handlePhotoUpload}
                className={styles.upload}
              />
              {/* <label
                htmlFor="userImage"
                style={{ boxSizing: "border-box", margin: "0 0 10px 0" }}
                className={buttonStyle()}
              >
                Upload Image
              </label> */}
              <Button
                type="primary"
                onClick={() => {
                  document.getElementById("userImage").click();
                }}
              >
                Upload Image
              </Button>
              <div style={{ marginTop: 12 }}></div>
              <Button type="primary"
                onClick={removePhoto}
              >Remove</Button>
            </div>
          </div>
          <p>Name</p>
          <div>
            <Input
              id="settingsEditFirstName"
              defaultValue={userInfo.firstName}
              placeholder="First Name..."
            ></Input>
            <Input
              id="settingsEditLastName"
              defaultValue={userInfo.lastName}
              placeholder="Last Name..."
            ></Input>
          </div>
          {gender()}
          {age()}
          <p>Contact</p>
          <Input
            id="settingsEditPhone"
            defaultValue={userInfo.mobile}
            placeholder="Phone..."
          ></Input>
          <Input
            id="settingsEditEmail"
            defaultValue={userInfo.username}
            placeholder="Email..."
          ></Input>
          {address()}
          {/* <button
            onClick={sendNewInfo}
            className={buttonStyle() + " " + styles.save}
          >
            Save
          </button> */}
          <div style={{ height: "16px" }}></div>
          <Button block type="primary" onClick={sendNewInfo}>
            Save
          </Button>
          <div style={{ height: "16px" }}></div>
        </div>
      )}
    </div>
  );
}

export default ClientSettingsInfoEdit;
