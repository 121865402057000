import $ from "jquery";

const baseUrl =
    process.env.NODE_ENV === "development"
        ? "http://localhost:5000"
        : "";

function apiCall(url, data) {
    // Create and return a new Promise
    return new Promise((resolve) => {
        // Make a POST request using jQuery
        $.post(url, data)
            .done(function (responseData, textStatus, jqXHR) {
                // Log the status and data to the console
                console.log("status: " + textStatus + ", data: " + JSON.stringify(responseData));
                console.log(jqXHR)
                // Resolve the promise with an object containing the data and status
                resolve({ data: responseData, status: jqXHR.status });
            })
            .fail(function (jqxhr, textStatus, errorThrown) {
                // Log the error message to the console
                console.log("failed, " + errorThrown); // Example output: "failed, Unauthorized"
                
                // Resolve the promise with false to indicate the request failed
                resolve({data:textStatus, error:errorThrown});
            });
    });
}

export async function initLoggedIn() {
    return await apiCall("/testLogin", {});
}

export async function login(user) {
    return await apiCall("/login", user);
}

export async function register(user) {
    return await apiCall("/register", user);
}

export async function logout() {
    return await apiCall("/logout", {});
}

export async function getHubColours(id) {
    return await apiCall("/getHubColours", { ptId: id });
}

