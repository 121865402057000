import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { generalApiCall } from "../scripts/apiCalls";
import styles from "./register.module.css";
function BuyPackageRedirect() {
  const navigate = useNavigate();
  let { packageId } = useParams();
  useEffect(() => {
    console.log(packageId)
    if (packageId) {
      generalApiCall("/buyPackage/" + packageId).then((res) => {
        if (res.status === 200) {
          window.location.replace(res.data.url);
        }
      });
    }
  }, []);
  return <div className={"fillHeight " + styles.cnt}>One moment...</div>;
}

export default BuyPackageRedirect;
