import React from "react";
import styles from "./ptClientList.module.css";
import { generalApiCall } from "../../scripts/apiCalls.jsx";
import { useOutletContext, useNavigate, Link } from "react-router-dom";
import { Badge, List, message, Input } from "antd";
import { useEffect, useState } from "react";
import { MessageOutlined } from "@ant-design/icons";

function PtClientList(props) {
  const setToolbarIcon = useOutletContext();
  useEffect(() => {
    setToolbarIcon(props.tbIcon);
  }, []);
  const [clientData, setClientData] = useState();
  const [clientDataRaw, setClientDataRaw] = useState()
  const [isLoaded, setIsLoaded] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    generalApiCall("/getClientList").then((result) => {
      //maybe move this to the backend?
      const users = result.data.map((client) => {
        client.age = dateToAge(client.dob);
        delete client.dob; //delete client["dob"];
        return client;
      });

      setClientDataRaw(users);
      setClientData(users);
      setIsLoaded(true);
    });
  }, []);

  function dateToAge(dob) {
    const timeDiff = new Date(new Date() - new Date(dob));
    const age = timeDiff.getUTCFullYear() - 1970; //time counts from 1970
    return age;
  }

  function searchChange(e) {
    console.log(e.target.value);
    let searchText = e.target.value.toLowerCase();
    let searchData = [];
    for (let i = 0; i < clientDataRaw.length; i++) {
      let fullName = (
        clientDataRaw[i].firstName +
        " " +
        clientDataRaw[i].lastName
      ).toLowerCase();
      if (fullName.includes(searchText)) {
        searchData.push(clientDataRaw[i]);
      }
    }
    setClientData(searchData);
  }

  function toMessages(id) {
    navigate("client/" + id + "/messages", { replace: false });
  }

  return (
    <div className={"fillHeight crosses " + styles.pageCtn}>
      <div
        style={{ padding: "8px 8px", backgroundColor: "rgba(0, 0, 0, 0.45)" }}
      >
        <Input
          style={{ width: "100%" }}
          onChange={searchChange}
          placeholder="Search.."
        ></Input>
      </div>
      <div style={{ height: "2px" }}></div>
      <List
        size="small"
        dataSource={clientData}
        renderItem={(item) => (
          <List.Item
            key={item._id}
            style={{
              padding: "8px 5%",
              backgroundColor: "#e6f4ff",
              cursor: "pointer",
              // borderRadius: "8px",
              // margin: "1px 2px",
            }}
            onClick={(e) => {
              e.preventDefault();
              navigate("/pt/client/" + item._id + "/profile");
            }}
          >
            <div
              style={{
                display: "flex",
                flex: "1",
                alignItems: "flex-start",
                maxWidth: "100%",
              }}
            >
              <img
                style={{
                  width: 40,
                  height: 40,
                  objectFit: "cover",
                  borderRadius: 8,
                  marginInlineEnd: 16,
                }}
                alt="profile"
                src={"/getProfilePhoto/" + item._id}
              />
              <div style={{ flex: "1 0", width: 0 }}>
                <h4
                  style={{ margin: 0, fontSize: 14 }}
                >{`${item.firstName} ${item.lastName}`}</h4>
                <div
                  style={{ fontSize: 12, color: "rgba(0, 0, 0, 0.45)" }}
                >{`${item.gender}, ${item.age}`}</div>
              </div>
            </div>
            <div
              // role="link"
              onMouseDown={(e) => {
                e.preventDefault();
                toMessages(item._id);
              }}
            >
        {item.ptSeen === false ? (
          <Badge dot>
            <MessageOutlined style={{ fontSize: "24px", color: "black"  }} />
          </Badge>
        ) : (
          <MessageOutlined style={{ fontSize: "24px", color: "black"  }} />
        )}
      </div>
          </List.Item>
        )}
      />
    </div>
  );
}
export default PtClientList;
