import { useEffect } from "react";
import styles from "./register.module.css";
import { Input, Select, Form } from "antd";

function RegisterForm({ formData, formRef }) {
  useEffect(() => {
    formRef.setFieldsValue(formData);
  }, []);
  const required = [
    {
      required: true,
      message: "Required",
    },
  ];

  const validateAge = (rule, value) => {
    return new Promise((resolve, reject) => {
      const dobDate = new Date(value);
      const diff = new Date(new Date() - dobDate);
      const age = diff.getUTCFullYear() - 1970;
      if (age < 18) {
        reject("Oh no, youre a wee baby (under 18)");
      } else {
        resolve();
      }
    });
  };
  const validatePassword = (rule, value) => {
    return new Promise((resolve, reject) => {
      const regex = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])([a-zA-Z0-9]+){8,}$/;

      if (value && !regex.test(value)) {
        reject(
          "Password must be 8+ characters with 1 uppercase, 1 lowercase, and 1 number"
        );
      } else {
        resolve();
      }
    });
  };

  return (
    <Form className={styles.box} form={formRef}>
      <label className={styles.label}>Details</label>
      <div className={styles.grid}>
        <Form.Item name="fName" rules={required}>
          <Input
            className={styles.margin}
            type="text"
            // id="fName"
            placeholder="First Name..."
            defaultValue={formData.fName || ""}
          />
        </Form.Item>
        <Form.Item name="lName" rules={required}>
          <Input
            className={styles.margin}
            type="text"
            id="lName"
            placeholder="Last Name..."
            defaultValue={formData.lName || ""}
          />
        </Form.Item>
      </div>
      <Form.Item
        name="email"
        rules={[
          {
            required: true,
            type: "email",
            message: "Not a valid email",
          },
        ]}
      >
        <Input
          className={styles.margin}
          type="email"
          id="email"
          placeholder="Email..."
          defaultValue={formData.email || ""}
        />
      </Form.Item>
      <Form.Item name="phNumber">
        <Input
          className={styles.margin}
          type="tel"
          id="phNumber"
          placeholder="Phone..."
          defaultValue={formData.phNumber || ""}
        />
      </Form.Item>
      <div className={styles.grid + " " + styles.margin}>
        <label>Gender </label>
        <Form.Item name="gender" rules={required}>
          <Select
            type="text"
            id="gender"
            placeholder="Gender"
            defaultValue={formData.gender}
            onChange={(e) => {
              console.log(e);
            }}
            options={[
              {
                value: "Male",
                label: "Male",
              },
              {
                value: "Female",
                label: "Female",
              },
              {
                value: "Non-Binary",
                label: "Non-Binary",
              },
              {
                value: "Other",
                label: "Other",
              },
            ]}
          ></Select>
        </Form.Item>
      </div>
      <div className={styles.grid + " " + styles.margin}>
        <label>Date of Birth</label>
        <Form.Item
          name="dob"
          rules={[
            { required: true, message: "Required" },
            { validator: validateAge },
          ]}
        >
          <Input
            type="date"
            id="dob"
            defaultValue={formData.dob || ""}
          />
        </Form.Item>
      </div>
      <label className={styles.label}>Address</label>
      <Form.Item name="street" rules={required}>
        <Input
          className={styles.margin}
          type="text"
          id="street"
          placeholder="Street..."
          defaultValue={formData.street || ""}
        />
      </Form.Item>
      <Form.Item name="town" rules={required}>
        <Input
          className={styles.margin}
          type="text"
          id="town"
          placeholder="Town..."
          defaultValue={formData.town || ""}
        />
      </Form.Item>
      <Form.Item name="country" rules={required}>
        <Input
          className={styles.margin}
          type="text"
          id="country"
          placeholder="Country..."
          defaultValue={formData.country || ""}
        />
      </Form.Item>
      <label className={styles.label}>Password</label>
      <Form.Item
        name="password1"
        rules={[
          { required: true, message: "Please input your new Password" },
          { validator: validatePassword },
        ]}
      >
        <Input
          className={styles.margin}
          type="password"
          id="password1"
          placeholder="Password..."
          autoComplete="new-password"
          defaultValue={formData.password1 || ""}
        />
      </Form.Item>
      <Form.Item
        name="password2"
        rules={[
          { required: true, message: "Please confirm your Password" },
          // { validator: validatePassword },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue("password1") === value) {
                return Promise.resolve();
              }
              return Promise.reject(
                new Error("The two passwords that you entered do not match!")
              );
            },
          }),
        ]}
      >
        <Input
          className={styles.margin}
          type="password"
          id="password2"
          placeholder="Confirm Password..."
          autoComplete="new-password"
          defaultValue={formData.password2 || ""}
        />
      </Form.Item>
    </Form>
  );
}

export default RegisterForm;