import React, { useState, useEffect } from "react";
import styles from "./bookingMade.module.css";
import TitleBar from "../components/titleBar";
import { Link } from "react-router-dom";
import { CloseCircleOutlined, CheckCircleOutlined } from "@ant-design/icons";
import { Button } from "antd";

function PackagePaid() {
  const [success, setSuccess] = useState(false);
  const [packageInfo, setPackageInfo] = useState();

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get("success")) {
      setSuccess(true);
      // "Order placed! You will receive an email confirmation."
      setPackageInfo({
        packageId: query.get("packageId"),
        accessCode: query.get("accessCode"),
      });
    }
    //SatOct01202209:00:00GMT+1300(NewZealandDaylightTime)
    if (query.get("canceled")) {
      setSuccess(false);
    }
  }, []);

  return (
    <div className={"fillHeight " + styles.cnt}>
      <TitleBar backButton={false} name="CONFIRMATION" />
      <div  className={styles.generalCtn}>
      {success ? (
        <div className={styles.bookingMadeCnt}>
          <div className={styles.page}>
            <CheckCircleOutlined style={{ color: "Green", fontSize: "64px" }} />
            <h3>Purchase Successful</h3>
            <p className={styles.text}>
              You will receive email confirmation with an access code to access
              the package
            </p>
            <p className={styles.closeText}>
              Already a client of <b>PT NAME?</b>  
            </p>
            <p className={styles.text}>
              You can also access the package by
              logging in <Link to="/login">~here~</Link>
            </p>
            <Button type="primary">
              <Link
                to={
                  "/packages/" +
                  packageInfo.packageId +
                  "/" +
                  packageInfo.accessCode
                }
              >
                View Package
              </Link>
            </Button>
          </div>
        </div>
      ) : (
        <div className={styles.bookingFailedCtn}>
          <div className={styles.page}>
            <CloseCircleOutlined style={{ color: "Red", fontSize: "64px" }} />
            <h3>Purchase Unsuccessful</h3>
            <p className={styles.text}>
              Please return to your point of purchase to try again
            </p>
          </div>
        </div>
      )}
      </div>
    </div>
  );
}

export default PackagePaid;

// http://localhost:3000/test?success=true&start=08:00%20am&end=09:00%20am&date=08%20Oct%202022
// teeeeeeesting payments <3
