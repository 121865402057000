import $ from "jquery";

$.ajaxSetup({
  crossDomain: true,
  xhrFields: {
    withCredentials: true,
  },
});

export async function generalApiCall(url, data) {
  return new Promise((res) => {
    $.post(
      url, // url
      data, // data to be submit
      function (responseData, status, jqXHR) {
        // success callback
        console.log(
          "URL: " +
            url +
            ", status: " +
            status +
            ", data: " +
            JSON.stringify(responseData)
        );
        // res(responseData,status);
        res({ data: responseData, status: jqXHR.status });
      }
    ).fail(function (jqxhr, settings, ex) {
      console.log("failed, " + ex);
      console.log(jqxhr);
      console.log(jqxhr.responseJSON);
      console.log(jqxhr.status);
      let returnData = "";
      if (jqxhr.responseJSON !== undefined) {
        returnData = jqxhr.responseJSON;
      } else {
        returnData = jqxhr.responseText;
      }
      console.log(returnData);
      // res([jqxhr.responseJSON,jqxhr.status]);
      res({ data: returnData, status: jqxhr.status });
    });
  });
}

//this also used date to age funciton
// export async function GetClientList() {
//   // const { userData} = useAuth();
//   return new Promise((res) => {
//     $.post(
//       "/getClientList", // url
//       { }, // data to be submit
//       function (data, status, jqXHR) {
//         //data is whats returned from the server
//         // success callback
//         console.log("status: " + status + ", data: " + JSON.stringify(data));

//         const users = data.map((client)=>{
//           client.age = dateToAge(client.dob)
//           delete client.dob; //delete client["dob"];
//           return client;
//         });

//         res(users); //think this just resolves the promise. no idea what it does
//       }
//     ).fail(function (jqxhr, settings, ex) {
//       console.log("failed, " + ex); //will spit "failed, Unauthorized"

//       res(); //think this just resolves the promise. no idea what it does
//     });
//   });
// }

export async function GetClientInfo(userId) {
  // const { userData} = useAuth();
  return new Promise((res) => {
    $.post(
      "/getClientInfo", // url
      { clientId: userId }, // data to be submit
      function (data, status, jqXHR) {
        //data is whats returned from the server
        // success callback
        console.log("status: " + status + ", data: " + JSON.stringify(data));

        //formatting dob to age
        // data["age"] = dateToAge(data.dob)
        // delete data.dob; //delete client["dob"];

        res(data); //think this just resolves the promise. no idea what it does
      }
    ).fail(function (jqxhr, settings, ex) {
      console.log("failed, " + ex); //will spit "failed, Unauthorized"

      res(); //think this just resolves the promise. no idea what it does
    });
  });
}

// export async function GetMessages(userId) {
//   // const { userData} = useAuth();
//   return new Promise((res) => {
//     $.post(
//       "/getMessages", // url
//       {clientId : userId}, // data to be submit
//       function (data, status, jqXHR) {
//         //data is whats returned from the server
//         // success callback
//         console.log("status: " + status + ", data: " + JSON.stringify(data));

//         res(data); //think this just resolves the promise. no idea what it does
//       }
//     ).fail(function (jqxhr, settings, ex) {
//       console.log("failed, " + ex); //will spit "failed, Unauthorized"

//       res(); //think this just resolves the promise. no idea what it does
//     });
//   });
// }

// export async function GetMessagesUpdate(lastId,userId) {
//   // const { userData} = useAuth();
//   return new Promise((res) => {
//     $.post(
//       "/getMessagesUpdate", // url
//       {
//         lastId: lastId,
//         clientId : userId
//       }, // data to be submit
//       function (data, status, jqXHR) {
//         //data is whats returned from the server
//         // success callback
//         console.log("status: " + status + ", data: " + JSON.stringify(data));

//         res(data); //think this just resolves the promise. no idea what it does
//       }
//     ).fail(function (jqxhr, settings, ex) {
//       console.log("failed, " + ex); //will spit "failed, Unauthorized"

//       res(); //think this just resolves the promise. no idea what it does
//     });
//   });
// }

// export async function SendMessage(message,userId) {
//   console.log("message clicked")
//   // const { userData} = useAuth();
//   return new Promise((res) => {
//     $.post(
//       "/sendMessage", // url
//       {
//         clientId : userId,
//         message:message
//       }, // data to be submit
//       function (data, status, jqXHR) {
//         //data is whats returned from the server
//         // success callback
//         console.log("status: " + status + ", data: " + JSON.stringify(data));

//         res(data); //think this just resolves the promise. no idea what it does
//       }
//     ).fail(function (jqxhr, settings, ex) {
//       console.log("failed, " + ex); //will spit "failed, Unauthorized"

//       res(); //think this just resolves the promise. no idea what it does
//     });
//   });
// }

function dateToAge(dob) {
  const timeDiff = new Date(new Date() - new Date(dob));
  const age = timeDiff.getUTCFullYear() - 1970; //time counts from 1970
  return age;
}

export async function uploadProfilePhoto(file) {
  // const { userData} = useAuth();
  return new Promise((res) => {
    var formData = new FormData();
    formData.append("file", file);

    $.ajax({
      url: "/uploadProfilePhoto",
      type: "POST",
      data: formData,
      processData: false,
      contentType: false,
      success: function (data) {
        console.log(data);
        res(data);
      },
      fail: function (data) {
        console.log(data);
        res();
      },
    });
  });
}
export async function uploadFile(file, info) {
  // const { userData} = useAuth();
  const filesInfoString = JSON.stringify(info);
  return new Promise((res) => {
    var formData = new FormData();
    for (let i = 0; i < file.length; i++) {
      formData.append("file" + i, file[i]);
    }
    console.log(info)
    console.log(filesInfoString)
    formData.append("filesInfo", filesInfoString);

    $.ajax({
      xhr: function () {
        var xhr = new window.XMLHttpRequest();

        xhr.upload.addEventListener(
          "progress",
          function (evt) {
            if (evt.lengthComputable) {
              var percentComplete = evt.loaded / evt.total;
              percentComplete = parseInt(percentComplete * 100);
              console.log(percentComplete);
              if (percentComplete === 100) {
              }
            }
          },
          false
        );

        return xhr;
      },
      url: "/uploadFile",
      type: "POST",
      data: formData,
      processData: false,
      contentType: false,
      success: function (data) {
        console.log(data);
        res(data);
      },
      fail: function (data) {
        console.log(data);
        res();
      },
    });
  });
}
// export async function uploadFile(file, info) {
//   // const { userData} = useAuth();
//   return new Promise((res) => {

//     var formData = new FormData()
//     formData.append('file', file)
//     formData.append('name', info.name)
//     formData.append('description', info.description)
//     formData.append('label', info.label)

//     $.ajax({
//         url: "/uploadFile",
//         type: 'POST',
//         data: formData,
//         processData: false,
//         contentType: false,
//         success: function(data) {
//             console.log(data);
//             res(data)
//         },
//         fail: function(data){
//           console.log(data)
//           res()
//         }
//     });
//   });
// }
