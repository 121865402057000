import React, { useEffect, useState } from "react";
import LoginExample1 from "./loginExamples/loginExample1";
import LoginExample2 from "./loginExamples/loginExample2";

let arrayOfExamples = [<LoginExample1 />, <LoginExample2 />];

function Login(props) {
  useEffect(() => {
    const handleKeyDown = (event) => {
      const code = event.code;
      if (code === "Space") {
        console.log("running cycleExamples");
        cycleExamples();
      }
    };
    document.addEventListener("keydown", handleKeyDown, false);
    return () => {
      document.removeEventListener("keydown", handleKeyDown, false);
    };
  }, []);

  const [exampleIndex, setExampleIndex] = useState(0);
  function cycleExamples() {
    setExampleIndex((prevExampleIndex) => {
      if (prevExampleIndex === arrayOfExamples.length - 1) {
        return 0;
      } else {
        return prevExampleIndex + 1;
      }
    });
  }

  return arrayOfExamples[exampleIndex];
}

export default Login;
