import { useState, useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import TitleBar from "../../components/titleBar";
import styles from "./ptSettingsBooking.module.css";
import sysButtons from "../../styles/sysButtons.module.css";
import sysInputs from "../../styles/sysInputs.module.css";
import { generalApiCall } from "../../scripts/apiCalls";

function PtSettingsTheme(props) {
  const setToolbarIcon = useOutletContext();
  useEffect(() => {
    setToolbarIcon(props.tbIcon);
  }, []);

  const style = getComputedStyle(document.body);
  const tmpColours = {
    primaryDark: style.getPropertyValue("--primaryDark"),
    primary: style.getPropertyValue("--primary"),
    primaryLight: style.getPropertyValue("--primaryLight"),
    secondaryDark: style.getPropertyValue("--secondaryDark"),
    secondary: style.getPropertyValue("--secondary"),
    grayDark: style.getPropertyValue("--grayDark"),
    grayLight: style.getPropertyValue("--grayLight"),
    primaryBg: style.getPropertyValue("--primaryBg"),
    offWhite: style.getPropertyValue("--offWhite"),
    textColour: style.getPropertyValue("--textColour"),
  };
  const [themeData, setThemeData] = useState(tmpColours);

  useEffect(() => {
    generalApiCall("/getHubColours").then((res) => {
      console.log(res.data)
      setThemeData(res.data);
    });
  }, []);

  function sendTheme() {
    let tmpTheme = {
      primary: document.getElementById("colPrimary").value,
      primaryDark: document.getElementById("colPrimaryDark").value,
      primaryLight: document.getElementById("colPrimaryLight").value,
      secondaryDark: document.getElementById("colSecondaryDark").value,
      secondary: document.getElementById("colSecondary").value,
      grayDark: document.getElementById("colGrayDark").value,
      grayLight: document.getElementById("colGrayLight").value,
      primaryBg: document.getElementById("colPrimaryBg").value,
      offWhite: document.getElementById("colOffWhite").value,
      textColour: document.getElementById("colTextColour").value,
    };
    console.log(tmpTheme);
    generalApiCall("/setHubColours",{theme: tmpTheme})
  
  }

  return (
    <div className={"fillHeight crosses " + styles.pageCtn}>
      <TitleBar name="Theme Settings" backButton={true}></TitleBar>
      <div className={styles.ctn}>
        <label>primaryDark:</label>
        <input
          id="colPrimaryDark"
          type="color"
          defaultValue={themeData.primaryDark}
        ></input>
        <label>primary:</label>
        <input id="colPrimary" type="color" defaultValue={themeData.primary}></input>
        <label>primaryLight</label>
        <input id="colPrimaryLight" type="color" defaultValue={themeData.primaryLight}></input>
        <label>secondaryDark</label>
        <input id="colSecondaryDark" type="color" defaultValue={themeData.secondaryDark}></input>
        <label>secondary</label>
        <input id="colSecondary" type="color" defaultValue={themeData.secondary}></input>
        <label>grayDark</label>
        <input id="colGrayDark" type="color" defaultValue={themeData.grayDark}></input>
        <label>grayLight</label>
        <input id="colGrayLight" type="color" defaultValue={themeData.grayLight}></input>
        <label>primaryBg</label>
        <input id="colPrimaryBg" type="color" defaultValue={themeData.primaryBg}></input>
        <label>offWhite</label>
        <input id="colOffWhite" type="color" defaultValue={themeData.offWhite}></input>
        <label>textColour</label>
        <input id="colTextColour" type="color" defaultValue={themeData.textColour}></input>
        <button onClick={sendTheme}>Save</button>
      </div>
    </div>
  );
}

export default PtSettingsTheme;
