import React, { useEffect, useState } from "react";
import styles from "./clientHome.module.css";
import sysButtons from "../../styles/sysButtons.module.css";
import ScheduleIcon from "../../components/icons/scheduleIcon";
import ForwardIcon from "../../components/icons/forwardIcon";
import useAuth from "../../scripts/useAuth.jsx";
import { useNavigate, useOutletContext } from "react-router-dom";
import { Button, Divider, Radio, Space } from "antd";
import { CalendarOutlined } from "@ant-design/icons";
import { generalApiCall } from "../../scripts/apiCalls";

function ClientHome(props) {
  const setToolbarIcon = useOutletContext();
  useEffect(() => {
    setToolbarIcon(props.tbIcon);
  }, []);

  let navigate = useNavigate();

  const [canBook, setCanBook] = useState(undefined);

  useEffect(() => {
    generalApiCall("/isPtBookingAuthed", {}).then((res) => {
      console.log(res)
      setCanBook(res.data);
    });
  }, []);

  function goToBooking() {
    navigate("/client/booking", { replace: false });
  }
  function goToPackages() {
    navigate("/client/packages", { replace: false });
  }
  function goToSubscriptions() {
    navigate("/client/subscriptions", { replace: false });
  }

  const { userData } = useAuth();
  const clientName = userData.firstName;

  return (
    // <div className={styles.container}>
    <div className={"fillHeight " + styles.container}>
      <h1 className={styles.welcome}>Welcome {clientName}</h1>
      <div style={{ width: "85%" }}>
        <div style={{ marginBottom: "16px" }}></div>
        {/* <Button size="large" type="primary" block icon={<CalendarOutlined />} onClick={goToBooking}>Make a Booking</Button> */}
        {canBook === true && (
          <Button size="large" type="primary" block onClick={goToBooking}>
            Make a Booking
          </Button>
        )}
        <div style={{ marginBottom: "16px" }}></div>
        <Button size="large" type="primary" block onClick={goToSubscriptions}>
          Subscriptions
        </Button>
        <div style={{ marginBottom: "16px" }}></div>
        <Button size="large" type="primary" block onClick={goToPackages}>
          View Packages
        </Button>
      </div>
    </div>
  );
}

export default ClientHome;
