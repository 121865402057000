import React, { useEffect } from "react";
import { useState } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import FileBody from "../components/fileBody";
import TitleBar from "../components/titleBar";
import packages from "../data/packages";
import { generalApiCall } from "../scripts/apiCalls";

function ExternalPackageFile() {
  let { fileId, accessCode } = useParams();

  const [fileData, setFileData] = useState({ name: "loading.." });
  const [fileLoaded, setFileLoaded] = useState(false);

  useEffect(() => {
    generalApiCall("/getPackageExternalFileInfo", {
      fileId: fileId,
      accessCode: accessCode,
    }).then((res) => {
      if (res.status === 401) {
        console.log("nav to prev page");
      } else {
        setFileData(res.data);
        setFileLoaded(true);
      }
    });
  }, []);

  return (
    <div style={{backgroundColor: "var(--primaryBg)"}} className={"fillHeight "}>
      <TitleBar name={fileData.name} backButton={true}></TitleBar>
      {fileLoaded && <FileBody fileData={fileData}></FileBody>}
      <div style={{height:"16px"}}></div>
    </div>
  );
}

export default ExternalPackageFile;
