import { AppstoreOutlined, MessageOutlined, MailOutlined, SettingOutlined, FolderOutlined, HomeOutlined, CalendarOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import { useNavigate } from 'react-router-dom';
import MessagesIcon from './icons/messagesIcon';

function getItem(label, key, icon, children, type) {
    return {
        key,
        icon,
        children,
        label,
        type,
    };
}
// const items = [
//     getItem('Navigation One', 'sub1', <MailOutlined />, [
//         getItem('Item 1', 'g1', null, [getItem('Option 1', '1'), getItem('Option 2', '2')], 'group'),
//         getItem('Item 2', 'g2', null, [getItem('Option 3', '3'), getItem('Option 4', '4')], 'group'),
//     ]),
//     getItem('Navigation Two', 'sub2', <AppstoreOutlined />, [
//         getItem('Option 5', '5'),
//         getItem('Option 6', '6'),
//         getItem('Submenu', 'sub3', null, [getItem('Option 7', '7'), getItem('Option 8', '8')]),
//     ]),
//     {
//         type: 'divider',
//     },
//     getItem('Navigation Three', 'sub4', <SettingOutlined />, [
//         getItem('Option 9', '9'),
//         getItem('Option 10', '10'),
//         getItem('Option 11', '11'),
//         getItem('Option 12', '12'),
//     ]),
//     getItem('Group', 'grp', null, [
//         getItem('Home', '13'), 
//         getItem('Files', '14'), 
//         getItem('Messages', '15',<MessagesIcon />), 
//         getItem('Settings', '16',<SettingOutlined />)
//     ], 'group'),
// ];
const clientItems = [
    getItem('PT-Hub', 'sub1', <MailOutlined />, null, 'group'),
    {
        type: 'divider',
    },
    getItem('Home', '13', <HomeOutlined />),
    getItem('Files', '14', <FolderOutlined />),
    getItem('Messages', '15', <MessageOutlined />),
    getItem('Settings', '16', <SettingOutlined />)
];
const ptItems = [
    getItem('PT-Hub', 'sub1', <MailOutlined />, null, 'group'),
    {
        type: 'divider',
    },
    getItem('Home', '1', <HomeOutlined />),
    getItem('Files', '2', <FolderOutlined />),
    getItem('Calendar', '3',<CalendarOutlined />),
    getItem('Settings', '4', <SettingOutlined />)
];



function DesktopToolbar(props) {
    const navigate = useNavigate();
    const onClick = (e) => {
        console.log('click ', e);
        if (e.key === "13") {
            navigate("/client/")
        }
        else if (e.key === "14") {
            navigate("/client/files")
        }
        else if (e.key === "15") {
            navigate("/client/messages")
        }
        else if (e.key === "16") {
            navigate("/client/Settings")
        }
        else if (e.key === "1") {
            navigate("/pt")
        }
        else if (e.key === "2") {
            navigate("/pt/library")
        }
        else if (e.key === "3") {
            navigate("/pt/booking")
        }
        else if (e.key === "4") {
            navigate("/pt/settings")
        }

    };
    return (
        <Menu
            onClick={onClick}
            style={{
                width: 256,
                height: "100vh",
                position: "sticky",
                top: 0,

            }}
            defaultSelectedKeys={['1']}
            defaultOpenKeys={['sub1']}
            mode="inline"
            items={props.isPt ? ptItems : clientItems}
        />
    );
}

export default DesktopToolbar;