import { Button, Form, Input, Modal, InputNumber } from "antd";
import { useState, useEffect } from "react";
import { UserOutlined } from "@ant-design/icons";

const PackageEditModal = ({
  open,
  onCreate,
  onCancel,
  packageData,
}) => {
  const templateValues = {
    name: "Package Name",
    longDescription:
      "This is the long description for the package. It contains details about the package and what it offers to the users. You can customize this to provide more information.",
    price: 499,
  };

  const [form] = Form.useForm();
  const { TextArea } = Input;

  useEffect(() => {
    if (packageData === "new" || !packageData) {
      form.setFieldsValue({
        name: templateValues.name,
        longDescription: templateValues.longDescription.substring(0, 1000),
        price: templateValues.price / 100,
      });
    } else {
      form.setFieldsValue({
        name: packageData.name || templateValues.name,
        longDescription: (packageData.longDescription || templateValues.longDescription).substring(0, 1000),
        price: packageData.price / 100 || templateValues.price / 100,
      });
    }
  }, [packageData]);

  return (
    <Modal
      centered
      visible={open}
      title="Edit Package"
      okText="Submit"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onCreate(values);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
      footer={
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          {packageData !== "new" ? (
            <Button type="dashed" danger onClick={onCancel}>
              Delete
            </Button>
          ) : (
            <div />
          )}
          <div>
            <Button onClick={onCancel}>Cancel</Button>
            <Button
              type="primary"
              onClick={() => {
                form
                  .validateFields()
                  .then((values) => {
                    form.resetFields();
                    if (packageData === "new") {
                      values._id = "new";
                    } else {
                      values._id = packageData._id;
                    }
                    onCreate(values);
                  })
                  .catch((info) => {
                    console.log("Validate Failed:", info);
                  });
              }}
            >
              Submit
            </Button>
          </div>
        </div>
      }
    >
      <Form form={form} layout="vertical" name="form_in_modal">
        <Form.Item
          name="name"
          label="Name"
          rules={[
            {
              required: true,
              message: "Please input the name of the package!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="longDescription"
          label="Long Description"
          tooltip="Provide detailed information about the package."
        >
          {<TextArea rows={8} maxLength={1000} showCount />}
        </Form.Item>
        <Form.Item
          name="price"
          min={0}
          label="Price"
          className="collection-create-form_last-form-item"
        >
          <InputNumber
            style={{ width: "100%" }}
            block
            precision={2}
            min={0}
            step={1}
            prefix={<div>$</div>}
            type="number"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default PackageEditModal;