import React from "react";
import { useParams } from "react-router-dom";
import styles from "./fileViewer.module.css";
import { FilePdfOutlined, FileExclamationOutlined } from "@ant-design/icons";

function FileViewer({ fileType, fileId }) {
  const { accessCode } = useParams();

  const urlExtension = () => {
    return accessCode === undefined ? fileId : `${fileId}/${accessCode}`;
  };

  const checkIframe = () => {
    if ("PDF Viewer" in navigator.plugins) {
      return true;
    } else {
      return false;
    }
  };

  const renderMP4 = () => (
    <video className={styles.videoContainer} controls>
      <source
        src={`${process.env.REACT_APP_API_BASE_URL}/getVideo/${urlExtension()}`}
        type="video/mp4"
      />
    </video>
  );

  const renderPDF = () => {
    const canSeePDF = checkIframe();
    if (canSeePDF) {
      return (
        <iframe
          title="PDF Viewer"
          src={`${process.env.REACT_APP_API_BASE_URL}/getPDF/${urlExtension()}`}
          type="application/pdf"
          className={styles.pdfContainer}
        ></iframe>
      );
    } else {
      return (
        <div className={styles.noPDF}>
          <FilePdfOutlined style={{ fontSize: "62px", marginBottom: "24px" }} />
          <p style={{ margin: "auto", textAlign: "center" }}>
            No PDF viewer plugin available for mobile <br /> Please download the file below{" "}
          </p>
        </div>
      );
    }
  };

  const renderUnknownFileType = () => (
    <div className={styles.noPDF}>
    <FileExclamationOutlined style={{ fontSize: "62px", marginBottom: "24px" }}/>
    <p style={{ margin: "auto", textAlign: "center" }}>
      File type not recognised <br /> Please download the file below{" "}
    </p>
  </div>
  );

  return fileType === ".mp4"
    ? renderMP4()
    : fileType === ".pdf"
    ? renderPDF()
    : renderUnknownFileType();
}

export default FileViewer;
