import React, { useState, useEffect } from "react";
import { generalApiCall } from "../scripts/apiCalls";
import { Typography } from 'antd';
const { Title, Text } = Typography;

function TermsAndCondiditions() {
  const [tAndC, setTAndC] = useState("");

  useEffect(() => {
    generalApiCall("/getTermsAndConditions").then((res) => {
      setTAndC(res.data);
      console.log(res.data)
    });
  }, []);

  return (
    <div style={{margin:"0px 7.5%" }}>
      <Title style={{textAlign:"center"}}>Terms and Conditions</Title>
      <br/>
      <Text style={{ lineBreak: "auto", whiteSpace: "pre-line" }}>{tAndC}</Text>
      <div style={{marginBottom:96}}></div>
    </div>
  );
}

export default TermsAndCondiditions;
