import React from "react";
import { Link } from "react-router-dom";
import styles from "./notificationCard.module.css";
import { generalApiCall } from "../scripts/apiCalls.jsx";

const NotificationCard = React.forwardRef((props, ref) => {
  const timestamp = new Date(props.timestamp);
  const date = timestamp.toLocaleDateString("en-NZ",{dateStyle:"short"});
  const time = timestamp.toLocaleTimeString("en-NZ", {
    timeStyle: "short",
  });
  // console.log(props.url)

  function handleLinkClick() {
    generalApiCall("/notificationClicked", {
      _id: props._id,
    });
  }

  const content = (
    <>
      <div className={(props.seen && styles.seen) + " " + styles.ctn}>
        <div className={styles.noteBox}>
          <div >
          {/* <div style={{height:"100%"}}> */}
            <img
              className={styles.img}
              src={"/getProfilePhoto/" + props.refUserId}
              alt="Human"
            ></img>
          </div>
          {/* <div className={styles.noteText}> */}
            <p style={{fontSize: "12px",margin: "0px 5px 0px 10px"}}>
              <span style={{ fontWeight: "bold" }}>{props.name}</span>
              {" " + props.text}
            </p>
          {/* </div> */}
          <div className={styles.date}>
            <p className={styles.timeStamp}>{time}</p>
            <p className={styles.timeStamp}>{date}</p>
          </div>
        </div>
      </div>
    </>
  );

  if (ref) {
    return <Link ref={ref} onClick={handleLinkClick} to={props.url}>{content}</Link>;
  } else {
    return <Link onClick={handleLinkClick} to={props.url}>{content}</Link>;
  }
});

export default NotificationCard;
