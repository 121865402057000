import { useState, useEffect } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import TitleBar from "../../components/titleBar";
import styles from "./ptSettingsBooking.module.css";
import { generalApiCall } from "../../scripts/apiCalls";
import { Button, Card, InputNumber, Slider, Tooltip } from "antd";
import { ConsoleSqlOutlined, InfoCircleOutlined } from "@ant-design/icons";

function PtSettingsBooking(props) {
  const setToolbarIcon = useOutletContext();
  useEffect(() => {
    setToolbarIcon(props.tbIcon);
  }, []);

  const navigate = useNavigate();
  const [bookingData, setBookingData] = useState();
  const [pricingData, setPricingData] = useState();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [authURL, setAuthURL] = useState("");

  useEffect(() => {
    generalApiCall("/getPtBookingSettings").then((res) => {
      res.data.timeMin = convertTimeFormat(res.data.timeMin);
      res.data.timeMax = convertTimeFormat(res.data.timeMax);
      setBookingData(res.data);
      setPricingData(res.data.pricing);
      setDataLoaded(true);
      if (res.data.hasAuthed === false) {
        generalApiCall("/api/getAuthURL").then((url) => {
          console.log(url.data);
          setAuthURL(url.data);
        });
      }
    });
  }, []);

  function convertTimeFormat(num) {
    let timeH = Math.floor(num);
    let timeM = (num % timeH) * 60;
    if (timeH < 10) {
      timeH = "0" + timeH.toString();
    }
    if (timeM < 10) {
      timeM = "0" + timeM.toString();
    }
    return timeH + ":" + timeM;
  }

  function submitBooking() {

    let bookingBuffer = parseInt(
      document.getElementById("tmBookingBuffer").value
    );

    let bookingSettings = {
      bookingBuffer: bookingBuffer,
      dayMinMax: bookingData.dayMinMax,
      pricing: pricingData,
    };
    console.log(bookingSettings);
    generalApiCall("/setPtBookingSettings", { booking: bookingSettings }).then(
      (res) => {
        //nav
        navigate(-1, { replace: false });
      }
    );
  }

  function updateDayMinMax(dayIndex, min, max) {
    let bookingDataTmp = bookingData;
    bookingDataTmp.dayMinMax[dayIndex].timeMin = min;
    bookingDataTmp.dayMinMax[dayIndex].timeMax = max;

    setBookingData(bookingDataTmp);
  }
  function updateDayAvailable(dayIndex, checked) {
    let bookingDataTmp = bookingData;
    bookingDataTmp.dayMinMax[dayIndex].available = checked;

    setBookingData(bookingDataTmp);
    console.log(bookingDataTmp);
  }

  function addNewBookingLength() {
    let PricingDataTmp = pricingData;
    console.log(pricingData);
    // PricingDataTmp.push({ length: 0, price: 0 })
    setPricingData([...PricingDataTmp, { length: 15, price: 0 }]);
    // setpricingData(bookingDataPrev=>({...bookingDataPrev,pricing: bookingDataPricingTmp}));
  }

  function updateBookingLength(index, length, price) {
    let pricingDataTmp = pricingData;
    pricingDataTmp[index].length = length;
    pricingDataTmp[index].price = price;
    console.log(pricingDataTmp);
    setPricingData(pricingDataTmp);
  }
  function removeBookingLength(index) {
    if (pricingData.length > 1) {
      let bookingDataPricingTmp = pricingData;
      bookingDataPricingTmp.splice(index, 1);
      setPricingData([...bookingDataPricingTmp]);
      console.log(bookingDataPricingTmp);
      // for (let i = 0; i < bookingDataPricingTmp.length; i++) {
      //   document.getElementById("pricingLength" + i).value =
      //     bookingDataPricingTmp[i].length;
      //   document.getElementById("pricingPrice" + i).value =
      //     bookingDataPricingTmp[i].price;
      // }
    }
  }

  return (
    <div className={"fillHeight crosses " + styles.pageCtn}>
      <TitleBar name="Booking Settings" backButton={true}></TitleBar>
      {dataLoaded && (
        <div className={styles.ctn}>
          <div style={{ height: "12px" }}></div>
          {!bookingData.hasAuthed && (
            <div className={styles.calAuthLink}>
              <div>
                <p>Please click link below to allow bookings</p>
              </div>
              <div>
                <a href={authURL}>Click Here</a>
              </div>
            </div>
          )}
          {bookingData.hasAuthed && (
            <>
              <div className={styles.bookingBuffer}>
                <div className={styles.inputCtn}>
                  <label style={{ fontSize: 18 }}>Booking Buffer </label>
                  <Tooltip title="This is the time between now and when a client can book in">
                    <InfoCircleOutlined
                      style={{
                        fontSize: "12px",
                        top: "-8px",
                        right: "4px",
                        position: "absolute",
                      }}
                    />
                  </Tooltip>
                  :
                </div>
                <div>
                  <InputNumber
                    id="tmBookingBuffer"
                    min={0}
                    max={240}
                    step={15}
                    defaultValue={bookingData.bookingBuffer}
                    tooltip=""
                  ></InputNumber>
                </div>
              </div>
              <h2>Booking Lengths & Price</h2>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(auto-fit, minmax(250px,1fr))",
                  gridGap: 8,
                }}
              >
                {pricingData.map((priceObj, index) => {
                  console.log(priceObj);
                  return (
                    <BookingLengthCard
                      key={"card" + index}
                      index={index}
                      length={priceObj.length}
                      price={priceObj.price}
                      updateFunc={updateBookingLength}
                      removeFunc={removeBookingLength}
                    ></BookingLengthCard>
                  );
                })}
              </div>
              <div style={{ marginTop: 8 }}></div>
              {pricingData.length < 4 && (
                <Button block type="primary" onClick={addNewBookingLength}>
                  Add
                </Button>
              )}
              {/* <button onClick={addNewBookingLength}>Add</button> */}

              <h2>Availability</h2>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(auto-fit, minmax(270px,1fr))",
                  gridGap: 8,
                }}
              >
                {bookingData.dayMinMax.map((day, index) => {
                  return (
                    <BookingDayCard
                      key={day.dayName}
                      name={day.dayName}
                      dayIndex={index}
                      checked={day.available}
                      min={day.timeMin}
                      max={day.timeMax}
                      updateDayMinMax={updateDayMinMax}
                      updateDayAvailable={updateDayAvailable}
                    ></BookingDayCard>
                  );
                })}
              </div>

              <div style={{ marginTop: 8 }}></div>
              <Button block type="primary" onClick={submitBooking}>
                Save
              </Button>
            </>
          )}
        </div>
      )}
    </div>
  );
}

function BookingLengthCard(props) {
  const [length, setLength] = useState(props.length);
  const [price, setPrice] = useState(props.price);
  // const [length, setLength] = useState(props.length)
  useEffect(() => {
    setLength(props.length);
    setPrice(props.price);
  }, [props.length, props.price]);

  function updateLength(sliderVal) {
    setLength(sliderVal);

    props.updateFunc(props.index, sliderVal, price);
  }
  function updatePrice(sliderVal) {
    setPrice(sliderVal);
    props.updateFunc(props.index, length, sliderVal);
  }

  return (
    <Card id={"pricing" + props.index} size="small">
      <label>Price:</label>
      <InputNumber
        id={"pricingPrice" + props.index}
        onChange={updatePrice}
        prefix="$"
        defaultValue={props.price}
        value={price}
      ></InputNumber>
      <br></br>
      <label>Time: {length} Minutes</label>
      <Slider
        id={"pricingLength" + props.index}
        defaultValue={props.length}
        value={length}
        min={15}
        max={15 * 8}
        step={15}
        // tooltip={{ open: true }}
        onChange={updateLength}
      ></Slider>
      <Button
        block
        danger
        type="dashed"
        onClick={() => {
          props.removeFunc(props.index);
        }}
      >
        Remove
      </Button>
    </Card>
  );
}

function BookingDayCard(props) {
  const [range, setRange] = useState([props.min, props.max]);

  const handleAfterChange = (value) => {
    if (value[0] > value[1]) {
      setRange([value[1], value[1]]);
    }
  };

  const updateAvailable = (e) => {
    props.updateDayAvailable(props.dayIndex, e.target.checked);
  };

  const handleSliderChange = (val) => {
    if (val[0] > val[1]) {
      val[0] = val[1];
    } else if (val[1] < val[0]) {
      val[1] = val[0];
    }
    setRange(val);
    props.updateDayMinMax(props.dayIndex, val[0], val[1]);
  };

  const convertTo12Hour = (time) => {
    const period = time < 12 ? "am" : "pm";
    time = time % 12 || 12;
    return `${time} ${period}`;
  };

  return (
    <div className={styles.bookingCard}>
      <div className={styles.topBookingCard}>
        <div className={styles.nameTimeCtn}>
          <p>{props.name}</p>
          <p id={"minMaxText" + props.name} className={styles.timeText}>
            {convertTo12Hour(range[0])} - {convertTo12Hour(range[1])}
          </p>
        </div>
        <input
          defaultChecked={props.checked}
          id={"cb" + props.name}
          type="checkbox"
          className={styles.bookingCheck}
          onClick={updateAvailable}
        ></input>
      </div>
      <div className={styles.sliderCtn}>
        <Slider
          range
          min={0}
          max={24}
          onChange={handleSliderChange}
          onAfterChange={handleAfterChange}
          value={range} // use the 'value' prop to make the slider controlled
        ></Slider>
      </div>
    </div>
  );
}

export default PtSettingsBooking;
