import { Button, Form, Input, Modal, Radio, InputNumber } from "antd";
import { useState, useEffect } from "react";
import { UserOutlined } from "@ant-design/icons";
const SubscriptionEditModal = ({
  open,
  onCreate,
  onCancel,
  subscriptionData,
}) => {
  const templateValues = {
    name: "name",
    shortDescription:
      "Get fit and reach your goals with my personalized training service. One-on-one coaching, flexible scheduling, and expert guidance to help you succeed. Contact me today!",
    longDescription:
      "Welcome to my personal training service, where I'm committed to helping you achieve your fitness goals and live a healthy lifestyle.\n\nWith my service, you'll enjoy (list services in subscription):\n" +
      "- A personalized training plan tailored to your needs and goals\n" +
      "- One-on-one coaching and support to keep you motivated and accountable\n" +
      "- Nutritional guidance and meal planning to fuel your workouts and support your health\n" +
      "- Access to the latest training techniques and equipment\n" +
      "- Flexible scheduling to fit your busy lifestyle\n" +
      "- A supportive and positive environment that encourages you to reach your full potential\n" +
      "\n" +
      "Whether you're looking to lose weight, build muscle, or improve your overall fitness, I'm here to help you every step of the way. Together, we'll create a plan that works for you and helps you achieve lasting results.\n" +
      "\n" +
      "So why wait? Contact me today and let's get started on your fitness journey!",
    price: 499,
  };
  const [form] = Form.useForm();
  const { TextArea } = Input;
  useEffect(() => {
    console.log(subscriptionData);
    if (subscriptionData === "new") {
      form.setFieldsValue({
        name: templateValues.name,
        shortDescription: templateValues.shortDescription.substring(0, 250),
        longDescription: templateValues.longDescription.substring(0, 1000),
        price: templateValues.price / 100,
      });
    } else {
      form.setFieldsValue({
        name: subscriptionData.name,
        // shortDescription: "tmp",
        // longDescription: "tmp",
        shortDescription: subscriptionData.shortDescription.substring(0, 250),
        longDescription: subscriptionData.longDescription.substring(0, 1000),
        price: subscriptionData.price / 100,
      });
    }
  }, [subscriptionData]);

  return (
    <Modal
      centered
      open={open}
      title="Edit Subscription"
      okText="Submit"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onCreate(values);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
      footer={
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          {subscriptionData !== "new" ? (
            <Button type="dashed" danger onClick={onCancel}>
              Delete
            </Button>
          ) : (
            <div />
          )}
          <div>
            <Button onClick={onCancel}>Cancel</Button>
            <Button
              type="primary"
              onClick={() => {
                form
                  .validateFields()
                  .then((values) => {
                    form.resetFields();
                    if(subscriptionData === "new"){
                    values._id = "new"
                    }
                    else{
                    values._id = subscriptionData._id
                    }
                    onCreate(values);
                  })
                  .catch((info) => {
                    console.log("Validate Failed:", info);
                  });
              }}
            >
              Submit
            </Button>
          </div>
        </div>
      }
    >
      <Form form={form} layout="vertical" name="form_in_modal">
        <Form.Item
          name="name"
          label="Name"
          rules={[
            {
              required: true,
              message: "Please input the name of subscription!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="shortDescription"
          label="Short Description"
          tooltip="Help your prospective clients with a summary of this Subscription! This will be displayed before they seek more information"
        >
          {
            <TextArea
              rows={4}
              maxLength={250}
              showCount
              style={{
                resize: "none",
              }}
            />
          }
        </Form.Item>
        <Form.Item
          name="longDescription"
          label="Long Description"
          tooltip="Show your prospective clients all you have to offer in this Subscription! This will be displayed after they seek more information"
        >
          {<TextArea rows={8} maxLength={1000} showCount />}
        </Form.Item>
        <Form.Item
          name="price"
          min={0}
          label={
            <div>
              Price{" "}
              {subscriptionData !== "new" && (
                <span style={{ backgroundColor: "#faad14", borderRadius: 7 }}>
                  (Updated in 30 days)
                </span>
              )}
            </div>
          }
          className="collection-create-form_last-form-item"
        >
          <InputNumber
            style={{ width: "100%" }}
            block
            precision={2}
            min={0}
            step={1}
            prefix={<div>$</div>}
            type="number"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default SubscriptionEditModal;
