import React, { useEffect, useState } from "react";
import EventSchedule from "../../components/eventSchedule";
import TitleBar from "../../components/titleBar";
import findFreeEvents from "../../scripts/booking";
import styles from "./bookingMaster.module.css";
import AddIcon from "../../components/icons/addIcon";
import BookingPopup from "../../components/bookingPopup.jsx";
import { useOutletContext } from "react-router-dom";
import { generalApiCall } from "../../scripts/apiCalls";
import useAuth from "../../scripts/useAuth.jsx";
import { Modal, Button } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons"

function BookingMaster(props) {
  const setToolbarIcon = useOutletContext();
  useEffect(() => {
    setToolbarIcon(props.tbIcon);
  });
  const { userData } = useAuth();
  const tmpBookingData = [
    {
      day: 7,
      startHour: 22,
      startMin: 0,
      endHour: 22,
      endMin: 0,
      date: 0,
      name: "",
    },
  ];
  const [bookingData, setBookingData] = useState(tmpBookingData);
  const [ptSettings, setPtSettings] = useState({ timeMin: 0, timeMax: 24 });
  const [availableSlots, setAvailableSlots] = useState(tmpBookingData);
  const [bookingLoaded, setBookingLoaded] = useState(false);
  let [isPopup, setIsPopup] = useState(false);
  let [addedWeeks, setAddedWeeks] = useState(0);
  let [popupEvent, setPopupEvent] = useState({ day: 0 });

  useEffect(() => {
    if (!userData.isPt && !bookingLoaded) {
      generalApiCall("/getPtBookingSettings").then((response) => {
        setPtSettings(response.data);
        console.log("non pt grab settings");

        generalApiCall("/api/getBooking", { addedWeeks: addedWeeks }).then(
          async (res) => {
            // console.log(res);
            if (res.status !== 401) {
              console.log("grabbed event data");
              // console.log(res)
              setBookingData(res.data);
              let fEv = await findFreeEvents(res.data, response.data);
              setAvailableSlots(fEv);
              setBookingLoaded(true);
            }
          }
        );
      });
    } else {
      generalApiCall("/api/getBooking", { addedWeeks: addedWeeks }).then(
        async (res) => {
          console.log("grabbed event data");
          setBookingData(res.data);
          let fEv = await findFreeEvents(res.data, ptSettings);
          setAvailableSlots(fEv);
          setBookingLoaded(true);
        }
      );
    }
  }, [addedWeeks, bookingLoaded]);

  function togglePopup() {
    setIsPopup(!isPopup);
  }

  let [screenWidth, setScreenWidth] =
    useState();
    // document.getElementById("bookingMasterCtn").clientWidth
  React.useEffect(() => {
    function handleResize() {
      // setScreenWidth(document.documentElement.clientWidth);
      setScreenWidth(document.getElementById("bookingMasterCtn").clientWidth);
    }
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  const timeGridW = 40;

  const startHour = ptSettings.timeMin;
  const endHour = ptSettings.timeMax;
  const hourHeight = 60;
  const dayWidth = (screenWidth - timeGridW) / 7;

  function TimeGrid() {
    const timeH = Array.from(
      { length: endHour - startHour },
      (_, i) => i + startHour
    );
    const formatHour = (hour) => {
      let formattedHour = hour;
      let descriptor = "am";

      if (hour > 12) {
        formattedHour = hour - 12;
        descriptor = "pm";
      } else if (hour === 12) {
        descriptor = "pm";
      } else if (hour === 0) {
        formattedHour = 12;
      }
      return { formattedHour, descriptor };
    };
    return (
      <div
        style={{
          width: `${timeGridW}px`,
          gridTemplateRows: `repeat(${endHour - startHour}, ${hourHeight}px)`,
        }}
        className={styles.timeGrid}
      >
        {timeH.map((hour) => {
          const { formattedHour, descriptor } = formatHour(hour);
          return (
            <div
              key={`${formattedHour}${descriptor}`}
              className={styles.timeItem}
            >
              <p className={styles.timeText}>
                {formattedHour}
                {descriptor}
              </p>
            </div>
          );
        })}
      </div>
    );
  }

  function GridBackground() {
    const n = Math.ceil((endHour - startHour) * 7);
    return (
      <div
        style={{ gridTemplateRows: `repeat(auto-fill, ${hourHeight}px)` }}
        className={styles.gridBgCtn}
      >
        {Array.from({ length: n }, (_, i) => (
          <div className={styles.gridBgItem} key={`gridBgItem-${i}`}></div>
        ))}
      </div>
    );
  }

  function getWeekDates(day) {
    let result = new Date();
    let totalDays = day + parseInt(addedWeeks) * 7;
    result.setDate(result.getDate() - result.getDay() + totalDays);
    let formattedResult = result.getDate().toString();
    if (formattedResult.length < 2) {
      formattedResult = "0" + formattedResult;
    }
    return formattedResult;
  }
  function getCurrentMonth() {
    let monthsString = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "Movember",
      "December",
    ];
    let result = new Date();
    let totDays = 6 + parseInt(addedWeeks) * 7; //0 for mon
    result.setDate(result.getDate() - result.getDay() + 1 + totDays);
    return monthsString[result.getMonth()];
  }
  function setPopupDayAndOpen(event) {
    setPopupEvent(event);
    setIsPopup(true);
  }
  function padNumberWithZero(n) {
    return n < 10 ? "0" + n : n;
  }

  function eventPopupTextFormatted(event) {
    let eventTextArray = [];
    let dateString = new Date(event.startUTC).toLocaleDateString("en-GB", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    });
    eventTextArray.push(event.name);
    eventTextArray.push(dateString);
    eventTextArray.push(
      event.startHour +
        ":" +
        padNumberWithZero(event.startMin) +
        " - " +
        event.endHour +
        ":" +
        padNumberWithZero(event.endMin)
    );
    return eventTextArray;
  }

  return (
    <div id="bookingMasterCtn" className={"fillHeight " + styles.ctn}>
      <TitleBar name="Booking"></TitleBar>
      <div className={styles.dateCtn}>
        <div
          onClick={() => {
            setAddedWeeks(addedWeeks - 1);
          }}
        >
          <LeftOutlined class={styles.navIcon} />
        </div>
        <h2>{getCurrentMonth()}</h2>
        <div
          onClick={() => {
            setAddedWeeks(addedWeeks + 1);
          }}
        >
          <RightOutlined class={styles.navIcon} />
        </div>
      </div>
      <div className={styles.dateGrid}>
        <div className={styles.dateItem}></div>
        {["S", "M", "T", "W", "T", "F", "S"].map((item, ind) => {
          return (
            <div className={styles.dateItem}>
              <p className={styles.dateItemText}>{item}</p>
              {getWeekDates(ind)}
            </div>
          );
        })}
      </div>
      <div className={styles.timeCtn}>
        <TimeGrid></TimeGrid>
        <GridBackground></GridBackground>
        {(!userData.isPt && bookingLoaded) && (
          <div onClick={togglePopup} className={styles.plus}>
            <AddIcon class={styles.icon} />
          </div>
        )}
      </div>
      {bookingLoaded && (
        <EventSchedule
          free={userData.isPt}
          startHour={startHour}
          endHour={endHour}
          hourHeight={hourHeight}
          dayWidth={dayWidth}
          // bookingData={userData.isPt ? bookingData : availableSlots}
          bookingData={bookingData}
          daySelectFunction={setPopupDayAndOpen}
          showText={true}
        ></EventSchedule>
      )}
      {bookingLoaded && (
        <EventSchedule
          free={!userData.isPt}
          startHour={startHour}
          endHour={endHour}
          hourHeight={hourHeight}
          dayWidth={dayWidth}
          bookingData={availableSlots}
          daySelectFunction={setPopupDayAndOpen}
          showText={true}
        ></EventSchedule>
      )}
      {!userData.isPt && isPopup && (
        <BookingPopup
          weekData={availableSlots}
          addedWeeks={addedWeeks}
          initalDay={popupEvent.day}
          close={togglePopup}
          bookingLengths={ptSettings.pricing}
          open={!userData.isPt && isPopup}
        ></BookingPopup>
      )}
      <Modal
        centered
        open={userData.isPt && isPopup}
        onCancel={togglePopup}
        footer={<Button onClick={togglePopup}>Close</Button>}
      >
        {eventPopupTextFormatted(popupEvent).map((item, ind) => {
          return <div className={styles.bookingInfoPopup}>{item}</div>;
        })}
      </Modal>
      {/* {userData.isPt && isPopup && (
        <Popup
          text={eventPopupTextFormatted(popupEvent)}
          close={togglePopup}
          buttons={[{ text: "Close", clicked: togglePopup }]}
        ></Popup>
      )} */}
    </div>
  );
}

export default BookingMaster;
