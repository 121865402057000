import {
  Button,
  Form,
  Input,
  Modal,
  Select,
  Steps,
  Upload,
  message,
  Spin,
} from "antd";
import React, { useState, useRef } from "react";
import useAuth from "../scripts/useAuth.jsx";
import { InboxOutlined } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea.js";
import { generalApiCall, uploadFile } from "../scripts/apiCalls";
import LoadingModal from "./loadingModal.jsx";

function UploadFile(props) {
  const { userData } = useAuth();
  const { Dragger } = Upload;

  const ref = useRef();
  const [uploadFiles, setUploadedFiles] = useState([]);
  const [uploadFilesInfo, setUploadedFilesInfo] = useState([]);
  const [stage, setStage] = useState("fileSelect");
  const [current, setCurrent] = useState(0);
  const [isLoadingModalVisible, setIsLoadingModalVisible] = useState(false);

  const draggerProps = {
    name: "file",
    multiple: true,
    fileList: uploadFiles,
    customRequest: ({ onSuccess }) => onSuccess("ok"),
    // action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
    onChange(info) {
      // console.log(info);
      const { status } = info.file;
      // console.log(uploadFiles);

      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
      // setUploadedFiles([...uploadFiles,info.file])
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
    onRemove(file) {
      let uploadFilesUpdated = uploadFiles.filter((obj, ind) => {
        if (obj.uid === file.uid) {
          return false;
        } else return true;
      });
      console.log("new List: " + uploadFilesUpdated);
      setUploadedFiles(uploadFilesUpdated);
    },
    beforeUpload(file, fileList) {
      setUploadedFiles([...uploadFiles, ...fileList]);
      console.log(fileList);
      return true;
    },
  };

  function updateFilesInfo() {
    let updatedFilesInfo = [];
    uploadFiles.forEach((fileToUpload) => {
      //search filesinfo for matches to uid
      var matchingFile = uploadFilesInfo.find((fileInfo) => {
        return fileInfo.uid === fileToUpload.uid;
      });
      if (matchingFile !== undefined) {
        updatedFilesInfo.push(matchingFile);
      } else {
        updatedFilesInfo.push({
          name: fileToUpload.name,
          description: "",
          label: "Other",
          uid: fileToUpload.uid,
        });
      }
    });
    setUploadedFilesInfo(updatedFilesInfo);
  }

  function footer() {
    if (stage === "fileSelect") {
      return (
        <>
          <Button onClick={props.close}>Close</Button>
          {uploadFiles.length > 0 && (
            <Button
              type="primary"
              onClick={() => {
                updateFilesInfo();
                setStage("fileEdit");
              }}
            >
              Proceed
            </Button>
          )}
        </>
      );
    } else if (stage === "fileEdit") {
      return (
        <>
          <Button
            onClick={() => {
              ref.current.submit();
              current === 0 ? setStage("fileSelect") : setCurrent(current - 1);
            }}
          >
            Back
          </Button>
          {current < uploadFiles.length - 1 ? (
            <Button
              type="primary"
              onClick={() => {
                ref.current.submit();
                setCurrent(current + 1);
              }}
            >
              Next
            </Button>
          ) : (
            <Button
              type="primary"
              onClick={() => {
                setIsLoadingModalVisible(true); // Open the loading modal
                ref.current.submit();
                ref.current.validateFields().then((values) => {
                  //last object sometimes isnt updated in time
                  let uploadFilesInfoTmp = [...uploadFilesInfo];
                  const lastFileIndex = uploadFilesInfoTmp.length - 1;
                  uploadFilesInfoTmp[lastFileIndex].name = values.name;
                  uploadFilesInfoTmp[lastFileIndex].description =
                    values.description;
                  uploadFilesInfoTmp[lastFileIndex].label = values.label;

                  uploadFile(uploadFiles, uploadFilesInfoTmp).then((res) => {
                    console.log(res);
                    setIsLoadingModalVisible(false); // Close the loading modal
                    props.close();
                    props.refresh();
                  });
                });
              }}
            >
              Submit
            </Button>
          )}
        </>
      );
    }
  }

  function DraggerCtn() {
    return (
      <Dragger {...draggerProps}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          Click or drag file to this area to upload
        </p>
        <p className="ant-upload-hint">
          Support for a single or bulk upload. Strictly prohibited from
          uploading company data or other banned files.
        </p>
      </Dragger>
    );
  }
  const items = uploadFiles.map((item) => ({
    key: item.name,
    // title: item.name,
    title: "",
  }));

  const onFinish = (values) => {
    let copyOfUploadedFiles = uploadFilesInfo;
    copyOfUploadedFiles[current].name = values.name;
    copyOfUploadedFiles[current].description = values.description;
    copyOfUploadedFiles[current].label = values.label;
    setUploadedFilesInfo(copyOfUploadedFiles);
    console.log("Success:", values);
    return values;
  };

  function FileBody() {
    if (stage === "fileSelect") {
      return <DraggerCtn></DraggerCtn>;
    } else if (stage === "fileEdit") {
      console.log(uploadFilesInfo[current]);
      return (
        <>
          <Steps
            size="small"
            direction="horizontal"
            current={current}
            items={items}
            responsive={false}
          ></Steps>
          <Form ref={ref} onFinish={onFinish}>
            <Form.Item
              name="name"
              label="Name"
              initialValue={uploadFilesInfo[current].name}
            >
              <Input></Input>
            </Form.Item>
            <Form.Item
              label="Description"
              name="description"
              initialValue={uploadFilesInfo[current].description}
            >
              <TextArea rows={4}></TextArea>
            </Form.Item>
            {userData.isPt && (
              <Form.Item
                name="label"
                label="Label"
                initialValue={uploadFilesInfo[current].label}
              >
                <Select
                  options={[
                    {
                      text: "Nutrition",
                      value: "Nutrition",
                    },
                    {
                      text: "Workout",
                      value: "Workout",
                    },
                    {
                      text: "Corrective",
                      value: "Corrective",
                    },
                    {
                      text: "Mobility",
                      value: "Mobility",
                    },
                    {
                      text: "Other",
                      value: "Other",
                    },
                  ]}
                ></Select>
              </Form.Item>
            )}
          </Form>
          {/* <Steps size="small" current={current} items={items}></Steps> */}
        </>
      );
    }
  }

  return (
    <div>
      <Modal
        footer={footer()}
        okType={uploadFiles.length > 0 ? "primary" : "disabled"}
        okText="Proceed"
        open={props.isOpen}
        onCancel={props.close}
      >
        <FileBody />
      </Modal>
      <LoadingModal visible={isLoadingModalVisible} />
    </div>
  );
}

export default UploadFile;
