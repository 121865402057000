import React, { useState, useEffect } from "react";
import styles from "./bookingMade.module.css";
import TitleBar from "../components/titleBar";
import { CloseCircleOutlined, CheckCircleOutlined } from "@ant-design/icons";

function BookingMade() {
  const [success, setSuccess] = useState(false);
  const [bookingInfo, setBookingInfo] = useState();

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get("success")) {
      setSuccess(true);
      // "Order placed! You will receive an email confirmation."

      setBookingInfo({
        start: query.get("start"),
        end: query.get("end"),
        date: query.get("date"),
      });
    }
    //SatOct01202209:00:00GMT+1300(NewZealandDaylightTime)
    if (query.get("canceled")) {
      setSuccess(false);
    }
  }, []);

  return (
    <div className={"fillHeight " + styles.cnt}>
      <TitleBar replace={true} backButton={true} name="CONFIRMATION" />
      {success ? (
        <div className={styles.bookingMadeCnt}>
          <CheckCircleOutlined style={{ color: "Green", fontSize: "48px" }} />
          <h2 className={styles.bookingMadeHeader}>Booking Successful</h2>
          <p>You will receive email confirmation</p>
          <p>Your personal training session is:</p>
          <h4>{bookingInfo.date}</h4>
          <h4>
            {bookingInfo.start} - {bookingInfo.end}
          </h4>
        </div>
      ) : (
        <div className={styles.bookingFailedCtn}>
          <CloseCircleOutlined style={{ color: "Red", fontSize: "48px" }} />
          <p>
            Order canceled -- continue to shop around and checkout when you're
            ready.
          </p>
        </div>
      )}
    </div>
  );
}

export default BookingMade;

// http://localhost:3000/test?success=true&start=08:00%20am&end=09:00%20am&date=08%20Oct%202022
// teeeeeeesting payments <3
