import React, { useEffect, useState } from "react";
import { Link, useOutletContext } from "react-router-dom";
import styles from "./ptSettings.module.css";
import TitleBar from "../../components/titleBar";
import sysButtons from "../../styles/sysButtons.module.css";
import FeedbackModal from "../../components/feedbackModal";
import useAuth from "../../scripts/useAuth.jsx";
import { generalApiCall } from "../../scripts/apiCalls";
import PopupSubmitText from "../../components/popupSubmitText";
import { Button } from "antd";

function PtSettings(props) {
  const setToolbarIcon = useOutletContext();
  useEffect(() => {
    setToolbarIcon(props.tbIcon);
  }, []);
  const { logout } = useAuth();

  const [showFeedbackPopup, setShowFeedbackPopup] = useState(false);

  function buttonStyle() {
    return (
      sysButtons.button +
      " " +
      sysButtons.centerSpreadContent +
      " " +
      sysButtons.buttonSolid
    );
  }

  function toggleFeedbackPopup() {
    setShowFeedbackPopup(!showFeedbackPopup);
  }

  function submitFeedback(text) {
    console.log(text);
    // setShowFeedbackPopup(false);
    generalApiCall("/submitFeedback", { feedback: text }).then((res) => {
      toggleFeedbackPopup();
    });
  }

  return (
    <div className={"fillHeight crosses " + styles.container}>
      <TitleBar name="Settings" hasBackButton="false"></TitleBar>
      <div style={{ height: "32px" }}></div>
      <Link className={styles.link} to="/pt/settings/info">
        <Button type="primary" size="large" block>
          Personal Info
        </Button>
      </Link>
      {/* <div style={{ height: "16px" }}></div> */}
      {/* <Link className={styles.link} to="/pt/settings/theme">
        <Button type="primary" size="large" block>
          Theme
        </Button>
      </Link>
      <div style={{ height: "16px" }}></div>
      <Link className={styles.link} to="/pt/settings/access">
        <Button type="primary" size="large" block>
          Access Codes
        </Button>
      </Link> */}
      <div style={{ height: "16px" }}></div>
      <Link className={styles.link} to="/pt/settings/booking">
        <Button type="primary" size="large" block>
          Booking
        </Button>
      </Link>
      <div style={{ height: "16px" }}></div>
      <Link className={styles.link} to="/pt/settings/password">
        <Button type="primary" size="large" block>
          Change Password
        </Button>
      </Link>
      <div style={{ height: "16px" }}></div>
      <Link className={styles.link} to="/pt/settings/packages">
        <Button type="primary" size="large" block>
          Packages
        </Button>
      </Link>
      <div style={{ height: "16px" }}></div>
      <Link className={styles.link} to="/pt/settings/subscriptions">
        <Button type="primary" size="large" block>
          Subscriptions
        </Button>
      </Link>
      <div style={{ height: "16px" }}></div>
      <Button
        type="primary"
        size="large"
        className={styles.feedbackButton}
        onClick={toggleFeedbackPopup}
      >
        Send Feedback
      </Button>
      <div style={{ height: "32px" }}></div>
      <Link className={styles.link} to="/">
        <Button danger size="large" block onClick={logout}>
          Logout
        </Button>
      </Link>
      <div style={{ height: "32px" }}></div>
      <Link className={styles.link} to="/termsAndConditions">
        <p style={{ textAlign: "center" }}>T&C</p>
      </Link>

      <FeedbackModal
        open={showFeedbackPopup}
        onCancel={toggleFeedbackPopup}
        onCreate={submitFeedback}
      ></FeedbackModal>
    </div>
  );
}
export default PtSettings;
