import React from "react";
import { Modal, Spin } from "antd";

function LoadingModal({ visible }) {
    return (
      <Modal
        visible={visible}
        closable={false}
        footer={null}
        centered
        maskClosable={false}
      >
        <div style={{ textAlign: "center" }}>
          <Spin size="large" />
          <p>Loading...</p>
        </div>
      </Modal>
    );
  }

  export default LoadingModal;