import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
// import { useLocation} from "react-router-dom";
import styles from "./loginExample1.module.css";
import useAuth, { setCssVars } from "../../scripts/useAuth";
import { generalApiCall } from "../../scripts/apiCalls";
import ForgotPassword from "../forgotPassword";

import { Typography, Input, Button, Modal } from "antd";
import {
  UserOutlined,
  EyeTwoTone,
  EyeInvisibleOutlined,
} from "@ant-design/icons";
const { Title, Text } = Typography;

function LoginExample1(props) {
  const navigate = useNavigate();
  const { login, authed, userData } = useAuth();
  const urlQuery = new URLSearchParams(window.location.search);
  const location = useLocation();

  const [currentBody, setCurrentBody] = useState("login");
  const [ModalOpen, setModalOpen] = useState(false);
  const [ModalText, setModalText] = useState("");

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get("code")) {
      setCurrentBody("passwordRequest");
    }
  }, []);

  function handleSubmit(e) {
    e.preventDefault();
    const user = {
      username: e.target[0].value,
      password: e.target[1].value,
    };

    console.log(window.history);
    login(user).then((response) => {
      //https://stackoverflow.com/questions/54069253/the-usestate-set-method-is-not-reflecting-a-change-immediately
      if (response.error === false) {
        let nav = "/client";
        if (response.isPt === true) {
          nav = "/pt";
        }
        if (
          location.state?.path &&
          location.state?.path !== "/client" &&
          location.state?.path !== "/pt"
        ) {
          navigate(location.state?.path, { replace: true });
        } else {
          navigate(nav, { replace: true });
        }
      } else {
        console.log("failed login");
        setModalText("Incorrect email or password")
        setModalOpen(true)
      }
    });
  }
  function handlePackageSubmit(e) {
    e.preventDefault();

    // let accessCode = document.getElementById("externalAccessCode").value;
    let accessCode = e.target[0].value;
    generalApiCall("/packageExternalLogin", { accessCode: accessCode }).then(
      (res) => {
        console.log("api call");
        if (res.status === 401) {
        } else {
          navigate("/packages/" + res.data + "/" + accessCode, {
            replace: false,
          });
        }
      }
    );
  }

  useEffect(() => {
    console.log(process.env.REACT_APP_TEST);
    if (urlQuery.get("success")) {
      setCssVars();
    }
    console.log("checking auth");
    if (authed) {
      console.log("isAuthed");
      let nav = "/client";
      if (userData.isPt === true) {
        nav = "/pt";
      }
      navigate(nav, { replace: true });
    }
  }, [authed]);

  function selectBody() {
    switch (currentBody) {
      case "login":
        return (
          <LoginBody
            setCurrentBody={setCurrentBody}
            handleSubmit={handleSubmit}
          ></LoginBody>
        );
      // break;
      case "package":
        return (
          <PackageBody
            setCurrentBody={setCurrentBody}
            handleSubmit={handlePackageSubmit}
          ></PackageBody>
        );
      // break;
      case "passwordRequest":
        return (
          <ForgotPassword setCurrentBody={setCurrentBody}></ForgotPassword>
        );
      // break;
      case "passwordReset":
        // code block
        break;
      default:
      // code block
    }
  }
  function selectSecondary() {
    switch (currentBody) {
      case "login":
        return (
          <Text type="secondary">
            Bought a package?{" "}
            <p
              className={styles.registerLinkText}
              onClick={() => {
                setCurrentBody("package");
              }}
            >
              Access
            </p>
          </Text>
        );
      // break;
      case "package":
        return (
          <Text type="secondary">
            Didn't mean to click that?{" "}
            <p
              className={styles.registerLinkText}
              onClick={() => {
                setCurrentBody("login");
              }}
            >
              Login
            </p>
          </Text>
        );
      // break;
      case "passwordRequest":
        return (
          <Text type="secondary">
            Remembered your password?{" "}
            <p
              className={styles.registerLinkText}
              onClick={() => {
                setCurrentBody("login");
              }}
            >
              Login
            </p>
          </Text>
        );
      // break;
      case "passwordReset":
        return <Text type="secondary">You'll be back in soon!</Text>;
      default:
      // code block
    }
  }

  return (
    <div
      className={
        styles.backgroundColour +
        " fillHeight horizontalCenter verticalCenter crosses "
      }
    >
      <div className={styles.loginCtn + " horizontalCenter verticalCenter"}>
        <div>
          <Title level={3} style={{ textAlign: "center" }}>
            Welcome Back!
          </Title>
          {selectSecondary()}
        </div>
        {/* <div style={{ margin: "10px" }}></div> */}
        {selectBody()}
        <p className="loginNotice">Prototype v0.9.5</p>
        <Modal
          centered
          open={ModalOpen}
          onCancel={()=>setModalOpen(false)}
          footer={[
            
            <Button type="primary"  onClick={()=>setModalOpen(false)}>
              Okay
            </Button>,]}
        >
          {ModalText}
        </Modal>
      </div>
    </div>
  );
}

const PackageBody = (props) => {
  return (
    <>
      <form
        style={{ height: "100%" }}
        className="horizontalCenter"
        onSubmit={props.handleSubmit}
      >
        <div style={{ marginTop: 8 }}></div>
        <div className={styles.labelCtn}>
          <Text type="secondary">Access Code</Text>
        </div>
        <Input
          id="packageInput"
          size="large"
          placeholder="Code..."
          prefix={"#"}
          autoComplete="false"
        />

        <Button
          style={{ marginTop: "auto" }}
          type="primary"
          htmlType="submit"
          className="loginButton"
        >
          Login
        </Button>
      </form>
    </>
  );
};
const LoginBody = (props) => {
  return (
    <>
      <form className="horizontalCenter" onSubmit={props.handleSubmit}>
        <div style={{ marginTop: 8 }}></div>
        <div className={styles.labelCtn}>
          <Text type="secondary">Your e-mail</Text>
        </div>
        <Input
          block
          size="large"
          placeholder="Email.."
          prefix={<UserOutlined />}
        />
        <div className={styles.labelCtn}>
          <Text type="secondary">Password</Text>
        </div>
        <Input.Password
          size="large"
          block
          placeholder="input password"
          iconRender={(visible) =>
            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
          }
        />
        <div className={styles.forgetPasswordCtn}>
          {/* <Link className={styles.forgotPasswordLink} to="/forgotPassword"> */}
          <Text
            type="secondary"
            onClick={() => {
              props.setCurrentBody("passwordRequest");
            }}
          >
            <p className={styles.forgotPasswordLinkText}>Forgot Password?</p>
          </Text>
          {/* </Link> */}
        </div>
        <Button type="primary" htmlType="submit" className="loginButton">
          Login
        </Button>
        <Link
          className="registerLink"
          to="/register?accessCode=6319349b17da4f3a4658392f"
        >
          <p className={styles.registerLinkText}>Register</p>
        </Link>
      </form>
    </>
  );
};

export default LoginExample1;
