import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { generalApiCall } from "../scripts/apiCalls";
import LoadingScreen from "../components/loadingScreen";

function VerifyEmailRedirect(props) {
  const navigate = useNavigate();

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);
    if (query.get("code")) {
      generalApiCall("/verifyEmail", { code: query.get("code") }).then(
        (response) => {
          console.log(response);

          window.location.replace(response.data)
        }
      );
    }
  }, []);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // height: "100vh",
      }}
    >
      <LoadingScreen />
    </div>
  );
}

export default VerifyEmailRedirect;
